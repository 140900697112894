// PS_01, PS_02, PS_03, PS_04
import React, { useState, useEffect, useRef } from 'react';
import Tippy from '@tippyjs/react';
import { followCursor } from 'tippy.js';
import objCryptoJS from 'crypto-js';
import 'tippy.js/dist/tippy.css';
import { decodeSessionDetails, encryptHybidMessage } from '../../Services/CommonServices';
import { sessionResponse } from '../../Common/Interface';
import { useLocation, useHistory } from 'react-router';
import Loader from '../Common/Loader';
import { isvalidSecret, isValidTenantID, isValidEmail, isValidAppID } from '../../Common/Constant';
import { checkServiceApp, deleteserviceapp, fetchConnectionDataApi, getTaskParallelInstance, fetchParallelInstance, fetchServiceAppApi, fetchPsDataApi } from "../../Services/psConfigServices";//PS_AD-04
import { insertLastVisitedPageValueApi } from '../../Services/MigrationAndManageMigrationServices';
import { getSession } from '../../Services/manageClientAPI';
import jwt from 'jwt-decode';


interface MigrationComponentProps {
    value: number;
    setValue: React.Dispatch<React.SetStateAction<number>>;
    mainNewTab: string;
    roleCheck: string;
    totalParallelCount: number;
    setTotalParallelCount: React.Dispatch<React.SetStateAction<number>>;
    sourceApps: any[]; // Replace 'any' with a specific type if possible
    setSourceApps: React.Dispatch<React.SetStateAction<any[]>>;
    sourceServiceAccounts: any;
    setsourceServiceAccounts: any;
    targetServiceAccounts?: any,
    setTargetServiceAccounts?: any,
    targetApps: any[]; // Replace 'any' with a specific type if possible
    setTargetApps: React.Dispatch<React.SetStateAction<any[]>>;
    migrationNestedTabButton: string;
    setMigrationNestedTabButton: (tab: string) => void;
    migrationNestedTab: string;
    setMigrationNestedTab: (tab: string) => void;
    sourceDataError: {
        notification: string;
        sourceAccountEmail: string;
        sourceAccountPassword: string;
        targetAccountEmail: string;
        targetAccountPassword: string;
        sourceServiceAccount: string;
        targetServiceAccount: string;
        sourceServiceAppId: string;
        sourceServiceAppSecret: string;
        targetServiceAppId: string;
        targetServiceAppSecret: string;
        sourceServiceAccountLength: string,
        targetServiceAccountLength: string,
        sourceServiceAppLength: string;
        targetServiceAppLength: string;
        showPopupMessage: string;
        invalidEmailAddress: string;
        SourceAzureAppId: string;
        SourceAzureSecretId: string;
        emailMaxLengthError: string;
    };
    setsourceDataError: React.Dispatch<React.SetStateAction<{
        notification: string;
        sourceAccountEmail: string;
        sourceAccountPassword: string;
        targetAccountEmail: string;
        targetAccountPassword: string;
        sourceServiceAccount: string;
        targetServiceAccount: string;
        sourceServiceAppId: string;
        sourceServiceAppSecret: string;
        targetServiceAppId: string;
        targetServiceAppSecret: string;
        sourceServiceAccountLength: string,
        targetServiceAccountLength: string,
        sourceServiceAppLength: string;
        targetServiceAppLength: string;
        showPopupMessage: string;
        invalidEmailAddress: string;
        SourceAzureAppId: string;
        SourceAzureSecretId: string;
        emailMaxLengthError: string;
    }>>;
    sourceDataErrorMessage: {
        notification: string;
        sourceAccountEmail: string;
        sourceAccountPassword: string;
        targetAccountEmail: string;
        targetAccountPassword: string;
        sourceServiceAccount: string;
        targetServiceAccount: string;
        sourceServiceAppId: string;
        sourceServiceAppSecret: string;
        targetServiceAppId: string;
        targetServiceAppSecret: string;
    };
    setsourceDataErrorMessage: React.Dispatch<React.SetStateAction<{
        notification: string;
        sourceAccountEmail: string;
        sourceAccountPassword: string;
        targetAccountEmail: string;
        targetAccountPassword: string;
        sourceServiceAccount: string;
        targetServiceAccount: string;
        sourceServiceAppId: string;
        sourceServiceAppSecret: string;
        targetServiceAppId: string;
        targetServiceAppSecret: string;
    }>>;
    sourceApp: { id: string; secret: string, serviceAccountKey?: any };
    setSourceApp: React.Dispatch<React.SetStateAction<{ id: string; secret: string, serviceAccountKey?: any }>>;
    targetApp: { id: string; secret: string };
    setTargetApp: React.Dispatch<React.SetStateAction<{ id: string; secret: string }>>;
    migSourceAppSAC: boolean;
    handleSaveAndContinueSourceSercviceApp: (data: any) => Promise<void>;
    handleSaveAndContinueTargetSercviceApp: () => void;
    handleSaveAndContinueSourceSercviceAccount: (data: any) => Promise<void>;
    handleSaveAndContinueTargetSercviceAccount: (data: any) => Promise<void>;
    insertParallelCount: () => void;
    setMigSourceAppSAC: (value: boolean) => void;
    onToggleAccordian: () => void;
    taskId: any;
    migAccountSaved: boolean;
    setMigAccountSaved: React.Dispatch<React.SetStateAction<boolean>>;
}
const MigrationConfiguration: React.FC<MigrationComponentProps> = ({
    mainNewTab,
    value,
    setValue,
    roleCheck,
    totalParallelCount,
    setTotalParallelCount,
    migrationNestedTabButton,
    setMigrationNestedTabButton,
    migrationNestedTab,
    setMigrationNestedTab,
    sourceApp,
    setSourceApp,
    setSourceApps,
    sourceServiceAccounts,
    setsourceServiceAccounts,
    targetServiceAccounts,
    setTargetServiceAccounts,
    targetApp,
    setTargetApp,
    setTargetApps,
    sourceDataError,
    setsourceDataError,
    sourceDataErrorMessage,
    setsourceDataErrorMessage,
    sourceApps,
    targetApps,
    migSourceAppSAC,
    handleSaveAndContinueSourceSercviceApp,
    handleSaveAndContinueTargetSercviceApp,
    handleSaveAndContinueSourceSercviceAccount,
    handleSaveAndContinueTargetSercviceAccount,
    insertParallelCount,
    setMigSourceAppSAC,
    taskId,
    onToggleAccordian,
    migAccountSaved,
    setMigAccountSaved }) => {

    let isPoupClosedAfterSuccess = false;

    const location: any = useLocation().state;
    //console.log(location.state, 'migmaster location');

    const [eventProcessed, setEventProcessed] = useState(false);

    const [connectionData, setConnectionData] = useState<any>({});

    const [environment, serEnvironment] = useState<string>(location?.state?.environment.toLowerCase());

    const [totaltaskParallelCount, setTotalTaskParallelCount] = useState<number>(0);
    const [file, setFile] = useState<string | any>("");
    const jsonRegex = /\.json$/i;

    const [fileInputValue, setFileInputValue] = useState<string>("");

    let appName: any = location?.state?.applicationName

    if (!Array.isArray(appName)) {
        // If it's not an array, convert it to an array
        appName = [appName]
    }


    // PS_07, PS_08, PS_09, PS_10, PS_11, PS_12, PS_13, PS_14, PS_15, PS_16, PS_17, PS_18, PS_19, PS_20, 
    // PS_21, PS_22, PS_23, PS_24, PS_25, PS_26, PS_27, PS_28, PS_29, PS_30
    let [emailValid, setEmailValid] = useState<boolean>(true);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [deleteEmail, setDeleteEmail] = useState<string>('');
    const [deleteTab, setDeleteTab] = useState<string>('');
    const [index, setIndex] = useState<number>();
    const [DeletePopup, setDeletePopup] = useState<boolean>(false);
    const [applicationName, setApplicationName] = useState<string>(appName);
    const [loader, setLoader] = useState<boolean>(false)

    const [secondaryLoader, setSecondaryLoader] = useState<boolean>(false)

    const isOpen = true;
    ////console.log(roleCheck,'roleCheckroleCheckroleCheckroleCheckroleCheckroleCheck')
    const [SourceAppEnable, setSourceAppEnable] = useState<boolean>(true);
    const [migTargetAppSAC, setMigTargetAppSAC] = useState<boolean>(false);
    const [hide, setHide] = useState<boolean>(false);
    const [sourceServiceAccount, setsourceServiceAccount] = useState<any>({ email: '' });
    //const [sourceServiceAccounts, setsourceServiceAccounts] = useState<any[]>([]);
    const [targetServiceAccount, setTargetServiceAccount] = useState<any>({ email: '' });
    //const [targetServiceAccounts, setTargetServiceAccounts] = useState<any[]>([]);

    // --------------------------------------------
    const [deleteSourceServiceAccount, setdeleteSourceServiceAccount] = useState<any[]>([]);
    const [deleteTargetServiceAccount, setdeleteTargetServiceAccount] = useState<any[]>([]);
    // const [targetApp, setTargetApp] = useState<any>({ id: '', secret: '' });
    // const [targetApps, setTargetApps] = useState<any[]>([]);
    // -----------------------------------------------
    const [deleteSourceApp, setDeleteSourceApp] = useState<any[]>([]);
    const [deleteTargetApp, setDeleteTargetApp] = useState<any[]>([]);
    const requiredAppLength: any = Number(value) + 1
    // const [saveAndContinueCheck, setSaveAndContinueCheck] = useState<boolean>(false);

    let history = useHistory()
    const [ctoken, setCToken] = useState<string>(sessionStorage.getItem('clientToken') || '');
    const [tokenExpiration, setTokenExpiration] = useState<number>(parseInt(sessionStorage.getItem('tokenExpiration') || '0'));
    const [atoken, setAToken] = useState<string>(sessionStorage.getItem('a_at') || '');
    const [isAdmin, setIsAdmin] = useState<boolean>(sessionStorage.getItem('isAdmin') === 'true');
    const [objAdmin, setObjAdmin] = useState({})
    const [objClient, setObjClient] = useState({})
    let [adminName, setAdminName] = useState('');
    let [adminEemailAddress, setAdminEmailAddress] = useState('');
    let [adminrole, setAdminRole] = useState('');
    let sessionDetails = { UserName: "", CompanyName: "", EmailAddress: "", ID: 0, Role: "" }
    let [clientName, setclientName] = useState('');
    let [companyName, setCompanyName] = useState('');
    let [emailAddress, setEmailAddress] = useState('');
    let [role, setRole] = useState('');

    let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
    const [isSessionEpired, setisSessionEpired] = useState(false);

    const [alertMessage, setAlertMessage] = useState<string>('')

    let InActiveTimeout = useRef<NodeJS.Timeout | null>(null);

    const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';
    const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';
    const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';
    const [alert, setAlert] = useState<any>('');
    const [clientParallelCount, setClientParallelCount] = useState<number>();

    const [disableActions, setDisableActions] = useState<boolean>(false);

    useEffect(() => {

        // location?.state.environment === location?.state.environment
        const responseRedirect = (event: any) => {
            debugger;

            if (event.data.authorization == `${process.env.REACT_APP_FE_CONNECTORS_URL}/Redirect` && !eventProcessed) {
                let dePass = event.data.authorizationCode;
                //let clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();

                //console.log("token ", clientToken)

                if (migrationNestedTabButton.toLowerCase() == 'source') {

                    //console.log(sourceServiceAccount, "before set")

                    setsourceServiceAccount((prevState: any) => ({
                        ...prevState,
                        authcode: dePass
                    }));

                }

                else {
                    //console.log(targetServiceAccount, "before set")

                    setTargetServiceAccount((prevState: any) => ({
                        ...prevState,
                        authcode: dePass
                    }));
                }
            }
        };

        window.addEventListener("message", responseRedirect);

        return () => {
            window.removeEventListener("message", responseRedirect);
        };


    }, []);


    useEffect(() => {
        debugger

        if ((sourceServiceAccount.authcode || targetServiceAccount.authcode) && !eventProcessed) {
            setEventProcessed(true);
            // triggerBE = false;
            debugger;

            //console.log("connectionData inside useEffect", targetServiceAccount);

            debugger;

            let forward;

            setLoader(false)

            if (migrationNestedTabButton.toLowerCase() == 'source') {
                handleAddAccount('serviceAccount', mainNewTab);
            }
            else {
                handleAddAccount('targetServiceAccount', mainNewTab);
            }
        }

    }, [sourceServiceAccount, targetServiceAccount])


    // PS_31, PS_32, PS_33
    useEffect(() => {
        debugger
        sessionTokenProperties();
        // resetTimer();
        // window.addEventListener('keydown', resetTimer);
        // window.addEventListener('click', resetTimer);
        mainMethod();
        // return () => {
        //     window.removeEventListener('click', resetTimer);
        //     window.removeEventListener('keydown', resetTimer);
        //   }
    }, [])

    useEffect(() => {
        if (totaltaskParallelCount > 0) {
            setValue(totaltaskParallelCount);
        } else {
            setValue(1);
        }
    }, [totaltaskParallelCount]);

    let mainMethod = async () => {
        try {
            debugger
            await sessionTokenProperties();
            if (applicationName.includes('teamschannel') && mainNewTab !== 'assessment') {
                await fetchConnectionData();
                await fetchServiceAccount()
            }
            await fetchServiceApp();
            await fetchTotalParallelCount();
            await fetchTaskParallelCount();
        }
        catch (error) {
            ////console.log(error);
        }
    }

    let resetTimer = async () => {
        try {
            debugger
            let sessionResponse: string = await resetSessionTimer(InActiveTimeout, SessionEpiredDateTime);
            if (sessionResponse == 'expired') {
                debugger
                sessionStorage.removeItem("c_at");
                setisSessionEpired(true)
                setAlert(true)
                setAlertMessage('Your Session has Expired please login again to continue');
            }
            else if (sessionResponse == 'updateToken') {
                if (emailAddress == "" || emailAddress == undefined) {
                    await sessionTokenProperties();
                }
                debugger
                let token = await getToken()

                let payload = {
                    mailId: emailAddress,
                    isAdmin: false,
                    token: token
                };
                let getsessionToken = await getSession(payload);
                const rn_secretKey: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY
                const cs_secretKey: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;

                const c_byteValue = objCryptoJS.AES.decrypt(getsessionToken?.data?.data.sessionToken, rn_secretKey);
                const c_decryptedValue = c_byteValue.toString(objCryptoJS.enc.Utf8);
                let updatedToken = objCryptoJS.AES.encrypt(c_decryptedValue, cs_secretKey).toString();
                await sessionStorage.removeItem("c_at");
                await sessionStorage.setItem('c_at', updatedToken);
                let updatedobjClient: any = jwt(c_decryptedValue);
                SessionEpiredDateTime = updatedobjClient.CreatedAt;
                setSessionEpiredDateTime(SessionEpiredDateTime);
            }
        }
        catch (error) {
            setLoader(false);
            setAlert(true)
            setAlertMessage('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
        }
    }

    const resetSessionTimer = async (InActiveTimeout: any, SessionExpirationDateTime: any) => {
        try {
            debugger
            if (typeof window !== 'undefined') {
                if (InActiveTimeout.current) {
                    clearTimeout(InActiveTimeout.current);
                }

                const tokenExpiryCheckInterval = Number(process.env.REACT_APP_TABIDLESCENARIO);

                InActiveTimeout.current = setTimeout(() => {
                    // console.log("Expired");          
                    sessionStorage.removeItem("c_at");
                    setisSessionEpired(true)
                    setAlert(true)
                    setAlertMessage('Your Session has Expired please login again to continue');
                }, tokenExpiryCheckInterval);

                const currentDateTime = new Date().toISOString();
                //const sessionExpiredDateTime = new Date(SessionExpirationDateTime).toISOString();
                const diffInMilliseconds = Math.abs(new Date(currentDateTime).getTime() - new Date(SessionEpiredDateTime).getTime());
                const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
                console.log("minutes migconfig : ", minutes);

                const sessionUpdateTimeGap = Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP);

                if (minutes > sessionUpdateTimeGap) {
                    return 'updateToken'
                }

                return 'NoActionRequired'
            }
            return 'NoActionRequired'
        }
        catch (ex) {
            throw ex
        }
    };


    // PS_34, PS_35, PS_36, PS_37, PS_38, PS_39, PS_40, PS_41, PS_42, PS_43, PS_44, PS_45, PS_46, PS_47, PS_48, PS_49
    let sessionTokenProperties = async () => {
        try {
            ////console.log("start of session token");

            const tokenResponse: sessionResponse = await decodeSessionDetails();
            debugger
            if (tokenResponse.adminDetails) {
                setObjAdmin(tokenResponse.adminDetails)
                setAdminName(tokenResponse.adminDetails.Name);
                setAdminEmailAddress(tokenResponse.adminDetails.Email);
                setAdminRole(tokenResponse.adminDetails.Role);

                if (tokenResponse?.adminDetails?.Role.toLowerCase().includes('viewer')) {
                    setDisableActions(true)
                }
            }

            if (tokenResponse.clientDetails) {
                setObjClient(tokenResponse.clientDetails)
                setclientName(tokenResponse.clientDetails.Name);
                setCompanyName(tokenResponse.clientDetails.CompanyName)
                emailAddress = tokenResponse.clientDetails.Email;
                setEmailAddress(emailAddress);
                setRole(tokenResponse.clientDetails.Role);

                SessionEpiredDateTime = tokenResponse.clientDetails.CreatedAt;
                setSessionEpiredDateTime(SessionEpiredDateTime);

                if (tokenResponse?.clientDetails?.Role.toLowerCase().includes('viewer')) {
                    setDisableActions(true)
                }
            }
            else {
                history.push("/Login")
            }

            ////console.log("end of session token");

            //#endregion
        } catch (error) {
            setAlert(true);
            setLoader(false)

            console.error('Failed in sesion Token', error);
        }
    };


    const getToken = async () => {
        let clientToken: string;
        let adminToken: string;
        let token: { clientToken?: string; adminToken?: string } = {};
        debugger
        try {
            ////console.log(" start of get token");

            if (objClient !== undefined) {
                let sessionToken: any = sessionStorage.getItem('c_at')
                if (sessionToken) {
                    // sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)
                    // var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
                    // clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
                    token['clientToken'] = sessionToken

                }


            }
            if (objAdmin !== undefined) {
                let sessionToken: any = sessionStorage.getItem('a_at')
                if (sessionToken) {
                    // sessionToken = objCryptoJS.AES.decrypt(sessionToken, a_secretKey)
                    // var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
                    // adminToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();

                    token['adminToken'] = sessionToken

                }
            }
            ////console.log("end of get token");

            return token
        } catch (error) {
            setAlert(true);
            setLoader(false)
            ////console.log("error in getToken");

            throw error
        }
    }

    // Function to validate if an email is valid
    const isValidEmail = (email: string): boolean => {
        return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
    };


    const maskPassword: any = (password: any) => {
        if (!password) return null;

        return '****************************************'

        // return '*'.repeat(password.length);
    }

    const handleIncrement: any = () => {
        if (value < totalParallelCount && !disableActions) {
            setValue(prevValue => prevValue + 1);
        }
    };

    const handleDecrement: any = () => {
        if (value > 0 && !disableActions) {
            setValue(prevValue => prevValue - 1);
        }
    }

    // PS_132, PS_133, PS_134
    const handleClick = (index: any, tab: string, email: any) => {
        try {
            ////console.log("handleclick inside");

            setIndex(index)
            setDeleteTab(tab)
            setDeleteEmail(email)
            setDeletePopup(true)
        } catch (error: any) {
            setAlert(true);
            setLoader(false)

        }
    };

    //PS_218 to 224
    const openMicrosoftAuthWindow = async () => {
        try {
            setEventProcessed(false);
            //console.log("openMicrosoftAuthWindow start");

            sessionStorage.setItem('R_Url', '/Configurations');

            let loaderContent: any = "Please wait. We are connecting the Teams settings";
            setLoader(true);

            const microsoftLoginUrlTemmplate = process.env.REACT_APP_MICROSOFT_AUTH_URL;
            let microsoftLoginUrl = microsoftLoginUrlTemmplate
                ? microsoftLoginUrlTemmplate
                    .replace("{0}", encodeURIComponent(migrationNestedTabButton === 'Source' ? connectionData.sourceData.azureAppId : connectionData.targetData.azureAppId))
                    .replace("{1}", encodeURIComponent(`${process.env.REACT_APP_FE_CONNECTORS_URL}/Redirect`))
                : "";

            const width = 500;
            const height = 600;
            const left = (window.innerWidth - width) / 2;
            const top = (window.innerHeight - height) / 2;

            const newWindow = window.open(microsoftLoginUrl, '_blank', `width=${width},height=${height},left=${left},top=${top}`);

            if (!newWindow) {
                setAlert("Popup has been blocked, enable it to authetnicate your account");
            }

            else {
                newWindow?.postMessage("message", `${process.env.REACT_APP_FE_CONNECTORS_URL}`)
                const checkWindowClosed = setInterval(() => {
                    if (newWindow.closed) {
                        clearInterval(checkWindowClosed);
                        setLoader(false)
                    }
                }, 1000); // Check every second
            }
            //console.log("openMicrosoftAuthWindow end");
        } catch (error) {
            //console.log("openMicrosoftAuthWindow catch");
            setAlert("Cannot able to connect")
        }
    }

    // PS_135, PS_136, PS_137, PS_138, PS_139, PS_140, PS_141, PS_142, PS_143, PS_144, PS_145, PS_146, PS_147, PS_148, PS_149, PS_150, PS_151, PS_152, PS_153, PS_154, PS_155, PS_156, 
    // PS_157, PS_158, PS_159, PS_160, PS_161, PS_162, PS_163, PS_164, PS_165, PS_166, PS_167
    const handleDeleteAccount = async (index: any, tab: string, email: string, mainNewTab: any) => {
        try {
            if (!disableActions) {
                let token = await getToken()
                debugger
                if (mainNewTab === 'assessment') {
                    setDeletePopup(false)
                    setLoader(true)
                    const payload = {
                        token: token,
                        taskId: taskId,
                        connectionType: "source",
                        appId: email
                    };
                    const response: any = await deleteserviceapp(payload);
                    setSourceAppEnable(true)
                    setLoader(false)
                    const updatedAccounts = [...sourceApps];
                    updatedAccounts.splice(index, 1);
                    setSourceApps(updatedAccounts);
                    setDeleteSourceApp([...deleteSourceApp, { email }]);
                }
                else if (tab === 'serviceAccount') {
                    const updatedAccounts = [...sourceServiceAccounts];
                    updatedAccounts.splice(index, 1);
                    setsourceServiceAccounts(updatedAccounts);
                    setLoader(false)
                    setDeletePopup(false)
                    setdeleteSourceServiceAccount([...deleteSourceServiceAccount, { email }]);
                } else if (tab === 'targetServiceAccount') {
                    setDeletePopup(false)
                    const updatedAccounts = [...targetServiceAccounts];
                    updatedAccounts.splice(index, 1);
                    setTargetServiceAccounts(updatedAccounts);
                    setdeleteTargetServiceAccount([...deleteTargetServiceAccount, { email }]);
                }
                else if (tab === 'sourceApp') {
                    setDeletePopup(false)
                    if (sourceApps.length === 1) {
                        setSourceAppEnable(true)
                    }
                    setDeletePopup(false)
                    setLoader(true)
                    const payload = {
                        token: token,
                        taskId: taskId,
                        connectionType: "source",
                        appId: email
                    };
                    const response: any = await deleteserviceapp(payload);

                    setLoader(false)
                    const updatedAccounts = [...sourceApps];
                    updatedAccounts.splice(index, 1);
                    setSourceApps(updatedAccounts);
                    setDeleteSourceApp([...deleteSourceApp, { email }]);
                } else if (tab === 'targetApp') {
                    debugger
                    setDeletePopup(false)
                    setDeletePopup(false)
                    setLoader(true)
                    const payload = {
                        token: token,
                        taskId: taskId,
                        connectionType: "target",
                        appId: email
                    };
                    const response = await deleteserviceapp(payload);
                    setLoader(false)
                    const updatedAccounts = [...targetApps];
                    updatedAccounts.splice(index, 1);
                    setTargetApps(updatedAccounts);
                    setDeleteTargetApp([...deleteTargetApp, { email }]);
                }
            }
        } catch (error: any) {
            setAlert(true);
            setLoader(false)

        }
    };

    const handleServiceAccount = async () => {
        await openMicrosoftAuthWindow()

        // handleAddAccount('sourceApp', mainNewTab); 

        // setMigSourceAppSAC(false)
    }


    // PS_86, PS_87, PS_88, PS_89, PS_90, PS_93, PS_94, PS_98, PS_99, PS_100, PS_101, PS_103, PS_104, 
    // PS_105, PS_106, PS_107, PS_110, PS_111, PS_115, PS_116, PS_117, PS_118, PS_120
    const handleAddAccount = async (tab: string, mainNewTab: any) => {
        try {
            if (!disableActions) {
                let token = await getToken()
                debugger
                if (tab === 'sourceApp') {

                    if (sourceApps.length >= requiredAppLength) {
                        setsourceDataError({
                            ...sourceDataError,
                            sourceServiceAppLength: "",
                        })
                    };
                    let trimmedPassword = sourceApp?.secret?.trim()
                    const trimmedId = sourceApp?.id?.trim()
                    if (trimmedId && trimmedPassword) {

                        trimmedPassword = await encryptHybidMessage(trimmedPassword?.toString())
                        if (sourceApps.some(account => account.id === trimmedId) || targetApps.some(account => account.id === trimmedId)) {

                            setsourceDataError({
                                ...sourceDataError,
                                sourceServiceAppId: 'Duplicate Entry',
                            });
                        }
                        else {
                            setLoader(true)
                            let payload: any
                            if (environment == 'tenant') {
                                payload = {
                                    taskId: taskId,
                                    token: token,
                                    applicationName: applicationName,
                                    azureAppId: trimmedId,
                                    azureAppSecretId: trimmedPassword,
                                    adminMailId: '',
                                    service: 'serviceapp',
                                    authcode: '',
                                    type: "source",
                                    serviceAccountKey: '',
                                    environment: environment.toLowerCase()
                                }
                            }
                            else {
                                payload = {
                                    taskId: taskId,
                                    token: token,
                                    applicationName: applicationName,
                                    azureAppId: '',
                                    azureAppSecretId: '',
                                    adminMailId: '',
                                    service: 'serviceapp',
                                    authcode: '',
                                    type: "source",
                                    serviceAccountKey: sourceApp?.serviceAccountKey,
                                    environment: environment.toLowerCase()
                                }
                            }

                            const result: any = await checkServiceApp(payload)
                            debugger
                            ////console.log(result, "result of service app check");

                            setLoader(false)
                            setFile("")
                            if (result.data.statusCode === 500) {
                                setShowPopup(true)
                                setLoader(false)

                                if (environment.toLowerCase() == 'tenant') {

                                    setsourceDataError({
                                        ...sourceDataError,
                                        showPopupMessage: 'Azure App Id already exists'
                                    });
                                    setSourceApp({ id: '', secret: '' });

                                } else {
                                    setsourceDataError({
                                        ...sourceDataError,
                                        showPopupMessage: 'File already exist'
                                    });
                                }
                                return
                            }
                            else if (result?.data?.statusCode === 501) {
                                setsourceDataError({
                                    ...sourceDataError,
                                    showPopupMessage: 'The entered Azure app secret value is already exists',
                                });
                                setSourceApp({ id: '', secret: '' });
                                setShowPopup(true)
                                setLoader(false)
                                return
                            }
                            else if (result?.data?.statusCode === 400) {
                                setsourceDataError({
                                    ...sourceDataError,
                                    showPopupMessage: result?.data?.statusMessage,
                                });

                                setShowPopup(true)
                                setSourceApp({ id: '', secret: '' });
                                setLoader(false)
                                return
                            }
                            else if (result?.data?.statusCode === 408 || result?.data?.statusCode === 205) {
                                setsourceDataError({
                                    ...sourceDataError,
                                    showPopupMessage: 'Please provide the required scopes',
                                });
                                setShowPopup(true)
                                setSourceApp({ id: '', secret: '' });
                                setLoader(false)
                                return
                            } else {
                                setSourceApps([...sourceApps, { id: trimmedId, secret: trimmedPassword }]);
                                setMigAccountSaved(false);
                                if (mainNewTab === 'assessment') {

                                    let payload = {
                                        taskId: taskId,
                                        token: token,
                                        azureAppId: trimmedId,
                                        azureAppSecretId: trimmedPassword,
                                        type: "source"
                                    };

                                    handleSaveAndContinueSourceSercviceApp(payload);
                                }
                            }
                            setFile("")
                            setSourceApp({ id: '', secret: '' });
                            setsourceDataError({ ...sourceDataError, sourceServiceAppId: '' });
                            setsourceDataError({ ...sourceDataError, sourceServiceAppSecret: '' });
                        }

                    } else {
                        if (environment.toLowerCase() == 'tenant') {
                            setsourceDataError({
                                ...sourceDataError,
                                sourceServiceAppId: sourceApp.id ? '' : sourceDataErrorMessage.sourceServiceAppId,
                                sourceServiceAppSecret: trimmedPassword ? '' : sourceDataErrorMessage.sourceServiceAppSecret
                            });
                        }
                        else {
                            setsourceDataError({
                                ...sourceDataError,
                                sourceServiceAppId: 'Select the valid config file',
                            });
                        }

                    }

                }
                else if (tab === 'targetApp') {

                    setsourceDataError({
                        ...sourceDataError,
                        targetServiceAppLength: "",
                    });
                    let trimmedPassword = targetApp?.secret?.trim();
                    const trimmedId = targetApp?.id?.trim()

                    if (targetApp.id && trimmedPassword) {
                        trimmedPassword = await encryptHybidMessage(trimmedPassword?.toString())
                        if (sourceApps.some(account => account.id === trimmedId) || targetApps.some(account => account.id === trimmedId)) {

                            setsourceDataError({
                                ...sourceDataError,
                                targetServiceAppId: 'Duplicate Entry',
                            });
                        }
                        else {
                            setLoader(true)

                            let payload = {
                                taskId: taskId,
                                token: token,
                                applicationName: applicationName,
                                azureAppId: trimmedId,
                                azureAppSecretId: trimmedPassword,
                                adminMailId: '',
                                service: 'serviceapp',
                                authcode: '',
                                type: "target",
                                serviceAccountKey: '',
                                environment: environment.toLowerCase()
                            }
                            debugger
                            const result = await checkServiceApp(payload)

                            setLoader(false)
                            if (result?.data?.statusCode === 502) {
                                setsourceDataError({
                                    ...sourceDataError,
                                    showPopupMessage: 'Azure App Id already exists',
                                });
                                setTargetApp({ id: '', secret: '' });
                                setShowPopup(true)
                                return
                            }
                            else if (result?.data?.statusCode === 503) {
                                setsourceDataError({
                                    ...sourceDataError,
                                    showPopupMessage: 'The entered Azure app secret value is already exists',
                                });
                                setTargetApp({ id: '', secret: '' });
                                setShowPopup(true)
                                return
                            }
                            else if (result?.data?.statusCode === 400 || result?.data?.statusCode === 205) {
                                setsourceDataError({
                                    ...sourceDataError,
                                    showPopupMessage: result?.data?.statusMessage,
                                });

                                setShowPopup(true)
                                setTargetApp({ id: '', secret: '' });
                                return
                            }
                            else if (result?.data?.statusCode === 408) {
                                setsourceDataError({
                                    ...sourceDataError,
                                    showPopupMessage: 'Please provide the required scopes',
                                });

                                setShowPopup(true)
                                setTargetApp({ id: '', secret: '' });
                                return
                            }
                            else {
                                setTargetApps([...targetApps, { id: targetApp.id, secret: trimmedPassword }]);
                            }

                            setTargetApp({ id: '', secret: '' });
                            setsourceDataError({ ...sourceDataError, targetServiceAppId: '' });
                            setsourceDataError({ ...sourceDataError, targetServiceAppSecret: '' });
                        }
                    }
                    else {
                        // If either email or password is missing, set the appropriate error messages
                        setsourceDataError({
                            ...sourceDataError,
                            targetServiceAppId: targetApp.id ? '' : sourceDataErrorMessage.targetServiceAppId,
                            targetServiceAppSecret: trimmedPassword ? '' : sourceDataErrorMessage.targetServiceAppSecret
                        });

                    }
                }

                else if (tab === 'serviceAccount') {
                    if (sourceServiceAccounts.length >= requiredAppLength) {
                        setsourceDataError({
                            ...sourceDataError,
                            sourceServiceAppLength: "",
                        })
                    };

                    debugger;

                    const trimmedId = sourceServiceAccount.email

                    //console.log(sourceServiceAccount, "admin mailId")

                    if (sourceServiceAccounts.some((account: { email: any; }) => account.email === trimmedId) || targetServiceAccounts.some((account: { email: any; }) => account.email === trimmedId)) {

                        setsourceDataError({
                            ...sourceDataError,
                            sourceAccountEmail: 'Duplicate Entry',
                        });
                    }
                    else {
                        setLoader(true)
                        let payload = {
                            taskId: taskId,
                            token: token,
                            applicationName: applicationName,
                            azureAppId: '',
                            azureAppSecretId: '',
                            adminMailId: trimmedId,
                            service: 'serviceaccount',
                            authcode: sourceServiceAccount.authcode,
                            type: 'source',
                            serviceAccountKey: '',
                            environment: environment.toLowerCase()
                        }


                        const result: any = await checkServiceApp(payload)

                        sourceServiceAccount.authcode = ''

                        debugger
                        //console.log(result.data, "result of service account check");

                        setLoader(false)
                        if (result.data.statusCode === 503) {
                            setsourceDataError({
                                ...sourceDataError,
                                showPopupMessage: 'Azure Admin Email already exists',
                            });
                            setShowPopup(true)
                            setsourceServiceAccount({ email: '' })
                            setLoader(false)
                            return
                        }

                        else if (result?.data?.statusCode === 400) {
                            setsourceDataError({
                                ...sourceDataError,
                                showPopupMessage: 'Enter valid credentials',
                            });

                            setShowPopup(true)
                            setsourceServiceAccount({ email: '' })
                            setLoader(false)
                            return
                        }
                        else if (result?.data?.statusCode === 408 || result?.data?.statusCode === 205) {
                            setsourceDataError({
                                ...sourceDataError,
                                showPopupMessage: 'Please provide the required scopes',
                            });
                            setShowPopup(true)
                            setsourceServiceAccount({ email: '' })
                            setLoader(false)
                            return
                        } else {

                            // setsourceServiceAccount((prevState: any) => ({
                            //     ...prevState,
                            //     refreshToken: result.data.refreshToken
                            //   }));
                            setsourceServiceAccounts([...sourceServiceAccounts, { email: sourceServiceAccount.email, password: result.data.refreshToken }])

                            // if (mainNewTab === 'assessment') {
                            //     handleSaveAndContinueSourceSercviceApp(payload);
                            // }
                        }
                        //setsourceServiceAccount({email: '' })
                        setsourceDataError({ ...sourceDataError, sourceAccountEmail: '' });
                        setsourceDataError({ ...sourceDataError, sourceAccountPassword: '' });
                    }
                }

                else if (tab === 'targetServiceAccount') {
                    if (sourceServiceAccounts.length >= requiredAppLength) {
                        setsourceDataError({
                            ...sourceDataError,
                            sourceServiceAppLength: "",
                        })
                    };

                    debugger;

                    const trimmedId = targetServiceAccount.email

                    //console.log(targetServiceAccount, "admin mailId")

                    if (sourceServiceAccounts.some((account: { email: any; }) => account.email === trimmedId) || targetServiceAccounts.some((account: { email: any; }) => account.email === trimmedId)) {

                        setsourceDataError({
                            ...sourceDataError,
                            targetAccountEmail: 'Duplicate Entry',
                        });
                        setLoader(false)
                    }
                    else {
                        // setLoader(true)

                        setSecondaryLoader(true)
                        let payload = {
                            taskId: taskId,
                            token: token,
                            applicationName: applicationName,
                            azureAppId: '',
                            azureAppSecretId: '',
                            adminMailId: trimmedId,
                            service: 'serviceaccount',
                            authcode: await encryptHybidMessage(sourceServiceAccount.authcode.toString()),
                            type: 'target',
                            serviceAccountKey: '',
                            environment: environment.toLowerCase()
                        }


                        const result: any = await checkServiceApp(payload)

                        sourceServiceAccount.authcode = ''

                        debugger
                        //console.log(result.data, "result of service account check");

                        // setLoader(false)
                        setSecondaryLoader(false)

                        if (result.data.statusCode === 503) {
                            setsourceDataError({
                                ...sourceDataError,
                                showPopupMessage: 'Azure Admin Email already exists',
                            });
                            setShowPopup(true)
                            setTargetServiceAccount({ email: '' })
                            // setLoader(false)

                            setSecondaryLoader(false)
                            return
                        }

                        else if (result?.data?.statusCode === 400) {
                            setsourceDataError({
                                ...sourceDataError,
                                showPopupMessage: result?.data?.statusMessage,
                            });

                            setShowPopup(true)
                            setTargetServiceAccount({ email: '' })
                            // setLoader(false)
                            setSecondaryLoader(false)
                            return
                        }
                        else if (result?.data?.statusCode === 408 || result?.data?.statusCode === 205) {
                            setsourceDataError({
                                ...sourceDataError,
                                showPopupMessage: 'Please provide the required scopes',
                            });
                            setShowPopup(true)
                            setTargetServiceAccount({ email: '' })
                            // setLoader(false)
                            setSecondaryLoader(false)
                            return
                        } else {

                            // setsourceServiceAccount((prevState: any) => ({
                            //     ...prevState,
                            //     refreshToken: result.data.refreshToken
                            //   }));

                            // if (mainNewTab === 'assessment') {
                            //     handleSaveAndContinueSourceSercviceApp(payload);
                            // }
                            setTargetServiceAccounts([...targetServiceAccounts, { email: targetServiceAccount.email, password: result.data.refreshToken }])
                        }
                        setTargetServiceAccount({ email: '' })
                        setsourceDataError({ ...sourceDataError, targetAccountEmail: '' });
                        setsourceDataError({ ...sourceDataError, targetAccountPassword: '' });
                    }
                }
            }
        } catch (error: any) {
            ////console.log("Error in Handle Add account : ", error);

            setAlert('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
            // setLoader(false)
            setSecondaryLoader(false)

        }
    };
    // PS_55, PS_56, PS_57, PS_58, PS_59, PS_60, PS_61, PS_62, PS_63, PS_64, PS_65, PS_66, PS_67, PS_68, PS_69, PS_70, PS_71, PS_72, 
    // PS_73, PS_74, PS_75, PS_76, PS_77, PS_78, PS_79, PS_80, PS_81, PS_82, PS_83, PS_84
    const handleInputChange = (e: any, tab: string) => {
        try {
            if (!disableActions) {
                debugger

                setEmailValid(true)

                emailValid = true

                const { name, value } = e.target;
                debugger
                if (tab === 'serviceAccount') {
                    setEmailValid(isValidEmail(value));
                    setsourceServiceAccount({ ...sourceServiceAccount, [name]: value });
                    if (value.trim() === '') {
                        setsourceDataError({
                            ...sourceDataError,
                            sourceServiceAccount: 'Email Address cannot be empty',
                        });
                    }
                    else if (sourceServiceAccounts.some((account: { email: any; }) => account.email === value.trim()) || targetServiceAccounts.some((account: { email: any; }) => account.email === value.trim())) {

                        setsourceDataError({
                            ...sourceDataError,
                            sourceAccountEmail: 'Duplicate Entry',
                        });
                    }
                    else {
                        setsourceDataError({
                            ...sourceDataError,
                            targetAccountEmail: '',
                        });
                    }
                }
                else if (tab === 'targetServiceAccount') {
                    setEmailValid(isValidEmail(value));
                    setTargetServiceAccount({ ...targetServiceAccount, [name]: value });
                    if (value.trim() === '') {
                        setsourceDataError({
                            ...sourceDataError,
                            targetAccountEmail: 'Email Address cannot be empty',
                        });
                    } else if (isValidEmail(value) === false) {
                        setsourceDataError({
                            ...sourceDataError,
                            targetAccountEmail: 'The Entered Email ID is invalid',
                        });
                    } else if (value.trim().length >= 320) {
                        setsourceDataError({
                            ...sourceDataError,
                            targetAccountEmail: 'The Entered Email ID is invalid',
                        });
                    }


                    else if (sourceServiceAccounts.some((account: { email: any; }) => account.email === value.trim()) || targetServiceAccounts.some((account: { email: any; }) => account.email === value.trim())) {

                        setsourceDataError({
                            ...sourceDataError,
                            targetAccountEmail: 'Duplicate Entry',
                        });
                    }

                    else {
                        setsourceDataError({
                            ...sourceDataError,
                            targetAccountEmail: '',
                        });
                    }
                }
                else if (tab === 'sourceApp') {
                    if (name === 'id') {

                        if (sourceApps.some(account => account.id === value.trim()) || targetApps.some(account => account.id === value.trim())) {

                            setsourceDataError({
                                ...sourceDataError,
                                sourceServiceAppId: 'Duplicate Entry',
                            });
                        }

                        else if (value.trim() === '') {
                            setsourceDataError({
                                ...sourceDataError,
                                sourceServiceAppId: 'App client ID cannot be empty',
                            });
                        }
                        else if (!isValidAppID(value.trim())) {
                            debugger
                            setsourceDataError({
                                ...sourceDataError,
                                sourceServiceAppId: 'Enter valid App client ID',
                            });
                        }
                        else {
                            setsourceDataError({
                                ...sourceDataError,
                                sourceServiceAppId: '',
                            });
                        }
                    }
                    else if (name === 'secret') {
                        const trimmedValue = value.trim();

                        // You can add additional validation logic for password if needed
                        if (trimmedValue === '') {
                            setsourceDataError({
                                ...sourceDataError,
                                sourceServiceAppSecret: 'Azure App Secret Value cannot be empty',
                            });
                        }
                        else if (!isvalidSecret(trimmedValue)) {
                            setsourceDataError({
                                ...sourceDataError,
                                sourceServiceAppSecret: 'Enter Valid App Secret Value',
                            });
                        } else {
                            setsourceDataError({
                                ...sourceDataError,
                                sourceServiceAppSecret: '',
                            });
                        }
                    }
                    setSourceApp({ ...sourceApp, [name]: value });
                }
                else if (tab === 'targetApp') {
                    if (name === 'id') {

                        if (sourceApps.some(account => account.id === value.trim()) || targetApps.some(account => account.id === value.trim())) {

                            setsourceDataError({
                                ...sourceDataError,
                                targetServiceAppId: 'Duplicate Entry',
                            });
                        }

                        else if (value.trim() === '') {
                            setsourceDataError({
                                ...sourceDataError,
                                targetServiceAppId: 'App client ID cannot be empty',
                            });
                        }
                        else if (!isValidAppID(value.trim())) {
                            setsourceDataError({
                                ...sourceDataError,
                                targetServiceAppId: 'Enter valid App client ID',
                            });
                        }

                        else {
                            setsourceDataError({
                                ...sourceDataError,
                                targetServiceAppId: '',
                            });
                        }
                    } else if (name === 'secret') {
                        const trimmedValue = value.trim();

                        // You can add additional validation logic for password if needed
                        if (trimmedValue === '') {
                            setsourceDataError({
                                ...sourceDataError,
                                targetServiceAppSecret: 'Azure App Secret Value cannot be empty',
                            });
                        }
                        else if (!isvalidSecret(trimmedValue)) {
                            setsourceDataError({
                                ...sourceDataError,
                                targetServiceAppSecret: 'Enter valid Azure App Secret Value',
                            });
                        } else {
                            setsourceDataError({
                                ...sourceDataError,
                                targetServiceAppSecret: '',
                            });
                        }

                    }
                    setTargetApp({ ...targetApp, [name]: value });

                }
            }
        } catch (error: any) {
            setAlert(true);
            setLoader(false)

        }
    };

    const fetchConnectionData = async () => {
        try {
            ////console.log("start of fetch service apps");
            debugger;
            let token = await getToken()

            setLoader(true)
            const payload = {

                token: token,
                taskId: taskId,

            }
            const response = await fetchConnectionDataApi(payload);

            //console.log("connection data: ", response.data)
            let result = response.data
            if (result) {
                ////console.log("inside if");
                //console.log("connection data: ", result)

                setConnectionData(result)

                setLoader(false)
            }

            ////console.log("end of fetch service apps");


        } catch (error) {
            setAlert(true);
            setLoader(false)
            ////console.log("error in fetch service apps");


        }
    }

    const fetchServiceApp = async () => {
        try {
            ////console.log("start of fetch service apps");

            let token = await getToken()

            setLoader(true)
            const payload = {

                token: token,
                taskId: taskId,

            }
            const response = await fetchServiceAppApi(payload);
            let result = response.data.data
            if (result) {
                ////console.log("inside if");

                // Separate accounts based on connectionType
                const sourceData = result.filter((account: { connectionType: string }) => account.connectionType === 'source');
                const sourceAccountsData = sourceData.map((account: { clientId: any; clientSecret: any }) => ({
                    id: account.clientId,
                    secret: account.clientSecret
                }));

                ////console.log(sourceAccountsData, "data for source apps");
                setSourceApps(sourceAccountsData); // Set source apps state

                const targetData = result.filter((account: { connectionType: string }) => account.connectionType === 'target');
                const targetAccountsData = targetData.map((account: { clientId: any; clientSecret: any }) => ({
                    id: account.clientId,
                    secret: account.clientSecret
                }));

                ////console.log(targetAccountsData, "data for target apps");
                setTargetApps(targetAccountsData); // Set target apps state
                setLoader(false)

            }

            ////console.log("end of fetch service apps");


        } catch (error) {
            setAlert(true);
            setLoader(false)
            ////console.log("error in fetch service apps");


        }
    }


    const fetchServiceAccount = async () => {
        try {
            ////console.log("start of fetch service apps");

            let token = await getToken()

            setLoader(true)
            const payload = {

                token: token,
                taskId: taskId,
                isPSAccount: 0

            }
            const response = await fetchPsDataApi(payload);
            let result = response.data.data

            //console.log(result, "ps data")
            if (result) {
                ////console.log("inside if");

                // Separate accounts based on connectionType
                const sourceData = result.filter((account: { connectionType: string }) => account.connectionType === 'source');
                const sourceAccountsData = sourceData.map((account: { serviceAccountMailId: any; password: any }) => ({
                    email: account.serviceAccountMailId,
                    password: account.password
                }));

                ////console.log(sourceAccountsData, "data for source apps");
                setsourceServiceAccounts(sourceAccountsData); // Set source apps state

                const targetData = result.filter((account: { connectionType: string }) => account.connectionType === 'target');
                const targetAccountsData = targetData.map((account: { serviceAccountMailId: any; password: any }) => ({
                    email: account.serviceAccountMailId,
                    password: account.password
                }));

                ////console.log(targetAccountsData, "data for target apps");
                setTargetServiceAccounts(targetAccountsData); // Set target apps state
                setLoader(false)

            }

            ////console.log("end of fetch service apps");


        } catch (error) {
            setAlert(true);
            setLoader(false)
            ////console.log("error in fetch service apps");


        }
    }

    // const additionalAccountsRequiredSource: any = requiredAppLength - sourceApps.length
    // const additionalAccountsRequiredTarget: any = requiredAppLength - targetApps.length





    const fetchTotalParallelCount = async () => {
        setLoader(true)
        try {
            let token = await getToken()

            const payload = {
                taskId: taskId,
                token: token,
            }
            const result = await fetchParallelInstance(payload);


            if (result.data) {
                setTotalParallelCount(result.data.availableParallelInstanceCount)
                setClientParallelCount(result.data.purchasedParallelInstance)
                ////console.log("inside if result");
                setLoader(false)
            }
        } catch (error) {
            setAlert(true);
            setLoader(false)

            ////console.log('Error fetching data:', error);
        }
    };


    const fetchTaskParallelCount = async () => {
        setLoader(true)
        try {
            let token = await getToken()

            const payload = {
                taskId: taskId,
                token: token,
            }
            const result = await getTaskParallelInstance(payload);


            if (result.data) {
                setTotalTaskParallelCount(result.data.ParallelInstanceCount)

                //console.log("inside if result");
                setLoader(false)
            }
        } catch (error) {
            setAlert(true);
            setLoader(false)

            //console.log('Error fetching data:', error);
        }
    };
    const availableParallelInstances = Number(totalParallelCount) - Number(value)

    const insertLastVisitedPageValue = async (data: any) => {
        try {
            setLoader(true)
            let token = await getToken()

            const payload = {
                token: token,
                taskId: taskId,
                lastVisitedValue: data,
                lastVisitedStage: 0.2,
            };

            // Insert only newly entered records into the database
            const response: any = await insertLastVisitedPageValueApi(payload);
            setLoader(false)
            if (!response) {
                ////console.log('The current page value failed to insert');
            } else {
                ////console.log('The current page value inserted successfully');

            }
        } catch (error) {
            console.error('Error inserting records:', error);
        }
    };


    //PS_131 to 139
    const OnchangeconnectionData = (e: React.ChangeEvent<HTMLInputElement>) => {

        debugger
        try {
            setsourceDataError({
                ...sourceDataError,
                sourceServiceAppId: '',
                targetServiceAppId: '',

            });
            const { name, value } = e.currentTarget;
            //console.log(name);   
            const file1: any = e.target.files?.[0];
            if (name === 'serviceAccountKey' && !(jsonRegex.test(file1?.name))) {
                setsourceDataError({
                    ...sourceDataError,
                    sourceServiceAppId: 'Select the valid config file',
                });
            }

            else if (name === 'serviceAccountKey') {
                setFile(file1);
                if (file1) {
                    const reader = new FileReader();
                    reader.onload = () => {
                        try {
                            const dePass: any = reader.result as string;
                            const jsonObject = JSON.parse(dePass);
                            debugger
                            if (jsonObject.client_email && jsonObject.private_key) {
                                //console.log(jsonObject.client_email, 'file data')
                                const encrypted = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();


                                setSourceApp({ ...sourceApp, id: jsonObject.client_email, secret: jsonObject.private_key, serviceAccountKey: encrypted });
                            }
                            else {
                                setsourceDataError({
                                    ...sourceDataError,
                                    sourceServiceAppId: 'Select the valid config file',
                                });
                            }
                        }
                        catch (error) {
                            setsourceDataError({
                                ...sourceDataError,
                                sourceServiceAppId: 'Select the valid config file',
                            });
                        }
                    };
                    reader.readAsText(file1);
                }
            } else {
                setsourceDataError({
                    ...sourceDataError,
                    sourceServiceAppId: 'Select the valid config file',
                });
            }
        }
        catch (error) {
            //console.log(error, "uuuuuuuu")
            setAlert('Something went wrong! Please try again or reach AVAMIGRATRON Support.')
        }

    }

    //handleMigrationTargetTab Fn: is triggered when the target button in Migration tab is clicked
    const handleMigrationTargetTab = () => {
        try {
            setMigrationNestedTabButton('Target');

            if (applicationName.includes('teamschannel')) {
                setMigrationNestedTab('serviceAccount');
            }

        } catch (err) {
            console.error('Error in handleMigrationTargetTab')
        }
    }

    //console.log("appp ------------------> ", sourceApps)

    //console.log("erroeOB------------", sourceDataError)

    //console.log("service accounts ------------------> ", sourceServiceAccounts)

    //console.log("REACT_APP_FE_CONNECTORS_URL------------------> ", process.env.REACT_APP_FE_CONNECTORS_URL)

    return (
        <div className="col-md-12 mt-4 mt-md-8">
            {(mainNewTab === 'Migration' || mainNewTab === 'assessment') ?
                <div
                    className="tab-pane fade show active"
                    id="v-pills-migration"
                    role="tabpanel"
                    aria-labelledby="v-pills-migration-tab"
                >
                    {mainNewTab !== 'assessment' && (

                        <div>
                            <label
                                htmlFor="parallel-instance"
                                className="form-label custom-label font-14 font-semibold"
                            >
                                Parallel Instance
                                <span className="mandatory-color">*{" "}</span><Tippy
                                    delay={500}
                                    arrow={true}
                                    //   followCursor={'horizontal'}
                                    //   plugins={[followCursor]}
                                    placement='top'
                                    content="Allows you to adjust the number of simultaneous processes running concurrently, speeding up migration tasks.">
                                    <img
                                        src="/images/tooltip.svg"
                                        className="cursor-pointer"
                                        alt="tooltip"
                                    />
                                </Tippy>
                            </label>

                            <div className="row mb-4">
                                <div className="col-md-5">
                                    <div className="input-group custom-form border-0 w-md-50 me-3 mb-2 mb-md-0">
                                        <button
                                            className="btn custom-form px-3 "
                                            type="button"
                                            id="button-addon2"
                                            onClick={handleDecrement}
                                            disabled={disableActions ? true : roleCheck.toLowerCase().includes('viewer') || value === 1 || migrationNestedTabButton == 'Target'}
                                        >
                                            <img
                                                src="/images/minus-line.svg"
                                                alt="Minus"
                                            />
                                        </button>
                                        <input
                                            type="text"
                                            className="form-control custom-form font-regular font-14"
                                            id="parallel-instance"
                                            value={value}
                                            disabled
                                            // defaultValue={4}
                                            aria-label="parallel-instance"
                                            aria-describedby="button-addon2"
                                        />
                                        <button
                                            className="btn custom-form px-3 "
                                            type="button"
                                            id="button-addon2"
                                            onClick={handleIncrement}
                                            disabled={disableActions ? true : roleCheck.toLowerCase().includes('viewer') || migrationNestedTabButton == 'Target'}
                                        >
                                            <img
                                                src="/images/plus-input.svg"
                                                alt="Plus"
                                            />
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="d-flex gap-2 align-items-center justify-content-center font-12 p-2 tab-grey-color info-div-bg border-radius-8">
                                        <Tippy
                                            delay={500} // Provide delay as a single number
                                            arrow={true}
                                            followCursor={'horizontal'}
                                            // plugins={[followCursor]}
                                            // placement='top'
                                            content="Your available parallel Instances count is displayed here based on the Number of Available instance/Total instance count"
                                        >
                                            <img
                                                src="/images/tooltip.svg"
                                                alt="tooltip"
                                                width={11}
                                            />
                                        </Tippy>

                                        <span>Your available Parallel Instance{" "}</span>
                                        <span className="font-bold font-12 ms-1">
                                            {" "}
                                            {availableParallelInstances <= 0 ? 0 : availableParallelInstances}/{clientParallelCount}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <ul
                        className="nav nav-pills mb-4"
                        id="pills-tab"
                        role="tablist"
                    >
                        {mainNewTab !== 'assessment' && (

                            <li
                                className="nav-item"
                                role="presentation"
                            >
                                <button
                                    onClick={() => setMigrationNestedTabButton('Source')}
                                    className={` nav-link theme-tab font-16 ${migrationNestedTabButton === 'Source' ? 'active' : ''} `}
                                    // className="nav-link theme-tab active font-16"
                                    data-bs-toggle="pill"
                                    data-bs-target="#Sourcemigration"
                                    type="button"
                                    role="tab"
                                    aria-selected="true"
                                >
                                    Source
                                </button>
                            </li>
                        )}
                        {mainNewTab !== 'assessment' && (

                            <li
                                className="nav-item"
                                role="presentation"
                            >
                                <button
                                    onClick={handleMigrationTargetTab}
                                    className={` nav-link theme-tab font-16 ${migrationNestedTabButton === 'Target' ? 'active' : ''} `}
                                    // className="nav-link theme-tab font-16"
                                    data-bs-toggle="pill"
                                    data-bs-target="#Targetmigration"
                                    type="button"
                                    role="tab"
                                    aria-selected="false"
                                    disabled={sourceApps.length == 0 || !migAccountSaved && sourceApps.length == 1 || ((sourceApps.length > 0) ? false : SourceAppEnable)}
                                >
                                    Target
                                </button>
                            </li>
                        )}
                    </ul>
                    <div
                        className="tab-content"
                        id="pills-tabContent"
                    >
                        <div
                            className="tab-pane fade show active"
                            id="Sourcemigration"
                            role="tabpanel"
                        >
                            {mainNewTab !== 'assessment' && (

                                <h5 className="font-16 font-bold mb-2">
                                    {`Throttle Handling and Parallel
                                     Migration `}{(migrationNestedTabButton.toLowerCase() === 'target' && applicationName.includes('teamschannel') && migrationNestedTab === 'serviceAccount') ? `- Service Accounts` : `- Service Apps`}
                                </h5>
                            )}
                            {mainNewTab == 'assessment' && (
                                <h5 className="font-20 font-bold mb-2">
                                    Throttle Handling
                                    <span className="mandatory-color ms-1">*</span>
                                </h5>
                            )}
                            {(migrationNestedTabButton.toLowerCase() === 'target' && applicationName.includes('teamschannel')) ?
                                <ul className="nav nav-pills mb-2 border p-1 border-radius-8 color-white-v1 d-inline-flex" id="pills-tab" role="tablist">

                                    <li className="nav-item" role="presentation">
                                        <button
                                            onClick={() => setMigrationNestedTab('serviceAccount')}
                                            className={`nav-link custom-service-tabs font-regular font-14 tab-grey-color border-radius-8 ${migrationNestedTab === 'serviceAccount' ? 'active' : ''}`}
                                            id="pills-sourceserviceapp-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-sourceserviceapp"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-sourceserviceapp"
                                            aria-selected={migrationNestedTab === 'serviceAccount' ? 'true' : 'false'}
                                        >
                                            Service Account
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            onClick={() => disableActions ? setMigrationNestedTab('serviceApp') : targetServiceAccounts?.length > value ? setMigrationNestedTab('serviceApp') : null}
                                            className={`nav-link custom-service-tabs font-regular font-14 tab-grey-color border-radius-8 ${migrationNestedTab === 'serviceApp' ? 'active' : ''}`}
                                            id="pills-sourceserviceapp-tab"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-sourceserviceapp"
                                            aria-selected={migrationNestedTab === 'serviceApp' ? 'true' : 'false'}
                                        >
                                            Service Apps
                                        </button>
                                    </li>
                                </ul>
                                :
                                <></>
                            }



                            <div
                                className="tab-content"
                                id="pills-tabContent"
                            >

                                {(mainNewTab === 'Migration' || mainNewTab === 'assessment') && migrationNestedTabButton === 'Source' ? <div
                                    className="tab-pane fade show active"
                                    id="pills-sourceserviceapp"
                                    role="tabpanel"
                                    aria-labelledby="pills-sourceserviceapp-tab"
                                >
                                    <p className="font-14 font-regular mb-3">
                                        {environment.toLowerCase() == 'tenant' ? 'Service apps enhance source data extraction, enabling parallel processing and reduce throttling.' : 'By adding the service account key , you can seamlessly integrate the service apps to manage parallel migration tasks and handle throttle issues effectively.'}
                                    </p>
                                    <div className="table-responsive theme-table bg-white mt-3">
                                        <table className="table table-borderless table-shadow mb-0">
                                            <thead>
                                                {environment.toLowerCase() == 'tenant' ?
                                                    <tr>

                                                        <th className="text-nowrap">
                                                            App Client ID
                                                        </th>
                                                        <th className="text-nowrap">
                                                            App Secret Value
                                                        </th>
                                                        <th className="text-nowrap text-center">
                                                            Action
                                                        </th>
                                                    </tr>
                                                    :

                                                    <tr>

                                                        <th className="text-nowrap">
                                                            Service Account Key
                                                        </th>

                                                        <th className="text-nowrap text-center">
                                                            Action
                                                        </th>
                                                    </tr>}

                                            </thead>
                                            <tbody>
                                                <tr>
                                                    {environment.toLowerCase() == 'tenant' ?
                                                        <>
                                                            <td className='align-top' >
                                                                <input
                                                                    type="text"
                                                                    className="form-control custom-form font-14 font-regular"
                                                                    placeholder="Enter App Client ID"
                                                                    name="id"
                                                                    value={sourceApp.id.trim()}
                                                                    onChange={(e) => handleInputChange(e, 'sourceApp')}
                                                                    disabled={disableActions ? true : roleCheck.toLowerCase().includes('viewer')}
                                                                />
                                                                <span className="mandatory-color fixedError">
                                                                    {sourceDataError.sourceServiceAppId}
                                                                </span>
                                                            </td>
                                                            <td className='align-top' >
                                                                <div className="position-relative">
                                                                    <input
                                                                        type={hide ? "text" : "password"}
                                                                        className="form-control password-eye-hide custom-form font-14 font-regular py-2"
                                                                        placeholder="Enter App Secret Value"
                                                                        name="secret"
                                                                        value={sourceApp.secret.trim()}
                                                                        onChange={(e) => handleInputChange(e, 'sourceApp')}
                                                                        disabled={disableActions ? true : roleCheck.toLowerCase().includes('viewer')}
                                                                    />
                                                                    <img
                                                                        className={`position-absolute ${hide ? 'custom-open-eye-postion' : 'custom-open-eye-postion'}`}
                                                                        src={`/images/${hide ? `password-eye-open.svg` : `password-icon.svg`}`}
                                                                        alt="password-icon"
                                                                        onClick={() => { setHide(!hide); }}

                                                                    />
                                                                    <span className="mandatory-color fixedError passExpansion">
                                                                        {sourceDataError.sourceServiceAppSecret}
                                                                    </span>
                                                                </div>

                                                            </td>

                                                        </>
                                                        :
                                                        <>
                                                            <td>
                                                                {/* <span >
                                                                    <input className="form-control" type="file"
                                                                        id="formFile"
                                                                        //disabled={isSourceConnection ? disableSourceFields : disableTargetFields}
                                                                        name='serviceAccountKey'
                                                                        placeholder={file?.name ? file.name : "No file chosen"}
                                                                        onChange={OnchangeconnectionData}
                                                                        accept=".json"
                                                                    />
                                                                </span> */}

                                                                {/* <input
                                                                    type="text"
                                                                    className="form-control file-input-field custom-form font-14 font-regular py-2"
                                                                    placeholder={file?.name ? file.name : "No file chosen"}
                                                                    aria-label="Example text with button addon"
                                                                    readOnly
                                                                    value={fileInputValue}
                                                                /> */}


                                                                <div className="input-group mb-3">

                                                                    <input className="form-control" type="file"
                                                                        id="formFile"
                                                                        //disabled={isSourceConnection ? disableSourceFields : disableTargetFields}
                                                                        name='serviceAccountKey'
                                                                        onChange={OnchangeconnectionData}
                                                                        accept=".json"
                                                                    />
                                                                    {/* </span>
                                  <input
                                    type="text"
                                    className="form-control file-input-field custom-form font-14 font-regular py-2"
                                    placeholder={file?.name ? file.name : "No file chosen"}
                                    aria-label="Example text with button addon"
                                    readOnly
                                    value={fileInputValue}
                                  /> */}
                                                                </div>



                                                                <span className="mandatory-color fixedError">
                                                                    {sourceDataError.sourceServiceAppId}
                                                                </span>

                                                            </td>
                                                        </>
                                                    }
                                                    <td className="text-center" style={{ verticalAlign: 'top', paddingTop: '1.3rem' }}>
                                                        <span className="d-inline-block">
                                                            <button disabled={(disableActions ? true : roleCheck.toLowerCase().includes('viewer') || sourceDataError.sourceServiceAppId != '' || (environment.toLowerCase() == 'tenant' && sourceDataError.sourceServiceAppSecret != '') || sourceApp.id == '' || sourceApp.secret == '')}

                                                                onClick={(e) => { handleAddAccount('sourceApp', mainNewTab); setMigSourceAppSAC(false) }} className="btn action-rounded-btn primary-action d-flex align-items-center justify-content-center">
                                                                <img
                                                                    src="/images/blue-plus-icon.svg"
                                                                    alt="blue-plus-icon"
                                                                />
                                                            </button>
                                                        </span>
                                                    </td>
                                                </tr>
                                                {sourceApps?.map((acc, index) => (
                                                    <tr key={index}>
                                                        {environment.toLowerCase() == 'tenant' ?
                                                            <>
                                                                <td className="text-nowrap">{acc.id}</td>
                                                                <td>{maskPassword(acc.secret)}</td>
                                                            </> :
                                                            <td>{acc.id}</td>
                                                        }

                                                        <td className="text-center">
                                                            <span className='d-inline-block' >
                                                                <button onClick={() => handleClick(index, 'sourceApp', acc.id)} disabled={disableActions ? true : roleCheck.toLowerCase().includes('viewer')} className="btn action-rounded-btn primary-action d-flex align-items-center justify-content-center">
                                                                    <img src="/images/red-delete-icon.svg" alt="delete-icon" />

                                                                </button>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>

                                    {mainNewTab !== 'assessment' && (

                                        <div className="d-flex justify-content-end mt-3">
                                            <button disabled={disableActions ? true : (sourceApps.length === 0 || totalParallelCount <= 0 || migSourceAppSAC === true) || roleCheck.toLowerCase().includes('viewer')} onClick={() => { handleSaveAndContinueSourceSercviceApp('') }} className="btn primary-btn text-nowrap font-semibold text-white font-14 d-flex gap-2 justify-content-center align-items-center">
                                                Save & Continue
                                            </button>
                                        </div>
                                    )}

                                    <span className="mandatory-color fixedError">
                                        {sourceDataError.sourceServiceAppLength}
                                    </span>
                                </div> : null}
                            </div>
                        </div>
                        {(mainNewTab === 'Migration' || mainNewTab !== 'assessment') && migrationNestedTabButton === 'Target' ? <div
                            className="tab-pane fade show active "
                            id="Targetmigration"
                            role="tabpanel"
                        >
                            <div
                                className="tab-content"
                                id="pills-tabContent"
                            >

                                {mainNewTab === 'Migration' && migrationNestedTabButton === 'Target' && migrationNestedTab.toLowerCase() === 'serviceaccount' && applicationName.includes('teamschannel') ? <div
                                    className="tab-pane fade show active"
                                    id="pills-targetserviceapp"
                                    role="tabpanel"
                                    aria-labelledby="pills-targetserviceapp  -tab"
                                >
                                    <p className="font-14 font-regular">
                                        Service accounts enhance target data ingestion, enabling parallel processing and reduce throttling.
                                    </p>
                                    <div className="table-responsive theme-table bg-white mt-2 ">
                                        <table className="table table-borderless mb-0">
                                            <thead>
                                                <tr>
                                                    <th className="text-nowrap">
                                                        Email Address
                                                    </th>
                                                    <th className="text-nowrap text-center">
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className='align-top' >
                                                        <input
                                                            type="text"
                                                            className="form-control custom-form font-14 font-regular"
                                                            placeholder="Enter Email Address"
                                                            name="email"
                                                            value={targetServiceAccount.email}
                                                            onChange={(e) => handleInputChange(e, 'targetServiceAccount')}
                                                            disabled={disableActions ? true : roleCheck.toLowerCase().includes('viewer')}

                                                        />
                                                        {sourceDataError.targetAccountEmail &&
                                                            <span className="mandatory-color fixedError">
                                                                {sourceDataError.targetAccountEmail}
                                                            </span>}
                                                    </td>

                                                    <td className="text-center">
                                                        <span className="d-inline-block">
                                                            <button
                                                                disabled={disableActions ? true : roleCheck.toLowerCase().includes('viewer') || !emailValid || sourceDataError.targetAccountEmail != '' || targetServiceAccount.email == ''}
                                                                onClick={(e) => {
                                                                    if (!disableActions)
                                                                        handleServiceAccount()
                                                                }} className="btn action-rounded-btn primary-action d-flex align-items-center justify-content-center">
                                                                <img
                                                                    src="/images/blue-plus-icon.svg"
                                                                    alt="blue-plus-icon"
                                                                />
                                                            </button>
                                                        </span>
                                                    </td>
                                                </tr>
                                                {targetServiceAccounts.map((acc: { email: any; password: any; }, index: React.Key | null | undefined) => (
                                                    <tr key={index}>
                                                        <td>{acc.email}</td>

                                                        <td className="text-center">
                                                            <span className='d-inline-block' >
                                                                <button onClick={() => handleClick(index, 'targetServiceAccount', acc?.email)} disabled={disableActions ? true : roleCheck.toLowerCase().includes('viewer')} className="btn action-rounded-btn primary-action d-flex align-items-center justify-content-center">
                                                                    <img src="/images/red-delete-icon.svg" alt="delete-icon" />

                                                                </button>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))}

                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="d-flex justify-content-end mt-3">
                                        <button disabled={disableActions ? true : (targetServiceAccounts.length === 0) || roleCheck.toLowerCase().includes('viewer')}
                                            onClick={() => { handleSaveAndContinueTargetSercviceAccount('') }} className="btn primary-btn text-nowrap font-semibold text-white font-14 d-flex gap-2 justify-content-center align-items-center">
                                            Save & Continue
                                        </button>
                                    </div>
                                    <span className="mandatory-color fixedError">
                                        {sourceDataError.targetServiceAccountLength}
                                    </span>
                                </div> : null}

                                {mainNewTab === 'Migration' && migrationNestedTabButton === 'Target' && migrationNestedTab === 'serviceApp' ? <div
                                    className="tab-pane fade show active"
                                    id="pills-targetserviceapp"
                                    role="tabpanel"
                                    aria-labelledby="pills-targetserviceapp  -tab"
                                >
                                    <p className="font-14 font-regular">
                                        Service apps validate credentials and accelerating bulk data processing.
                                    </p>
                                    <div className="table-responsive theme-table bg-white mt-3 ">                                        <table className="table table-borderless mb-0">
                                        <thead>
                                            <tr>
                                                <th className="text-nowrap">
                                                    App Client ID
                                                </th>
                                                <th className="text-nowrap">
                                                    App Secret Value
                                                </th>
                                                <th className="text-nowrap text-center">
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className='align-top' >
                                                    <input
                                                        type="text"
                                                        className="form-control custom-form font-14 font-regular"
                                                        placeholder="Enter App Client ID"
                                                        name="id"
                                                        value={targetApp.id.trim()}
                                                        onChange={(e) => handleInputChange(e, 'targetApp')}
                                                        disabled={disableActions ? true : roleCheck.toLowerCase().includes('viewer')}
                                                    />
                                                    {sourceDataError.targetServiceAppId !== "" && (
                                                        <span className="mandatory-color fixedError">{sourceDataError.targetServiceAppId}</span>
                                                    )}
                                                </td>
                                                <td className='align-top' >
                                                    <div className="position-relative">
                                                        <input
                                                            type={hide ? "text" : "password"}
                                                            className="form-control password-eye-hide custom-form font-14 font-regular py-2"

                                                            placeholder="Enter App Secret Value"
                                                            name="secret"
                                                            value={targetApp.secret.trim()}
                                                            onChange={(e) => handleInputChange(e, 'targetApp')}
                                                            disabled={disableActions ? true : roleCheck.toLowerCase().includes('viewer')}
                                                        />
                                                        <img
                                                            className={`position-absolute ${hide ? 'custom-open-eye-postion' : 'custom-open-eye-postion'}`}
                                                            src={`/images/${hide ? `password-eye-open.svg` : `password-icon.svg`}`}
                                                            alt="password-icon"
                                                            onClick={() => { setHide(!hide); }}

                                                        />
                                                        {sourceDataError.targetServiceAppSecret !== "" && (
                                                            <span className="mandatory-color fixedError">{sourceDataError.targetServiceAppSecret}</span>
                                                        )}

                                                    </div>
                                                </td>
                                                <td className="text-center" style={{ verticalAlign: 'top', paddingTop: '1.3rem' }}>
                                                    <span className="d-inline-block">
                                                        <button disabled={disableActions ? true : (roleCheck.toLowerCase().includes('viewer') || sourceDataError.targetServiceAppId != '' || sourceDataError.targetServiceAppSecret != '' || targetApp.id == '' || targetApp.secret == '')}
                                                            onClick={(e) => { handleAddAccount('targetApp', mainNewTab); setMigTargetAppSAC(false) }} className="btn action-rounded-btn primary-action d-flex align-items-center justify-content-center">
                                                            <img
                                                                src="/images/blue-plus-icon.svg"
                                                                alt="blue-plus-icon"
                                                            />
                                                        </button>
                                                    </span>
                                                </td>
                                            </tr>
                                            {targetApps.map((acc, index) => (
                                                <tr key={index}>
                                                    <td className="text-nowrap">{acc.id}</td>
                                                    <td>{maskPassword(acc.secret)}</td>
                                                    <td className="text-center">
                                                        <span className='d-inline-block' >
                                                            <button onClick={() => handleClick(index, 'targetApp', acc.id)} disabled={disableActions ? true : roleCheck.toLowerCase().includes('viewer')} className="btn action-rounded-btn primary-action d-flex align-items-center justify-content-center">
                                                                <img src="/images/red-delete-icon.svg" alt="delete-icon" />

                                                            </button>
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>
                                    </div>
                                    <div className="d-flex justify-content-end mt-3">
                                        <button disabled={disableActions ? true : targetApps.length === 0 || migTargetAppSAC === true || totalParallelCount <= 0}
                                            onClick={() => { insertLastVisitedPageValue('GeneralConfigurations-Notifications'), handleSaveAndContinueTargetSercviceApp(); insertParallelCount() }} className="btn primary-btn text-nowrap font-semibold text-white font-14 d-flex gap-2 justify-content-center align-items-center">
                                            Save & Continue
                                        </button>
                                    </div>
                                    <span className="mandatory-color fixedError">
                                        {sourceDataError.targetServiceAppLength}
                                    </span>
                                </div> : null}
                            </div>

                        </div> : null}
                    </div>
                </div> : null}
            {/*Second Tab - Migration Ends here*/}



            {/* </div> */}
            {loader && (
                <div className="overlay overlay-front">
                    <div className="position-absolute top-50 start-50 translate-middle">
                        <div
                            className="spinner-border Loader loader-color align-center "
                            role="status"
                        >
                            <span>
                                <span className="loader-inner-circle" />
                            </span>
                        </div>
                        <p className="loading-text font-16 font-semibold color-white-v2 mt-2">
                            Loading..
                        </p>
                    </div>
                </div>

            )}


            {secondaryLoader && (
                <div className="overlay overlay-front">
                    <div className="position-absolute top-50 start-50 translate-middle">
                        <div
                            className="spinner-border Loader loader-color align-center "
                            role="status"
                        >
                            <span>
                                <span className="loader-inner-circle" />
                            </span>
                        </div>
                        <p className="loading-text font-16 font-semibold color-white-v2 mt-2">
                            Loading..
                        </p>
                    </div>
                </div>
            )}


            {showPopup === true ? (
                <div className="modal fade show" id="Failure-popup" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="Failure-popupLabel" aria-modal="true" role="dialog" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.8)" }}>

                    <div className="modal-dialog modal-dialog-centered">

                        <div className="modal-content popup-brd-radius">

                            <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">

                                <span className="position-absolute">

                                    <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />

                                </span>

                            </div>

                            <div className="modal-body border-0 text-center">

                                <h5 className="modal-title mb-3 primary-header" id="Failure-popupLabel">Failure</h5>

                                <p className="primary-data">{isSessionEpired ? alertMessage : sourceDataError.showPopupMessage}</p>

                            </div>

                            <div className="modal-footer border-0 pb-4 justify-content-center">

                                <button type="button" onClick={() => {
                                    if (isSessionEpired) {
                                        history.push('/Login')
                                    }
                                    setShowPopup(false)
                                }}
                                    className="btn primary-btn font-14 px-4 font-semibold" data-bs-dismiss="modal">{isSessionEpired ? "OK" : "Retry"}</button>

                            </div>

                        </div>

                    </div>

                </div>) : null}
            {DeletePopup === true ? (
                <div
                    className="modal fade show"
                    id="Failure-popup"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabIndex={-1} // Change this line
                    aria-labelledby="Failure-popupLabel"
                    aria-modal="true"
                    role="dialog"
                    style={{ display: "block", backgroundColor: "rgba(0,0,0,0.8)" }}
                >
                    <div className="modal-dialog modal-dialog-centered">

                        <div className="modal-content popup-brd-radius">

                            <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">

                                <span className="position-absolute">

                                    <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />

                                </span>

                            </div>

                            <div className="modal-body border-0 text-center">

                                <h5 className="modal-title mb-3 primary-header" id="Failure-popupLabel">Alert</h5>

                                <p className="primary-data">Are you sure you want to Delete?</p>

                            </div>

                            <div className="modal-footer border-0 pb-4 justify-content-center">

                                <button type="button" onClick={() => {
                                    handleDeleteAccount(index, deleteTab, deleteEmail, mainNewTab)
                                }}
                                    className="btn primary-btn font-14 px-4 font-semibold" data-bs-dismiss="modal">Yes</button>
                                <button type="button" onClick={() => {
                                    setDeletePopup(false)

                                }}
                                    className="btn primary-btn font-14 px-4 font-semibold" data-bs-dismiss="modal">No</button>

                            </div>

                        </div>

                    </div>

                </div>) : null}

            {alert ? <div
                className="modal fade show"
                tabIndex={-1}
                style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content popup-brd-radius">
                        <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                            <span className="position-absolute">
                                <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
                            </span>
                        </div>
                        <div className="modal-body border-0 text-center">
                            <h5
                                className="modal-title mb-3 primary-header"
                                id="Failure-popupLabel"
                            >
                                { }
                            </h5>
                            <p className="primary-data">
                                {alert ? alert : 'Something went wrong! Please try again or reach AVAMIGRATRON Support.'}
                            </p>
                        </div>
                        <div className="modal-footer border-0 pb-4 justify-content-center">
                            <button
                                type="button"
                                className="btn primary-btn font-14 px-4 font-semibold"
                                onClick={() => {
                                    setAlert('')
                                }}
                            >
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div> : null}

        </div>

    )

};

export default MigrationConfiguration;