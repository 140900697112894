//PS_01,PS_02
import React, { useState } from 'react';
import { useEffect, useRef } from 'react';
import { useLocation, useHistory } from "react-router-dom";
import { sessionResponse } from '../../Common/Interface';
import { decodeSessionDetails } from '../../Services/CommonServices';
import objCryptoJS from "crypto-js";
import { insertLastVisitedPageValueApi } from '../../Services/MigrationAndManageMigrationServices';
// import { fetchApplicationNames } from '../../Services/GeneralConfigurationService/appSpecificConfigurationsApi';
import { fetchApplicationNames } from '../../Services/MigrationAndManageMigrationServices';

import ManageMigration from './ManageMigrationAccordian';
import jwt from 'jwt-decode'
import { getSession } from '../../Services/manageClientAPI';
import SelectMigration from './MigrationAccordian';
// PS_03, PS_04, PS_05, PS_06, PS_07, PS_08, PS_09, PS_10, PS_11, PS_12, PS_13, PS_14, PS_15, PS_16, PS_17, PS_18, PS_19
const MigrationManageMigration: React.FC<any> = () => {
  let location: any = useLocation().state;
  let history = useHistory();

  const stage = 4;
  //PS_03
  const [clientId, setClientId] = useState<any>(location?.state?.clientId || sessionStorage.getItem("m_c_i") || "");
  const [taskId, setTaskId] = useState<any>(location?.state?.taskId || 2);
  ////console.log(location.state, "location:::::");

  const [taskName, setTaskName] = useState<any>(location?.state?.taskName || "Sample Task");


  const [isDirectMigration, setIsDirectMigration] = useState<boolean>((location?.state?.isDirectMigration || location?.state?.isDirect) && (location?.state?.applicationName.toLowerCase() != 'outlook')) // Need to use location.state
  //console.log(isDirectMigration, "direct in mig manage screen");
  // console.log(location.state, 'loc in migandmanage screen');

  const [isActualUserMigration, setIsActualUserMigration] = useState<boolean>(true) // Need to use location.state
  const [applicationName, setApplicationName] = useState<any>(location?.state?.applicationName || 'teamschannel')

  const [loading, setLoading] = useState<boolean>(false);
  const [imageSource, setImageSource] = useState<string>("")

  const [openAccordion, setOpenAccordion] = useState<string>('migration'); // State to track which accordion is open

  const [migrationStatus, setMigrationStatus] = useState<boolean>(false);
  const [manageMigrationStatus, setManageMigrationStatus] = useState<boolean>(false);

  const [enableFinishButton, setEnableFinishButton] = useState<boolean>(false);

  const [reloadMigration, setReloadMigration] = useState<boolean>(false);
  const [reloadManageMigration, setReloadManageMigration] = useState<boolean>(false);
  const [ctoken, setCToken] = useState<string>(sessionStorage.getItem('clientToken') || '');
  const [tokenExpiration, setTokenExpiration] = useState<number>(parseInt(sessionStorage.getItem('tokenExpiration') || '0'));
  const [atoken, setAToken] = useState<string>(sessionStorage.getItem('a_at') || '');
  const [isAdmin, setIsAdmin] = useState<boolean>(sessionStorage.getItem('isAdmin') === 'true');
  const [objAdmin, setObjAdmin] = useState({})
  const [objClient, setObjClient] = useState({})
  let [adminName, setAdminName] = useState('');
  let [adminEemailAddress, setAdminEmailAddress] = useState('');
  let [adminrole, setAdminRole] = useState('');
  let sessionDetails = { UserName: "", CompanyName: "", EmailAddress: "", ID: 0, Role: "" }
  let [clientName, setclientName] = useState('');
  let [companyName, setCompanyName] = useState('');
  let [emailAddress, setEmailAddress] = useState('');
  let [role, setRole] = useState('');
  const [alert, setAlert] = useState<string>('');
  let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState('');
  const [isSessionEpired, setisSessionEpired] = useState(false);

  const [disableActions, setDisableActions] = useState(false);

  // //debugger
  let InActiveTimeout = useRef<NodeJS.Timeout | null>(null);
  const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';
  const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';
  const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';

  const applicationImageSource: { [key: string]: string } = {
    outlook: "/images/ms-outlook-logo.svg",
    sharedmailbox: "/images/shared-mailbox-icon.svg",
    roomsandequipments: "/images/rooms-n-equipment-icon.svg",
    teamschannel: "/images/teams-logo.svg",
    groups: "/images/user-groups.svg",
    onedrive: "/images/onedrive.svg",
    teamschat: "/images/teams-logo.svg",
    sharepoint: "/images/sharepoint.svg",
    gmail: "/images/gmail.svg",
    mydrive: "/images/my drive.svg",
    shareddrive: "/images/shareddrive.svg",
    ggroups: "/images/ggroups.svg",
    sites: "/images/sites.svg",
    chats: "/images/chats.svg"

  }

  // PS_20, PS_21,  
  useEffect(() => {
    sessionTokenProperties();
    resetTimer();
    fetchApplicationName();
    window.addEventListener('keydown', resetTimer);
    window.addEventListener('click', resetTimer);

    return () => {
      if (InActiveTimeout.current) {
        console.log("Migration & Manage Migration Unmount Time Clear");
        clearTimeout(InActiveTimeout.current);
      }
      window.removeEventListener('click', resetTimer);
      window.removeEventListener('keydown', resetTimer);
    };

  }, []);


  let resetTimer = async () => {
    try {
      console.log("Reset Timer in MM - Master");

      let sessionResponse: string = await resetSessionTimer(InActiveTimeout);
      if (sessionResponse == 'expired') {
        debugger
        console.log("Expired Timer in MM - Master");
        sessionStorage.removeItem("c_at");
        setisSessionEpired(true)
        setAlert('Your Session has Expired please login again to continue');
      }
      else if (sessionResponse == 'updateToken') {
        if (emailAddress == "" || emailAddress == undefined) {
          await sessionTokenProperties();
        }
        debugger
        let token = await getToken()
        let payload = {
          mailId: emailAddress,
          isAdmin: false,
          token: token
        };
        console.log("Payload MM - Master : ", payload);
        let getsessionToken = await getSession(payload);
        const rn_secretKey: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY
        const cs_secretKey: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;

        // const c_byteValue = objCryptoJS.AES.decrypt(getsessionToken?.data?.data.sessionToken, rn_secretKey);
        // const c_decryptedValue = c_byteValue.toString(objCryptoJS.enc.Utf8);
        // let updatedToken = objCryptoJS.AES.encrypt(c_decryptedValue, cs_secretKey).toString();
        // console.log("Old Token : ", await sessionStorage.getItem("c_at"));
        await sessionStorage.removeItem("c_at");
        await sessionStorage.setItem('c_at', getsessionToken?.data?.data.sessionToken);
        await sessionTokenProperties();
        // let updatedobjClient: any = jwt(c_decryptedValue);
        // SessionEpiredDateTime = updatedobjClient.CreatedAt;
        // setSessionEpiredDateTime(SessionEpiredDateTime);
      }
      ////console.log("Reset Timer End");
    }
    catch (error) {
      console.log("Reset Timer Catch in MM : ", error);
      setAlert('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
      setLoading(false);
    }
  }

  const resetSessionTimer = async (InActiveTimeout: any) => {
    try {
      // debugger
      ////console.log("Reset Session Timer Start");
      // Assuming InActiveTimeout is a number representing the ID of the current timeout

      if (typeof window !== 'undefined') {
        ////console.log("Reset Session Timer Inside");
        if (InActiveTimeout.current) {
          console.log("Timeout clear in MM");
          clearTimeout(InActiveTimeout.current);
        }
        const tokenExpiryCheckInterval = Number(process.env.REACT_APP_TABIDLESCENARIO);

        InActiveTimeout.current = setTimeout(() => {
          ////console.log("Expired");
          console.log("Session Expired in MM");
          sessionStorage.removeItem("c_at");
          ////console.log("removed session");
          setisSessionEpired(true)
          setAlert('Your Session has Expired please login again to continue');
          // return 'expired'
        }, tokenExpiryCheckInterval);

        ////console.log("Reset Session Timer checking");

        const currentDateTime = new Date().toISOString();
        // //const sessionExpiredDateTime = new Date(SessionExpirationDateTime).toISOString();

        const diffInMilliseconds = Math.abs(new Date(currentDateTime).getTime() - new Date(SessionEpiredDateTime).getTime());
        ////console.log("diffInMilliSeconds values:", diffInMilliseconds);

        const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
        console.log("minutes : ", minutes);

        const sessionUpdateTimeGap = Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP);
        ////console.log("Reset Session Timer Differece : ", sessionUpdateTimeGap);

        if (minutes > sessionUpdateTimeGap) {

          console.log("Reset Session Timer need to update MM");
          return 'updateToken'
        }

        console.log("Reset Session Timer End MM");
        return 'NoActionRequired'
      }
      return 'NoActionRequired'
    }
    catch (ex) {
      console.log("Reset Session Timer Catch MM : ", ex);
      throw ex
    }
  };


  let sessionTokenProperties = async () => {
    try {
      const tokenResponse: any = await decodeSessionDetails();
      debugger
      if (tokenResponse.adminDetails) {
        setObjAdmin(tokenResponse.adminDetails)
        setAdminName(tokenResponse.adminDetails.Name);
        setAdminEmailAddress(tokenResponse.adminDetails.Email);
        setAdminRole(tokenResponse.adminDetails.Role);

        if (tokenResponse?.adminDetails?.Role.toLowerCase().includes('viewer')) {
          setDisableActions(true)
        }
      }

      if (tokenResponse.clientDetails) {
        setObjClient(tokenResponse.clientDetails)
        setclientName(tokenResponse.clientDetails.Name);
        setCompanyName(tokenResponse.clientDetails.CompanyName)
        emailAddress = tokenResponse.clientDetails.Email;
        setEmailAddress(emailAddress);
        setRole(tokenResponse.clientDetails.Role);
        SessionEpiredDateTime = tokenResponse.clientDetails.CreatedAt;
        setSessionEpiredDateTime(SessionEpiredDateTime);

        if (tokenResponse?.clientDetails?.Role.toLowerCase().includes('viewer')) {
          setDisableActions(true)
        }
      }
      else {
        history.push("/Login")
      }
      //#endregion
    } catch (error) {
      setLoading(false)

      console.error('Failed in main method', error);
    }
  };


  const getToken = async () => {
    let clientToken: string;
    let adminToken: string;
    let token: { clientToken?: string; adminToken?: string } = {};
    debugger
    try {
      if (objClient != undefined) {
        let sessionToken: any = sessionStorage.getItem('c_at')
        if (sessionToken) {
          // sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)
          // var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          // clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
          token['clientToken'] = sessionToken

        }


      }
      if (objAdmin !== undefined) {
        let sessionToken: any = sessionStorage.getItem('a_at')
        if (sessionToken) {
          // sessionToken = objCryptoJS.AES.decrypt(sessionToken, a_secretKey)
          // var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          // adminToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();

          token['adminToken'] = sessionToken

        }
      }
      return token
    } catch (error) {

      setLoading(false)

      throw error
    }
  }
  // PS_22, PS_23, PS_24, PS_25, PS_26, PS_27, PS_28, PS_29, PS_30    
  const fetchApplicationName = async () => {
    try {
      let token = await getToken()
      // ////console.log(taskId, clientId);
      setLoading(true);
      const applicationNames: any = await fetchApplicationNames({
        token,
        taskId,
      });

      //PS_19, PS_20, PS_21
      ////console.log(applicationNames.data.data[0].applicationName, "This is the application name");

      setApplicationName(applicationNames.data.data[0][0].applicationName.toLowerCase().trim());
      //console.log(applicationNames.data,"applicationNames.data");

      setIsDirectMigration(applicationNames.data.data[1][0].isDirectMigration)
      if (applicationNames.data.data[0].applicationName === "outlook") {
        setImageSource("/images/ms-outlook-logo.svg")
      } else if (applicationNames.data.data[0].applicationName === "sharedmailbox") {
        setImageSource("/images/shared-mailbox-icon.svg")
      } else if (applicationNames.data.data[0].applicationName === "roomsandequipments") {
        setImageSource("/images/rooms-n-equipment-icon.svg")
      } else if (applicationNames.data.data[0].applicationName === "teamschannel") {
        setImageSource("/images/teams-logo.svg")
      } else if (applicationNames.data.data[0].applicationName === "groups") {
        setImageSource("/images/user-groups.svg")
      }

    } catch (error: any) {
      return error.message;
    } finally {
      setLoading(false);
    }
  };


  //  PS_22, PS_23, PS_24
  const handleAccordionToggle = (accordionName: any) => {
    setOpenAccordion(accordionName === openAccordion ? null : accordionName);
  };

  const fetchDataInManageMigration = async () => {
    setReloadManageMigration(!reloadManageMigration);
  };

  const fetchDataInMigration = async () => {
    setReloadMigration(!reloadMigration);
  };

  useEffect(() => {
    // Check if appSpecificConfigSaveStatus has changed to true
    if (manageMigrationStatus && migrationStatus) {
      setEnableFinishButton(true)
    }
  }, [manageMigrationStatus, migrationStatus]);


  const insertLastVisitedPageValue = async (page: string) => {
    try {
      //console.log(page, "This is the last inserted pageeeee");

      let token = await getToken()

      const payload = {
        token: token,
        taskId: taskId,
        lastVisitedValue: page
      };

      ////console.log(payload, 'last visited');

      // Insert only newly entered records into the database
      const response: any = await insertLastVisitedPageValueApi(payload);

      if (!response) {
        ////console.log('The current page value failed to insert');
      } else {
        ////console.log('The current page value inserted successfully');

      }
      history.push('/Task')
    } catch (error) {
      console.error('Error inserting records:', error);
    }
  };

  return (
    <div className="container-fluid container-height">
      <div className="row container-height">
        <div className="col-md-12">
          <div className="card px-4 py-4 mt-3 mb-3 border-0 rounded-3" style={{ height: '96%' }}>
            <ul className="breadcrumb-item mb-4 ps-0">
              <li>
                <a
                  className="pe-0"
                  onClick={() => {
                    history.push('/Task');
                  }}
                >
                  Task
                </a>
              </li>
              <li className="pe-2">{location?.state?.taskName || "Sample Task"}</li>
            </ul>
            <div className="d-flex align-items-center justify-content-between mb-4 flex-wrap gap-3 mt-2">
              <h5 className="primary-header mb-0 d-flex align-items-center">
                <a >
                  <img
                    src="/images/head-back-arrow.svg"
                    alt="back-arrow"
                    width={19}
                    style={{ cursor: 'pointer' }}
                    className="me-2"
                    onClick={
                      () => {
                        history.push('/Task');
                      }
                    }
                  />
                </a>
                {location?.state?.taskName || "Sample Task"} - Migration
                {/* User Migration icon */}
                <img
                  src={applicationImageSource[location?.state?.applicationName || ''] || ''}
                  alt={"Application Icon"}
                  width={23}
                  className="ms-2"
                />
                {/* Team logo */}
                {/* <img src="/images/teams-logo.svg" alt="teams-logo" width="29" class="ms-2"> */}
                {/* Sharepoint logo */}
                {/* <img src="/images/Microsoft-SharePoint-Logo.svg" alt="Sharepoint-logo" width="28" class="ms-2"> */}
                {/* Outlook logo */}
                {/* <img src="/images/ms-outlook-logo.svg" alt="outlook-logo" width="28" class="ms-2"> */}
              </h5>
            </div>
            {/* Wizard structure starts here */}
            <div className="row justify-content-center">
              <div className="col-md-7">
                <div className="d-flex justify-content-between align-items-center mt-3 mb-5 step-wizard">
                  <div className="d-flex align-items-center gap-1 bg-white z-index-1 cursor-pointer"
                    onClick={() => {
                      return history.push('/selectapps', {
                        state: {
                          ...location.state,
                          taskId: location.state.taskId,
                          taskName: location.state.taskName,
                          applicationName: location?.state?.applicationName,
                          isEdit: true,
                          pageType: 'selectapps',
                          taskType: 'migration',
                          stage: ((location?.state?.stage) && (location?.state?.stage > stage)) ? location?.state?.stage : stage,

                        }
                      })

                    }
                    }>
                    <div className="wizard-step-num d-flex justify-content-center align-items-center font-bold font-14"
                    >
                      1
                    </div>
                    <span className="bg-white me-1 font-14 font-semibold d-none d-xl-block wizard-step-text">
                      Select App
                    </span>
                  </div>

                  <div className="d-flex align-items-center gap-1 bg-white z-index-1 cursor-pointer"
                    onClick={() => {
                      history.push('/Connectors', {
                        state:
                          { ...location.state, isEdit: true, taskId: taskId, taskName: location?.state?.taskName || 'Sample Task', taskType: 'migration', stage: stage, applicationName: applicationName }
                      })
                    }}>
                    <div className="wizard-step-num d-flex justify-content-center align-items-center font-bold font-14 ">
                      2
                    </div>
                    <span className="bg-white me-1 font-14 font-semibold d-none d-xl-block wizard-step-text">
                      Connectors
                    </span>
                  </div>
                  <div className="d-flex align-items-center gap-1 bg-white z-index-1 cursor-pointer"
                    onClick={() => {

                      return history.push('/Configurations', {
                        state: {
                          ...location.state,
                          isEdit: true,
                          stage: ((location?.state?.stage) && (location?.state?.stage > stage)) ? location?.state?.stage : stage
                        }
                      })
                    }}>
                    <div className="wizard-step-num d-flex justify-content-center align-items-center font-bold font-14 " >
                      3
                    </div>
                    <span className="bg-white me-1 font-14 font-semibold d-none d-xl-block wizard-step-text" >
                      Configurations
                    </span>
                  </div>
                  <div className="d-flex align-items-center gap-1 bg-white z-index-1">
                    <div className="wizard-step-num d-flex justify-content-center align-items-center font-bold font-14 active">
                      4
                    </div>
                    <span className="bg-white me-1 font-14 font-semibold d-none d-xl-block wizard-step-text active">
                      Migration
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* Wizard structure ends here */}

            <SelectMigration
              taskId={taskId}
              clientId={clientId}
              applicationName={applicationName}
              isDirectMigration={isDirectMigration}
              isActualUserMigration={isActualUserMigration}
              isOpen={openAccordion === 'migration'}
              onToggle={() => handleAccordionToggle('migration')}
              onSelectMigrationSave={() => { handleAccordionToggle('managemigration') }}
              reloadMigration={reloadMigration}
              fetchDataInManageMigration={fetchDataInManageMigration}
            />

            <ManageMigration
              taskId={taskId}
              clientId={clientId}
              applicationName={applicationName}
              taskName={taskName}
              isOpen={openAccordion === 'managemigration'}
              onToggle={() => handleAccordionToggle('managemigration')}
              onManageMigrationSave={() => { handleAccordionToggle('migration') }}
              reloadMigration={reloadManageMigration}
              fetchDataInMigration={fetchDataInMigration}
            />

          </div>
        </div>
      </div>

      {loading && (
        <div className="container-fluid">
          <div className="overlay">
            <div className="position-absolute top-50 start-50 translate-middle">
              <div
                className="spinner-border Loader loader-color align-center "
                role="status"
              >
                <span>
                  <span className="loader-inner-circle"></span>
                </span>
              </div>
              <p className="loading-text font-16 font-semibold color-white-v2 mt-2">
                Loading..
              </p>
            </div>
          </div>
        </div>
      )}
      {alert ? <div
        className="modal fade show"
        tabIndex={-1}
        style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content popup-brd-radius">
            <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
              <span className="position-absolute">
                <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
              </span>
            </div>
            <div className="modal-body border-0 text-center">
              <h5
                className="modal-title mb-3 primary-header"
                id="Failure-popupLabel"
              >
                { }
              </h5>
              <p className="primary-data">
                {alert}
              </p>
            </div>
            <div className="modal-footer border-0 pb-4 justify-content-center">
              <button
                type="button"
                className="btn primary-btn font-14 px-4 font-semibold"
                onClick={() => {
                  if (isSessionEpired) {
                    history.push('/Login')
                  }
                  setAlert('')
                }}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div> : null}
      {/*Failure Popup ends here */}


    </div>
  )
}

export default MigrationManageMigration