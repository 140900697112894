import { Admin, Client, sessionResponse } from "../Common/Interface";
import { decryptTokenInBE } from "./taskService";
import forge from 'node-forge'

export async function decodeSessionDetails(): Promise<sessionResponse> {
  try {
    const c_local: any = sessionStorage.getItem("c_at");
    // const cs_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
    // let objClient: Client | undefined;

    const a_local: any = sessionStorage.getItem("a_at");
    // const as_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY;
    let objAdmin: Admin | undefined;
    // Ensure the values are present before attempting encryption
    if (!c_local && !a_local) {
      console.log("Failed to retrieve session data");
    }

    let token: any = {
      clientDetails: c_local,
    };

    // Encrypt the admin token (a_at) if present
    if (a_local) {
      token.adminDetails = a_local;
    }
    const response: any = await decryptTokenInBE(token);
    const decodedDetails: sessionResponse = {
      clientDetails: response?.data?.data?.clientDetails,
      adminDetails: response?.data?.data?.adminDetails,
    };

    return decodedDetails;
  } catch (error) {
    console.error("Failed to decode session details", error);
    throw error;
  }
}


export const encryptHybidMessage = async (message: string): Promise<any> => {
    try {
        debugger
        const publicKeyPem: string = process.env.REACT_APP_ENCRYPTION_PUBLIC_KEY as string;
        const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);
 
        // Generate a random AES key
        const aesKey = forge.random.getBytesSync(32); // 32 bytes = 256 bits
 
        // Encrypt the message with the AES key
        const cipher = forge.cipher.createCipher('AES-GCM', aesKey);
        const iv = forge.random.getBytesSync(12);
        cipher.start({ iv: iv });
        cipher.update(forge.util.createBuffer(message));
        cipher.finish();
        const encryptedMessage = cipher.output.getBytes();
        const tag = cipher.mode.tag.getBytes();
 
        // Encrypt the AES key with the RSA public key
        const encryptedKey = publicKey.encrypt(aesKey, 'RSA-OAEP', {
            md: forge.md.sha256.create(),
            mgf: forge.mgf.mgf1.create(forge.md.sha1.create()) // Use 'mgf' from 'forge.mgf'
        });
 
        // Encode the encrypted data for transmission
        const payload = {
            iv: forge.util.encode64(iv),
            key: forge.util.encode64(encryptedKey),
            message: forge.util.encode64(encryptedMessage),
            tag: forge.util.encode64(tag)
        };
 
        return payload;
    } catch (error) {
        debugger
        throw error;
    }
};
 