import React, { useState, useEffect } from "react";
// import fetchLicenseRenewalData from "../../Services/Service/licenseRenewalService"; // Import your API function
import { assignLicense,  revokeLicense } from "../../Services/manageClientAPI";
import { useHistory, useLocation } from "react-router-dom"
import moment from "moment";
import { Filter, sessionResponse } from "../../Common/Interface";
import { getAdminLicenseRenewalData } from "../../Services/DashboardServices";
import { decodeSessionDetails } from "../../Services/CommonServices";
import objCryptoJS from "crypto-js";
import { commonErrorMsgs } from "../../Common/Constant";
import { getSession } from "../../Services/manageClientAPI";
import jwt from 'jwt-decode'


//PS_02
interface InputField {
  resourceCount: string;
  resourceCost: string;
  parallelInstanceCount: string;
  parallelInstanceCost: string;
  assessmentCount: string;
  assessmentCost: string;
  licenseCount: string;
  licenseCost: string;
  parallelInstanceItemCount: string
  reason: string
}
// interface ErrorValidation {
//     resourceCount?: string;
//     resourceCost?: string;
//     parallelInstanceCount?: string;
//     parallelInstanceCost?: string;
//     parallelInstanceItemCount?: string;
//     parallelInstanceItemCost?: string;
//     assessmentCount?: string;
//     assessmentCost?: string;
//     licenseCount?: string;
//     licenseCost?: string;
// }
interface ErrorValidation {

  [key: string]: string;
}
interface LocationState {
  action?: string;
  id?: number;
  companyName?: string
}



const AssignOrRevokeLicense: React.FC = () => {
//PS_03 TO PS_13
  const [pageTitle, setPageTitle] = useState('Assign License');
  const location = useLocation<LocationState>();
  const action = location.state?.action;
  useEffect(() => {
    setPageTitle(action === 'assign' ? 'Assign License' : 'Revoke License');
  }, [action]);
  const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';
  const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';
  const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';
  const [objAdmin, setObjAdmin] = useState({})
  const [objClient, setObjClient] = useState({})
  const [adminLicenseRenewal, setAdminLicenseRenewal] = useState<any[]>([]);
  const [alert, setAlert] = useState<boolean>(false)
  const [alertMessage, setAlertMessage] = useState<string>('')
  const [totalDataCount, setTotalDataCount] = useState(0)
  const [searchValue, setSearchValue] = useState<string>("");
  const [sortBy, setSortBy] = useState<string>('cl.licenseExpiredAt');
  const [sortOrder, setSortOrder] = useState<string>('asc');
  const [dropdownOptions, setDropdownOptions] = useState<any[]>([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [fromDateFilter, setFromDate] = useState("");
  const [toDateFilter, setToDate] = useState("");
  const [loading, setLoading] = useState(false); // Add loading state
  const [pageSize, setPageSize] = useState<number>(10);
  const [filter, setFilter] = useState<Filter>({
    planType: null,
    fromDate: null,
    toDate: null,
  });
  const [filtershow, setfiltershow] = useState(false)
  let history = useHistory()
  const [loader, setLoader] = useState<boolean>(false);
  const [gsuitePurchased, setGsuitePurchased] = useState<boolean>(false);
  const [tenantPurchased, setTenantPurchased] = useState<boolean>(false);
  const [lackPurchased, setSlackPurchased] = useState<boolean>(false);
  let [clientName, setclientName] = useState('');
  let [clientId, setClientId] = useState<number>();
  let [companyName, setCompanyName] = useState<string>();
  let [emailAddress, setEmailAddress] = useState('');
  let [role, setRole] = useState('');
  let [isAdmin, setIsAdmin] = useState(false);
  let [adminName, setAdminName] = useState('');
  let [adminEmailAddress, setAdminEmailAddress] = useState('');
  let [adminrole, setAdminRole] = useState('');
  let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
  const [isSessionEpired, setisSessionEpired] = useState(false);
  const [productType, setProductType] = useState('');
  const [selectedApplications, setSelectedApplications] = useState<any>([]);
  const [inputFields, setInputFields] = useState<InputField>({
    resourceCount: '',
    resourceCost: '',
    parallelInstanceCount: '',
    parallelInstanceCost: '',
    assessmentCount: '',
    assessmentCost: '',
    licenseCount: '',
    licenseCost: '',
    parallelInstanceItemCount: '',
    reason: ''
  });
  const [touchedFields, setTouchedFields] = useState<{ [key: string]: boolean }>({});
  const [successPopup, setSuccessPopup] = useState(false);
  const [successPopupType, setSuccessPopupType] = useState('')
  const [showSubmitButton, setShowSubmitButton] = useState(false);
  const [errorValidation, setErrorValidation] = useState<{ [key: string]: string }>({});
  // //debugger
  const applications = {
    'Tenant': [ 'OneDrive','Outlook','SharePoint', 'Teams Channel'],
    'GSuite': ['Gmail', 'My Drive'],
    'Slack': ['Slack']
  };
companyName = location?.state?.companyName
  useEffect(() => {
    sessionTokenProperties();

  }, [sortBy, sortOrder, pageSize, filter]);

  let sessionTokenProperties = async () => {
    try {
      const tokenResponse: sessionResponse = await decodeSessionDetails();
      debugger
      if (window.location.pathname.toString().toLowerCase().includes("admin")) {
        if (tokenResponse?.adminDetails) {
          setObjAdmin(tokenResponse.adminDetails)
          setAdminName(tokenResponse.adminDetails.Name);
          setAdminEmailAddress(tokenResponse.adminDetails.Email);
          setAdminRole(tokenResponse.adminDetails.Role);
        }
        else {
          history.push("/Admin/Login")
        }
      }
      //#endregion
    } catch (error) {
      console.error('Failed in main method', error);
    }
  };



  const getToken = async () => {
    let clientToken: string;
    let adminToken: string;
    let token: { clientToken?: string; adminToken?: string } = {};
    debugger
    try {
      if (objClient != undefined) {
        let sessionToken: any = sessionStorage.getItem('c_at')
        if (sessionToken) {
          // sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)
          // var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          // clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
          token['clientToken'] = sessionToken

        }


      }
      if (objAdmin != undefined) {
        let sessionToken: any = sessionStorage.getItem('a_at')
        if (sessionToken) {
          // sessionToken = objCryptoJS.AES.decrypt(sessionToken, a_secretKey)
          // var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          // adminToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();

          token['adminToken'] = sessionToken

        }
      }
      return token
    } catch (error) {
      throw error
    }
  }
  //PS_14
  useEffect(() => {
    validateInputs();
  }, [inputFields.resourceCost, inputFields.resourceCount, inputFields.parallelInstanceCost, inputFields.parallelInstanceCount, inputFields.assessmentCost, inputFields.assessmentCount, inputFields.licenseCost, inputFields.licenseCount]);



  const validateFieldsAssignLicense = () => {
    let isValid = true;
   
    if (selectedApplications.length === 0) {

      setErrorValidation(errorValidation => ({ ...errorValidation, selectedApplications: "" }))
      isValid=false
    }
    else {
      setErrorValidation(errorValidation => ({ ...errorValidation, selectedApplications: "" }))
    }

    
    
    return isValid
  };
  const validateFieldsRevokeLicense = () => {
    let isValid = true;
   
    if (selectedApplications.length === 0) {

      setErrorValidation(errorValidation => ({ ...errorValidation, selectedApplications: "" }))
      isValid=false
    }
    else {
      setErrorValidation(errorValidation => ({ ...errorValidation, selectedApplications: "" }))
    }
    if (inputFields.reason === "") {
      setErrorValidation(errorValidation => ({ ...errorValidation, reason: "" }))
      isValid=false

    }
    else {
      setErrorValidation(errorValidation => ({ ...errorValidation, reason: "" }))
    }
    return isValid
  };
  const handleProductTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newProductType = event.target.value;
    setProductType(newProductType);
    setSelectedApplications([]);
    setErrorValidation({});
  };
  const handleOnChangeTextArea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value;

    // Check if the input is alphanumeric
    if (!(/^[a-zA-Z0-9 ]{0,250}$/).test(newValue)) {
      setErrorValidation(prevState => ({
        ...prevState,
        // reason: 'Only alphanumeric characters are allowed.',
      }));
      return;
    } else {
      setErrorValidation(prevState => ({
        ...prevState, reason: ""
      }))
    }


    // Check if the input exceeds 250 characters
    if (newValue.length > 250) {
      setErrorValidation(prevState => ({
        ...prevState,
        reason: 'Input cannot exceed 250 characters.',
      }));
      return;
    }
    else {
      setErrorValidation(prevState => ({
        ...prevState, reason: ""
      }))
    }
    setInputFields((prev) => ({ ...prev, reason: newValue }));
  }

  //PS_23
  const handleApplicationChange = (app: string) => {
    const lowercaseApp = app.toLowerCase(); // Convert to lowercase for comparison
  
    if (selectedApplications.some((a: string) => a.toLowerCase() === lowercaseApp)) {
      // Remove the app if it's already selected (case-insensitive comparison)
      setSelectedApplications(selectedApplications.filter((a: string) => a.toLowerCase() !== lowercaseApp));
    } else {
      // Add the original app name (preserving case) to the selectedApplications array
      setSelectedApplications([...selectedApplications, app]);
    }
  
    if (app !== "") {
      setErrorValidation(errorValidation => ({ ...errorValidation, selectedApplications: "" }));
    }
  };
  
  


  const validateInputs = () => {
    const newErrors: { [key: string]: string } = {};

    if (inputFields.resourceCost && inputFields.resourceCount === "") {
      newErrors.resourceCount = "Resource utilization Days cannot be empty";
    }
    if (inputFields.parallelInstanceCost && inputFields.parallelInstanceCount === "") {
      newErrors.parallelInstanceCount = "Parallel Instance Count Cannot be empty";
    }
    if (inputFields.assessmentCost && inputFields.assessmentCount === "") {
      newErrors.assessmentCount = "Assessment Count Cannot be empty";
    }
    if (inputFields.licenseCost && inputFields.licenseCount === "") {
      newErrors.licenseCount = "License Count Cannot be empty";
    }

    // Only update state if there are errors
    if (Object.keys(newErrors).length > 0) {
      setErrorValidation(prevErrors => ({ ...prevErrors, ...newErrors }));
    }

    // Return true if there are no errors, false otherwise
    return Object.keys(newErrors).length === 0;
  };

//PS_29
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    // Filter out non-numeric characters (keep digits and decimal point)
    const numericValue = value.replace(/[^0-9.]/g, '').slice(0, 10);;

    // Ensure only one decimal point
    const formattedValue = numericValue.split('.').slice(0, 2).join('.');

    // Update the input field state with the formatted numeric value
    setInputFields((prev) => ({ ...prev, [name]: formattedValue }));

    // Clear any existing error for this field
    setErrorValidation(prev => {
      const newErrors = { ...prev };
      delete newErrors[name];
      return newErrors;
    });
    if (inputFields.assessmentCount || inputFields.licenseCount || inputFields.parallelInstanceCount || inputFields.resourceCount) {
      setShowSubmitButton(true)
    }
  };
 clientId = location?.state?.id
  //console.log("the clientId hai",clientId);
  
//PS_38
  const handleAssignLicenseSubmit = async () => {

    let valid = false;
    valid = validateFieldsAssignLicense()
    let token = await getToken()
    if (valid) {
      setLoader(true)
      let assignLicensePayload = {
        token: token,
        clientId: clientId,
        resourceCount:Number(inputFields.resourceCount),
        parallelCount: Number(inputFields.parallelInstanceCount),
        licenseCount: Number(inputFields.licenseCount),
        assessmentCount: Number(inputFields.assessmentCount),
        productType: productType,
        applications: selectedApplications
      }
      try {
        let data: any = await assignLicense(assignLicensePayload)
        setLoader(false)
        //console.log("response", data);

        if (data.status === 201) {
          setInputFields({
            resourceCount: '',
            resourceCost: '',
            parallelInstanceCount: '',
            parallelInstanceCost: '',
            assessmentCount: '',
            assessmentCost: '',
            licenseCount: '',
            licenseCost: '',
            parallelInstanceItemCount: '',
            reason: ''

          })
          
          setSelectedApplications("")
          setProductType("")
          setSuccessPopup(true)
          setSuccessPopupType("Assign")
          //console.log("License Assigned Successfully", data);
          // history.push('/Admin/ManageClients/AdminClient',{state:{id:clientId}})
        } 
        else if(data.status === 400){
          setAlert(true)
          setAlertMessage("License Cannot be Assigned")
        }
        else{
          setAlert(true)
          setAlertMessage("License Cannot be Assigned")
        }

        //console.log('Form submitted:', { productType, selectedApplications, inputFields });
        // Here you would typically make an API call to submit the data
      } catch (error: any) {
        //console.log("Error in assigning the License");

      }
    }
    else{
      setLoader(false)
      setAlert(true)
    }
  };

  //PS_38 to PS_53
  const handleRevokeLicenseSubmit = async () => {

    let valid = false;
    valid = validateFieldsRevokeLicense()
    let token = await getToken()
    //console.log("token start", token);
    //console.log("the satrt", valid);
    if (valid) {
      setLoader(true)


      let revokeLicensePayload = {
        token: token,
        clientId: clientId,
        resourceCount:Number(inputFields.resourceCount),
        parallelCount: Number(inputFields.parallelInstanceCount),
        licenseCount: Number(inputFields.licenseCount),
        assessmentCount: Number(inputFields.assessmentCount),
        productType: productType,
        applications: selectedApplications
      }
      try {
        let data: any = await revokeLicense(revokeLicensePayload)
        setLoader(true)
        //console.log('payload:', revokeLicensePayload);

        if (data.status === 200) {
          setInputFields({
            resourceCount: '',
            resourceCost: '',
            parallelInstanceCount: '',
            parallelInstanceCost: '',
            assessmentCount: '',
            assessmentCost: '',
            licenseCount: '',
            licenseCost: '',
            parallelInstanceItemCount: '',
            reason: ''

          })
          debugger
          setSelectedApplications("")
          setProductType("")
          setSuccessPopup(true)
          setSuccessPopupType("Revoke")
          //console.log("License Revoked Successfully", data);
          setLoader(false)
        }else if(data.message ==="Cannote Revoke  License"){
          setLoader(false)
          setAlert(true)
        }else if(data.status !== 200) {
          setLoader(false)
          setAlert(true)
        }
      }
      catch (error: any) {
        //console.log("Error in revoking the License");
        setLoader(false)  

      }
      // Here you would typically make an API call to submit the data
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <div className="card px-4 py-4 mt-3 mb-3 border-0 rounded-3">
            {/* bread crumb starts here */}
            <ul className="breadcrumb-item mb-4 ps-0">
              <li>
                <a href="/Admin/ManageClients" className="pe-0" >
                  Manage Clients
                </a>
              </li>
              <li>
                <a  className="pe-0"   onClick={() => {history.push('/Admin/ManageClients/AdminClient',{state:{id:location?.state?.id}})}}>
                  {companyName}
                </a>
              </li>
              <li className="pe-2">{action === 'assign' ? 'Assign' : 'Revoke'} License</li>
            </ul>
            {/* bread crumb ends here */}
            <div className="d-flex align-items-center justify-content-between mb-4 flex-wrap gap-3 ">
              <h5 className="primary-header font-20 font-bold mb-0 d-flex align-items-center">
               
                  <img
                    src="/images/head-back-arrow.svg"
                    alt="back-arrow"
                    width={19}
                    className="me-2"
                    onClick={() => {history.push('/Admin/ManageClients/AdminClient',{state:{id:location?.state?.id}})}}
                  />
                
                {action === 'assign' ? 'Assign' : 'Revoke'} License
              </h5>
            </div>
            {action === 'assign' ? (
              <>
                {/*forms starts here*/}
                <div className="row">
                  <div className="col-md-12 mb-2  px-4">
                    <h5 className="font-bold font-20 my-2">Plan Information</h5>
                  </div>
                  
                  {/* <div className="col-md-6 col-lg-4 col-sm-12  mb-3 px-4" style={{visibility: productType ? 'visible' : 'hidden'}}>
                <label
                  className="form-label font-14 font-semibold label-color label-color"
                  htmlFor="applications"
                >
                  Applications
                </label>
                <div 
                  className="form-select custom-form m-0 font-14 font-regular"
                  style={{
                    height: 'auto',
                    minHeight: '38px',
                    padding: '8px',
                    overflow: 'auto'
                  }}
                >
                  {productType && applications[productType as keyof typeof applications]?.map((app, index) => (
                    <div 
                      key={index}
                      style={{
                        padding: '4px 8px',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                      onClick={() => handleApplicationChange(app)}
                    >
                      <input
                        type="checkbox"
                        checked={selectedApplications.includes(app)}
                        onChange={() => {}}
                        style={{ marginRight: '8px' }}
                      />
                      {app}
                    </div>
                  ))}
                   {errorValidation.selectedApplications && <p className="text-danger">{errorValidation.selectedApplications}</p>}

                </div>
                

              </div> */}
                   <div className="row mb-3">
                    <div className="col-md-6 col-lg-4 col-sm-12 my-3 px-4">
                      <label className="form-label font-14 font-semibold label-color label-color" htmlFor="PlanName"                 >
                        Product Type
                      </label>
                      <select
                        name="groupType"
                        id="groupType"
                        className="form-select custom-form m-0 font-14 font-regular"
                        value={productType}
                        onChange={handleProductTypeChange}
                      >
                        <option value="">Select</option>
                        <option value="Tenant">Tenant</option>
                        <option value="GSuite">Google Workspace</option>
                        <option value="Slack">Slack</option>
                      </select>
                    </div>
                    <div className="col-md-6 col-lg-4 col-sm-12 my-3 px-4" style={{ visibility: productType ? 'visible' : 'hidden' }}>
                    <label className="form-label font-14 font-semibold label-color label-color" htmlFor="PlanName"                 >
                        Application Type <span className="mandatory-color">*</span>
                      </label>
                      <div className="dropdown dd-flex-1">
                        <button
                          className="form-select custom-form m-0 font-14 font-regular text-start dpd-field d-flex justify-content-between align-items-center"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span className="font-13 font-regular color-gray-v4">
                            {selectedApplications.length > 0 ? selectedApplications.join(', ') : 'Applications'}
                          </span>
                          <img
                            src="/images/down-chevron-arw.svg"
                            alt="dropdown"
                          />
                        </button>
                        <ul className="dropdown-menu w-100 pb-0 rounded-start ">
                          {productType && applications[productType as keyof typeof applications]?.map((app, index) => (
                            <li
                              key={index}
                              className="dropdown-item py-0 cursor-pointer py-2"
                              onClick={() => handleApplicationChange(app)}
                            >
                              <div className="d-flex align-items-center">
                                <input
                                  className={`form-check-input me-2 mt-0 cus-check-input ${selectedApplications.includes(app) ? 'checked' : ''}`}
                                  type="checkbox"
                                  name={`option${index}`}
                                  id={`application${index + 1}`}
                                  checked={selectedApplications.includes(app)}
                                  onChange={(e) => {
                                    e.stopPropagation(); // Prevent the li onClick from firing
                                    handleApplicationChange(app);
                                  }}
                                />
                                <label
                                  className={`m-0 font-14 font-regular ${selectedApplications.includes(app) ? 'text-bold' : ''}`}
                                  htmlFor={`application${index + 1}`}
                                >
                                  {app}
                                </label>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                      {errorValidation.selectedApplications && <p className="text-danger">{errorValidation.selectedApplications}</p> }
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-lg-4 col-sm-12  px-4 mb-sm mt-sm my-md-2">
                    <label
                      className="form-label font-14 font-semibold label-color label-color"
                      htmlFor="resourceCount"
                    >
                      Resource Utilization (Days)
                    </label>
                    <div className="row">
                      <div className="col-md-6 mb-sm mb-sm-3">
                        <input
                          type="text"
                          className="form-control custom-form font-14 font-regular py-2"
                          id="resourceCount"
                          name="resourceCount"
                          placeholder="Enter Count"
                          value={inputFields.resourceCount}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-md-6">
                        <div className="input-group">
                          <span className="input-group-text font-semibold">$</span>
                          <input
                            type="text"
                            className="form-control custom-form font-14 font-regular"
                            id="resourceCost"
                            name="resourceCost"
                            placeholder="Enter Cost"
                            value={inputFields.resourceCost}
                            onChange={handleInputChange}
                          />


                        </div>
                      </div>
                    </div>
                    {errorValidation.resourceCost && <p className="text-danger">{errorValidation.resourceCost}</p> || errorValidation.resourceCount && <p className="text-danger">{errorValidation.resourceCount}</p>}

                  </div>
                  <div className="col-md-6 col-lg-4 col-sm-12 px-4 mb-sm mt-sm my-md-2">
                    <label
                      className="form-label font-14 font-semibold label-color label-color"
                      htmlFor="parallelInstanceCount"
                    >
                      Parallel Instance Count
                    </label>
                    <div className="row">
                      <div className="col-md-6 mb-sm mb-sm-3">
                        <input
                          type="text"
                          className="form-control custom-form font-14 font-regular py-2"
                          id="parallelInstanceCount"
                          name="parallelInstanceCount"
                          placeholder="Enter Count"
                          value={inputFields.parallelInstanceCount}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-md-6">
                        <div className="input-group">
                          <span className="input-group-text font-semibold">$</span>
                          <input
                            type="text"
                            className="form-control custom-form font-14 font-regular"
                            id="parallelInstanceCost"
                            name="parallelInstanceCost"
                            placeholder="Enter Cost"
                            value={inputFields.parallelInstanceCost}
                            onChange={handleInputChange}
                          />

                        </div>
                      </div>
                    </div>
                    {errorValidation.parallelInstanceCost && <p className="text-danger">{errorValidation.parallelInstanceCost}</p> || errorValidation.parallelInstanceCount && <p className="text-danger">{errorValidation.parallelInstanceCount}</p>}

                  </div>
                  <div className="col-md-6 col-lg-4 col-sm-12 px-4 mb-sm mt-sm my-md-2">
                    <label
                      className="form-label font-14 font-semibold label-color label-color"
                      htmlFor="assessmentCount"
                    >
                      Assessment Count
                    </label>
                    <div className="row">
                      <div className="col-md-6 mb-sm mb-sm-3">
                        <input
                          type="text"
                          className="form-control custom-form font-14 font-regular py-2"
                          id="assessmentCount"
                          name="assessmentCount"
                          placeholder="Enter Count"
                          value={inputFields.assessmentCount}
                          onChange={handleInputChange}
                        />

                      </div>

                      <div className="col-md-6">
                        <div className="input-group">
                          <span className="input-group-text font-semibold">$</span>
                          <input
                            type="text"
                            className="form-control custom-form font-14 font-regular"
                            id="assessmentCost"
                            name="assessmentCost"
                            placeholder="Enter Cost"
                            value={inputFields.assessmentCost}
                            onChange={handleInputChange}
                          />

                        </div>
                      </div>
                    </div>
                    {errorValidation.assessmentCost && <p className="text-danger">{errorValidation.assessmentCost}</p> || errorValidation.assessmentCount && <p className="text-danger">{errorValidation.assessmentCount}</p>}

                  </div>
                </div>
                <div className="row" style={{ visibility: productType ? 'visible' : 'hidden' }}>
                  <h5 className="font-bold font-20 px-4 my-3">
                    Migration Information
                  </h5>
                  <div className="col-md-6 col-lg-4 col-sm-12 px-4 mb-sm mt-sm my-md-2">
                    <label
                      className="form-label font-14 font-semibold label-color label-color"
                      htmlFor="licenseCount"
                    >
                      License
                    </label>
                    <div className="row">
                      <div className="col-md-6 mb-sm mb-sm-3">
                        <input
                          type="text"
                          className="form-control custom-form font-14 font-regular py-2"
                          id="licenseCount"
                          name="licenseCount"
                          placeholder="Enter Count"
                          value={inputFields.licenseCount}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-md-6">
                        <div className="input-group">
                          <span className="input-group-text font-semibold">$</span>
                          <input
                            type="text"
                            className="form-control custom-form font-14 font-regular"
                            id="licenseCost"
                            name="licenseCost"
                            placeholder="Enter Cost"
                            value={inputFields.licenseCost}
                            onChange={handleInputChange}
                          />

                        </div>
                      </div>
                    </div>
                    {errorValidation.licenseCost && <p className="text-danger">{errorValidation.licenseCost}</p> || errorValidation.licenseCount && <p className="text-danger">{errorValidation.licenseCount}</p>}

                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 px-4">
                    <div className="d-flex justify-content-end align-items-center mt-5 pt-4">
                      <button className="btn secondary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center py-2 me-3 px-4" onClick={() => history.push('/Admin/ManageClients')}>
                        Cancel
                      </button>
                      <button
                        className="btn primary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center px-4"
                        onClick={handleAssignLicenseSubmit}
                        disabled={(!productType || selectedApplications.length===0 ||  (inputFields.resourceCount === "" && 
                          inputFields.licenseCount === "" && 
                          inputFields.parallelInstanceCount === "" && 
                          inputFields.assessmentCount === ""))}
                      >
                        Submit
                      </button>
                      {loader && (
                        <div className="overlay">
                          <div className="position-absolute top-50 start-50 translate-middle">
                            <div
                              className="spinner-border Loader loader-color align-center "
                              role="status"
                            >
                              <span>
                                <span className="loader-inner-circle" />
                              </span>
                            </div>
                            <p className="loading-text font-16 font-semibold color-white-v2 mt-2">
                              Loading..
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {successPopup && (
                    <div className="modal fade show " id="Success-popup"
                      aria-labelledby="Success-popupLabel" aria-modal="true" role="dialog" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.8)" }}>
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content popup-brd-radius">
                          <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                            <span className="position-absolute">
                              <img src="/images/success-popup-icon.svg" alt="success-popup-icon" />
                            </span>
                          </div>
                          <div className="modal-body border-0 text-center">
                            <h5 className="modal-title mb-3 primary-header" id="Success-popupLabel">
                              {successPopupType === "Assign" ? "License Assigned Successfully":successPopupType === "Revoke" ? "License Revoke Successfully":""}
                            </h5>

                          </div>
                          <div className="modal-footer border-0 justify-content-center pb-4">
                            <button
                              type="button"
                              className="btn primary-btn font-14 px-4 font-semibold"
                              data-bs-dismiss="modal"
                              onClick={() => { history.push('/Admin/ManageClients/AdminClient',{state:{id:clientId}})
                                
                      
                              }}                            >
                              Ok
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {/*forms ends here*/}


              </>) : (<>{/*forms starts here*/}
                <div className="row">
                  <div className="col-md-12 mt-3  px-4">
                    <h5 className="font-bold font-20">Plan Information</h5>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6 col-lg-4 col-sm-12 my-3 px-4">
                      <label className="form-label font-14 font-semibold label-color label-color" htmlFor="PlanName"                 >
                        Product Type 
                      </label>
                      <select
                        name="groupType"
                        id="groupType"
                        className="form-select custom-form m-0 font-14 font-regular"
                        value={productType}
                        onChange={handleProductTypeChange}
                      >
                        <option value="">Select</option>
                        <option value="Tenant">Tenant</option>
                        <option value="GSuite">Google Workspace</option>
                        <option value="Slack">Slack</option>
                      </select>
                    </div>
                    <div className="col-md-6 col-lg-4 col-sm-12 my-3 px-4" style={{ visibility: productType ? 'visible' : 'hidden' }}>
                    <label className="form-label font-14 font-semibold label-color label-color" htmlFor="PlanName"                 >
                        Application Type <span className="mandatory-color">*</span>
                      </label>
                      <div className="dropdown dd-flex-1">
                        <button
                          className="form-select custom-form m-0 font-14 font-regular text-start dpd-field d-flex justify-content-between align-items-center"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span className="font-13 font-regular color-gray-v4">
                            {selectedApplications.length > 0 ? selectedApplications.join(', ') : 'Applications'}
                          </span>
                          <img
                            src="/images/down-chevron-arw.svg"
                            alt="dropdown"
                          />
                        </button>
                        <ul className="dropdown-menu w-100 pb-0 rounded-start ">
                          {productType && applications[productType as keyof typeof applications]?.map((app, index) => (
                            <li
                              key={index}
                              className="dropdown-item py-0 cursor-pointer py-2"
                              onClick={() => handleApplicationChange(app)}
                            >
                              <div className="d-flex align-items-center">
                                <input
                                  className={`form-check-input me-2 mt-0 cus-check-input ${selectedApplications.includes(app) ? 'checked' : ''}`}
                                  type="checkbox"
                                  name={`option${index}`}
                                  id={`application${index + 1}`}
                                  checked={selectedApplications.includes(app)}
                                  onChange={(e) => {
                                    e.stopPropagation(); // Prevent the li onClick from firing
                                    handleApplicationChange(app);
                                  }}
                                />
                                <label
                                  className={`m-0 font-14 font-regular ${selectedApplications.includes(app) ? 'text-bold' : ''}`}
                                  htmlFor={`application${index + 1}`}
                                >
                                  {app}
                                </label>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                      {errorValidation.selectedApplications && <p className="text-danger">{errorValidation.selectedApplications}</p> }
                    </div>
                  </div>
                  {/* <div className="col-md-6 col-lg-4 col-sm-12  mb-3 px-4" style={{visibility: productType ? 'visible' : 'hidden'}}>
                <label
                  className="form-label font-14 font-semibold label-color label-color"
                  htmlFor="applications"
                >
                  Applications
                </label>
                <div 
                  className="form-select custom-form m-0 font-14 font-regular"
                  style={{
                    height: 'auto',
                    minHeight: '38px',
                    padding: '8px',
                    overflow: 'auto'
                  }}
                >
                  {productType && applications[productType as keyof typeof applications]?.map((app, index) => (
                    <div 
                      key={index}
                      style={{
                        padding: '4px 8px',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                      onClick={() => handleApplicationChange(app)}
                    >
                      <input
                        type="checkbox"
                        checked={selectedApplications.includes(app)}
                        onChange={() => {}}
                        style={{ marginRight: '8px' }}
                      />
                      {app}
                    </div>
                  ))}
                 {errorValidation.selectedApplications && <p className="text-danger">{errorValidation.selectedApplications}</p>}

                </div>

              </div> */}

                  {/* <div className="col-md-6 col-lg-4 col-sm-12  mb-3 px-4" style={{visibility: productType ? 'visible' : 'hidden'}}>
  <label
    className="form-label font-14 font-semibold label-color"
    htmlFor="applications"
  >
    Applications
  </label>
  <div 
    className="form-select custom-form m-0 font-14 font-regular"
    style={{
      height: 'auto',
      minHeight: '38px',
      padding: '8px',
      overflow: 'auto'
    }}
  >
    {productType && applications[productType as keyof typeof applications]?.map((app, index) => (
      <div 
        key={index}
        style={{
          padding: '4px 8px',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center'
        }}
        onClick={() => handleApplicationChange(app)}
      >
        <input
          type="checkbox"
          checked={selectedApplications.includes(app)}
          onChange={() => {}}
          style={{ marginRight: '8px' }}
        />
        {app}
      </div>
    ))}
    {errorValidation.selectedApplications && <p className="text-danger">{errorValidation.selectedApplications}</p>}
  </div>
</div>  */}


                </div>



                <div className="row mb-3">
                  <div className="col-md-6 col-lg-4 col-sm-12 my-3 px-4">
                    <label
                      className="form-label font-14 font-semibold label-color label-color"
                      htmlFor="ParallelCount"
                    >
                      Resource Utilization (Days)
                    </label>
                    <input

                      type="text"
                      className="form-control custom-form font-14 font-regular py-2"
                      id="resourceCount"
                      name="resourceCount"
                      placeholder="Enter the value"
                      value={inputFields.resourceCount}
                      onChange={handleInputChange}
                    />
                    {errorValidation.resourceCount && <p className="text-danger">{errorValidation.resourceCount}</p>}
                  </div>


                  <div className="col-md-6 col-lg-4 col-sm-12 my-3 px-4">
                    <label
                      className="form-label font-14 font-semibold label-color label-color"
                      htmlFor="AssessmentCount"
                    >
                      Parallel Instance Count
                    </label>
                    <input
                      type="text"
                      className="form-control custom-form font-14 font-regular py-2"
                      id="PlanName"
                      name="parallelInstanceCount"
                      placeholder="Enter the value"
                      value={inputFields.parallelInstanceCount}
                      onChange={handleInputChange}
                    />
                    {errorValidation.parallelInstanceCount && <p className="text-danger">{errorValidation.parallelInstanceCount}</p>}
                  </div>




                  <div className="col-md-6 col-lg-4 col-sm-12 my-3 px-4">
                    <label
                      className="form-label font-14 font-semibold label-color label-color"
                      htmlFor="AssessmentCount"
                    >
                      Assessment Count
                    </label>
                    <input
                      type="text"
                      className="form-control custom-form font-14 font-regular py-2"
                      id="PlanName"
                      name="assessmentCount"
                      placeholder="Enter the value"
                      value={inputFields.assessmentCount}
                      onChange={handleInputChange}
                    />
                    {errorValidation.assessmentCount && <p className="text-danger">{errorValidation.assessmentCount}</p>}
                  </div>


                </div>
                <div className="row" style={{ visibility: productType ? 'visible' : 'hidden' }}>
                  <h5 className="font-bold font-20 px-4 mb-3">Migration Information</h5>
                  <div className="col-md-6 col-lg-4 col-sm-12 my-3 px-4">
                    <label
                      className="form-label font-14 font-semibold label-color label-color"
                      htmlFor="AssessmentCount"
                    >
                      License
                    </label>
                    <input
                      type="text"
                      className="form-control custom-form font-14 font-regular py-2"
                      id="PlanName"
                      name="licenseCount"
                      placeholder="Enter the value"
                      value={inputFields.licenseCount}
                      onChange={handleInputChange}
                    />
                    {errorValidation.licenseCount && <p className="text-danger">{errorValidation.licenseCount}</p>}

                  </div>

                  <div className="col-md-12 mb-4 mt-3 mb-3 px-4">
                    <label className="form-label font-14 font-bold label-color label-color">
                      Reason<span className="mandatory-color">*</span>
                    </label>
                    <textarea
                      placeholder="Enter Reason"
                      className="d-block rounded custom-form resize-none form-control p-3 h-100"
                      defaultValue={""}
                      name="reason"
                      value={inputFields.reason}
                      onChange={handleOnChangeTextArea}
                    />
                    {errorValidation.reason && <p className="text-danger">{errorValidation.reason}</p>}

                  </div>

                </div>
                <div className="row">
                  <div className="col-md-12 px-4">
                    <div className="d-flex justify-content-end align-items-center mt-5 pt-4">
                      <button className="btn secondary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center py-2 me-3 px-4" onClick={() => history.push('/Admin/ManageClients')}>
                        Cancel
                      </button>
                      <button
                        className="btn primary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center px-4"
                        onClick={handleRevokeLicenseSubmit}
                        disabled={!productType || selectedApplications.length === 0 || inputFields.reason === ""}
                      >
                        Submit
                      </button>
                      {loader && (
                        <div className="overlay">
                          <div className="position-absolute top-50 start-50 translate-middle">
                            <div
                              className="spinner-border Loader loader-color align-center "
                              role="status"
                            >
                              <span>
                                <span className="loader-inner-circle" />
                              </span>
                            </div>
                            <p className="loading-text font-16 font-semibold color-white-v2 mt-2">
                              Loading..
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {successPopup && (
                    <div className="modal fade show " id="Success-popup"
                      aria-labelledby="Success-popupLabel" aria-modal="true" role="dialog" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.8)" }}>
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content popup-brd-radius">
                          <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                            <span className="position-absolute">
                              <img src="/images/success-popup-icon.svg" alt="success-popup-icon" />
                            </span>
                          </div>
                          <div className="modal-body border-0 text-center">
                            <h5 className="modal-title mb-3 primary-header" id="Success-popupLabel">
                            License Revoked Successfully
                            </h5>

                          </div>
                          <div className="modal-footer border-0 justify-content-center pb-4">
                            <button
                              type="button"
                              className="btn primary-btn font-14 px-4 font-semibold"
                              data-bs-dismiss="modal"
                              onClick={() => {
                                history.push('/Admin/ManageClients/AdminClient',{state:{id:clientId}})
                              }}                            >
                              Ok
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
         
        </div>
      </div>
    </div>
  );
};



export default AssignOrRevokeLicense;

