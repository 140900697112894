import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import moment from 'moment';
import { sessionResponse } from "../../Common/Interface";
import { decodeSessionDetails } from "../../Services/CommonServices";
import objCryptoJS from "crypto-js";
import jwt from 'jwt-decode'
import { getSession } from "../../Services/manageClientAPI";

import {
    getMappedEntities,
    updateAction,
    insertScheduleMigration,
    startMigration,
    getSourceEntitiesService,
    getSharePointListsService,
    updateSelectedLists
} from "../../Services/MigrationAndManageMigrationServices";
import { deleteMappingService, getSitesApi, getTeamsandChannelsApi } from "../../Services/CreationandMapping";
import { commonErrorMsgs } from "../../Common/Constant";
import Tippy from "@tippyjs/react";

interface SelectMigrationProps {
    taskId: any;
    clientId: any;
    applicationName: any;
    isOpen: boolean;
    onToggle: () => void;
    onSelectMigrationSave: () => void;
    reloadMigration: boolean;
    isDirectMigration: boolean
    isActualUserMigration: boolean
    fetchDataInManageMigration: () => void;
}
// PS_61, PS_62, PS_63, PS_64, PS_65, PS_66, PS_67, PS_68, PS_69, PS_70, PS_71, PS_72, PS_73,
const SelectMigration: React.FC<SelectMigrationProps> = ({ taskId, clientId, isOpen, onToggle, applicationName, isDirectMigration, isActualUserMigration, onSelectMigrationSave, reloadMigration, fetchDataInManageMigration }) => {
    //PS_26

    const location: any = useLocation().state;
    const [loading, setLoading] = useState<boolean>(false);

    const [data, setData] = useState<any>([]);
    const [totalDataCount, settotalDataCount] = useState(0);
    const [totalListCount, setTotalListCount] = useState(0);

    let history = useHistory();
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [sharepointSelectionPopup, setSharepointSelectionPopup] = useState<boolean>(true)
    const [siteId, setSiteId] = useState<string>('')
    const [sortBy, setSortBy] = useState<string>("su.sourceDisplayName");
    const [sortOrder, setSortOrder] = useState<string>("DESC");
    const [sortHide, setSortHide] = useState<string>("");
    const [sharePointLists, setSharePointLists] = useState<any>([])
    const [selectedRows, setSelectedRows] = useState<any>([]);
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const [sharePointSort, setSharePointSort] = useState<string>("listNamedown")
    const [selectAllList, setSelectAllList] = useState<boolean>(false);
    const [renderstate, setrenderstate] = useState<boolean>(false)

    const [alertpopup, setAlertpopup] = useState<boolean>(false);
    const [isAlterOn, setIsAlterOn] = useState<boolean>(true)
    const [failAlert, setFailAlertpopup] = useState<boolean>(false);
    const [alertMessage, setAlertMessagepopup] = useState<string>("");


    const [scheduleMigrationDateTime, setScheduleMigrationDateTime] = useState<boolean>(false)
    const [scheduleMigrationDateTimeWarning, setScheduleMigrationDateTimeWarning] = useState<string>('')

    const [dateInput, setDateInput] = useState<string>('')
    const [timeInput, setTimeInput] = useState<string>('')
    const [minTime, setMinTime] = useState<string>('');
    const [ctoken, setCToken] = useState<string>(sessionStorage.getItem('clientToken') || '');
    const [tokenExpiration, setTokenExpiration] = useState<number>(parseInt(sessionStorage.getItem('tokenExpiration') || '0'));
    const [atoken, setAToken] = useState<string>(sessionStorage.getItem('a_at') || '');
    const [isAdmin, setIsAdmin] = useState<boolean>(sessionStorage.getItem('isAdmin') === 'true');
    const [objAdmin, setObjAdmin] = useState({})
    const [objClient, setObjClient] = useState({})
    let [adminName, setAdminName] = useState('');
    let [adminEemailAddress, setAdminEmailAddress] = useState('');
    let [adminrole, setAdminRole] = useState('');
    let sessionDetails = { UserName: "", CompanyName: "", EmailAddress: "", ID: 0, Role: "" }
    let [clientName, setclientName] = useState('');
    let [companyName, setCompanyName] = useState('');
    let [emailAddress, setEmailAddress] = useState('');
    let [role, setRole] = useState('');
    const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';
    const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';
    const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';
    const [alert, setAlert] = useState<string>('');
    let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
    const [isSessionEpired, setisSessionEpired] = useState(false);
    let [isEntityMapped, setIsEntityMapped] = useState<boolean>();
    // //debugger
    let InActiveTimeout = useRef<NodeJS.Timeout | null>(null);
    // console.log(location, 'migacc loc');

    const [disableActions, setDisableActions] = useState(false);

    const [filterValueForGroups, setFilterValueForGroups] = useState<string>('');
    const [siteDisplayName, setSiteDisplayName] = useState<string>('');
    const [searchEnabled, setSearchEnabled] = useState<boolean>(false);
    // PS_27

    //console.log(isDirectMigration, "isDirectMigrationisDirectMigrationisDirectMigrationisDirectMigrationisDirectMigration");

    const obj: any = {
        outlook: [
            {
                header: "Source Entity Name",
                value: "sourceDisplayName",
                sort: "su.sourceDisplayName"
            },
            {
                header: "Source Email Address",
                value: "sourceMailId",
                sort: "su.sourceMailId"
            },
            {
                header: "Source Type",
                value: "sourceResourceType",
                sort: "su.sourceResourceType"
            },
            {
                header: "Target Entity Name",
                value: "targetDisplayName",
                sort: "tu.targetDisplayName"
            },
            {
                header: "Target Email",
                value: "targetMailId",
                sort: "tu.targetMailId"
            },
            {
                header: "Target Type",
                value: "targetResourceType",
                sort: "tu.targetResourceType"
            }
        ],
        sharepoint: (isDirectMigration ? [
            {
                header: "Source Site Name",
                value: "sourceDisplayName",
                sort: "ssd.SourceSPSiteName"
            },
            {
                header: "Source Site URL",
                value: "sourceSiteURL",
                sort: "ssd.SourceSPSiteURL"
            }
        ] : [
            {
                header: "Source Site Name",
                value: "sourceDisplayName",
                sort: "su.sourceDisplayName"
            },
            {
                header: "Source Site URL",
                value: "sourceSiteURL",
                sort: "su.sourceMailId"
            },
            {
                header: "Target Site Name",
                value: "targetDisplayName",
                sort: "tu.targetDisplayName"
            },
            {
                header: "Target Site URL",
                value: "targetSiteURL",
                sort: "tu.targetMailId"
            },

            {
                //action column just been added
                header: "Action",
                value: "targetMailId",
                sort: "tu.targetMailId"
            },
        ]),
        teamschannel: (isDirectMigration ? [
            {
                header: "Source Team Name",
                value: "sourceDisplayName",
                sort: "stcd.SourceTeamName"
            },
            {
                header: "Source Channel Name",
                value: "sourceChannelName",
                sort: "stcd.SourceChannelName"
            }
        ] : [
            {
                header: "Source Team Name",
                value: "sourceDisplayName",
                sort: "su.sourceDisplayName"
            },
            {
                header: "Source Channel Name",
                value: "sourceChannelName",
                sort: "su.sourceMailId"
            },
            {
                header: "Target Team Name",
                value: "targetDisplayName",
                sort: "tu.targetDisplayName"
            },
            {
                header: "Target Channel Name",
                value: "targetChannelName",
                sort: "tu.targetMailId"
            },
            {
                header: "Action",
                value: "",
                sort: ""
            }
        ]),
        gmail: [
            {
                header: "Source User Name",
                value: "sourceDisplayName",
                sort: "su.sourceDisplayName"
            },
            {
                header: "Source Email Address",
                value: "sourceMailId",
                sort: "su.sourceMailId"
            },
            {
                header: "Target User Name",
                value: "targetDisplayName",
                sort: "tu.targetDisplayName"
            },
            {
                header: "Target Email",
                value: "targetMailId",
                sort: "tu.targetMailId"
            }
        ],
        mydrive: [
            {
                header: "Source User Name",
                value: "sourceDisplayName",
                sort: "su.sourceDisplayName"
            },
            {
                header: "Source Email Address",
                value: "sourceMailId",
                sort: "su.sourceMailId"
            },
            {
                header: "Target User Name",
                value: "targetDisplayName",
                sort: "tu.targetDisplayName"
            },
            {
                header: "Target Email",
                value: "targetMailId",
                sort: "tu.targetMailId"
            }
        ]
    }
    const listObj = [
        {
            header: "Name",
            value: "sourceDisplayName",
            sort: "listName"
        },
        {
            header: "Type",
            value: "sourceDisplayName",
            sort: "listType"
        }

    ]

    // PS_74, PS_75, PS_76, PS_77, PS_78, PS_79, PS_80,
    useEffect(() => {
        sessionTokenProperties();
        // resetTimer();

        // window.addEventListener('keydown', resetTimer);
        // window.addEventListener('click', resetTimer);

        // handling the OnLoad Grid Page
        debugger
        if (isDirectMigration) {
            getSourceEntities();
            data.length > 0 ? setIsEntityMapped(true) : setIsEntityMapped(false);
        }
        else {
            fetchData();
            data.length > 0 ? setIsEntityMapped(true) : setIsEntityMapped(false);
        }

        // return () => {
        //     window.removeEventListener('click', resetTimer);
        //     window.removeEventListener('keydown', resetTimer);
        // };


    }, [sortBy, sortHide, sortOrder, reloadMigration, renderstate]);


    // let resetTimer = async () => {
    //     try {
    //         // debugger;
    //         ////console.log("Reset Timer Start");

    //         let sessionResponse: string = await resetSessionTimer(InActiveTimeout, SessionEpiredDateTime);
    //         ////console.log("sessionResponse value :", sessionResponse);

    //         // //debugger
    //         if (sessionResponse == 'expired') {
    //             debugger
    //             sessionStorage.removeItem("c_at");
    //             setisSessionEpired(true)
    //             setAlert('Your Session has Expired please login again to continue');
    //         }
    //         else if (sessionResponse == 'updateToken') {
    //             if (emailAddress == "" || emailAddress == undefined) {
    //                 await sessionTokenProperties();
    //             }
    //             debugger
    //             let token = await getToken()
    //             let payload = {
    //                 mailId: emailAddress,
    //                 isAdmin: false,
    //                 token: token
    //             };
    //             let getsessionToken = await getSession(payload);
    //             const rn_secretKey: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY
    //             const cs_secretKey: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;

    //             const c_byteValue = objCryptoJS.AES.decrypt(getsessionToken?.data?.data.sessionToken, rn_secretKey);
    //             const c_decryptedValue = c_byteValue.toString(objCryptoJS.enc.Utf8);
    //             let updatedToken = objCryptoJS.AES.encrypt(c_decryptedValue, cs_secretKey).toString();
    //             await sessionStorage.removeItem("c_at");
    //             await sessionStorage.setItem('c_at', updatedToken);
    //             let updatedobjClient: any = jwt(c_decryptedValue);
    //             SessionEpiredDateTime = updatedobjClient.CreatedAt;
    //             setSessionEpiredDateTime(SessionEpiredDateTime);
    //         }
    //         ////console.log("Reset Timer End");
    //     }
    //     catch (error) {
    //         ////console.log("Reset Timer Catch");
    //         setAlert('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
    //         setLoading(false);
    //     }
    // }

    // const resetSessionTimer = async (InActiveTimeout: any, SessionExpirationDateTime: any) => {
    //     try {
    //         // debugger
    //         ////console.log("Reset Session Timer Start");
    //         // Assuming InActiveTimeout is a number representing the ID of the current timeout

    //         if (typeof window !== 'undefined') {
    //             ////console.log("Reset Session Timer Inside");
    //             if (InActiveTimeout.current) {
    //               clearTimeout(InActiveTimeout.current);
    //             }
    //             const tokenExpiryCheckInterval = Number(process.env.REACT_APP_TABIDLESCENARIO);

    //             InActiveTimeout.current = setTimeout(() => {
    //                 ////console.log("Expired");
    //                 sessionStorage.removeItem("c_at");
    //                 ////console.log("removed session");
    //                 setisSessionEpired(true)
    //                 setAlert('Your Session has Expired please login again to continue');
    //                 // return 'expired'
    //             }, tokenExpiryCheckInterval);

    //             ////console.log("Reset Session Timer checking");

    //             const currentDateTime = new Date().toISOString();
    //             //const sessionExpiredDateTime = new Date(SessionExpirationDateTime).toISOString();
    //             ////console.log("sessionExpiredDateTime value :", sessionExpiredDateTime);

    //             const diffInMilliseconds = Math.abs(new Date(currentDateTime).getTime() - new Date(SessionEpiredDateTime).getTime());
    //             ////console.log("diffInMilliSeconds values:", diffInMilliseconds);

    //             const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
    //             ////console.log("minutes : ", minutes);

    //             const sessionUpdateTimeGap = Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP);
    //             ////console.log("Reset Session Timer Differece : ", sessionUpdateTimeGap);

    //             if (minutes > sessionUpdateTimeGap) {

    //                 ////console.log("Reset Session Timer need to update");
    //                 return 'updateToken'
    //             }

    //             ////console.log("Reset Session Timer End");
    //             return 'NoActionRequired'
    //         }
    //         return 'NoActionRequired'
    //     }
    //     catch (ex) {
    //         ////console.log("Reset Session Timer Catch");
    //         throw ex
    //     }
    // };


    let sessionTokenProperties = async () => {
        try {
            const tokenResponse: any = await decodeSessionDetails();
            debugger
            if (tokenResponse.adminDetails) {
                setObjAdmin(tokenResponse.adminDetails)
                setAdminName(tokenResponse.adminDetails.Name);
                setAdminEmailAddress(tokenResponse.adminDetails.Email);
                setAdminRole(tokenResponse.adminDetails.Role);

                if (tokenResponse?.adminDetails?.Role.toLowerCase().includes('viewer')) {
                    setDisableActions(true)
                }
            }

            if (tokenResponse.clientDetails) {
                setObjClient(tokenResponse.clientDetails)
                setclientName(tokenResponse.clientDetails.Name);
                setCompanyName(tokenResponse.clientDetails.CompanyName)
                emailAddress = tokenResponse.clientDetails.Email;
                setEmailAddress(emailAddress);
                setRole(tokenResponse.clientDetails.Role);

                SessionEpiredDateTime = tokenResponse.clientDetails.CreatedAt;
                setSessionEpiredDateTime(SessionEpiredDateTime);

                if (tokenResponse?.clientDetails?.Role.toLowerCase().includes('viewer')) {
                    setDisableActions(true)
                }
            }
            else {
                history.push("/Login")
            }

            //#endregion
        } catch (error) {
            setAlertpopup(true);
            setLoading(false)

            console.error('Failed in main method', error);
        }
    };


    const getToken = async () => {
        let clientToken: string;
        let adminToken: string;
        let token: { clientToken?: string; adminToken?: string } = {};
        debugger
        try {
            if (objClient != undefined) {
                let sessionToken: any = sessionStorage.getItem('c_at')
                if (sessionToken) {
                    // sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)
                    // var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
                    // clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
                    token['clientToken'] = sessionToken

                }


            }
            if (objAdmin !== undefined) {
                let sessionToken: any = sessionStorage.getItem('a_at')
                if (sessionToken) {
                    // sessionToken = objCryptoJS.AES.decrypt(sessionToken, a_secretKey)
                    // var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
                    // adminToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();

                    token['adminToken'] = sessionToken

                }
            }
            return token
        } catch (error) {
            setAlertpopup(true);
            setLoading(false)

            throw error
        }
    }


    useEffect(() => {
        setMinTime(dateInput === new Date().toISOString().split('T')[0] ? getCurrentTime() : '00:00');
    }, [dateInput]);

    // PS_29, PS_30, PS_31, PS_32, PS_33
    const fetchData = async () => {
        setLoading(true); // Set loading state to true
        try {
            let token = await getToken()
            let payload: any = {
                taskId: taskId,
                token: token,
                applicationName: `${applicationName}`,
                searchValue: searchTerm.trim(),
                sortOrder: sortOrder,
                sortBy: sortBy,
                Action: "migration",
            }
            const mappedEntities: any = await getMappedEntities(payload);
            console.log("the mappedEnities", mappedEntities);

            // PS_37, PS_38, PS_39, PS_40
            setSelectAll(false)

            const newData = mappedEntities?.data?.data?.map((item: any) => ({
                ...item,
                checked: false,
            }));

            console.log("the new data", newData);

            setData(newData);
            settotalDataCount(mappedEntities?.data?.data?.length)

        } catch (error: any) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false); // Set loading state to false when done fetching
        }
    };

    //PS_38
    const getHeaderText = (applicationName: any) => {
        switch (applicationName) {
            case 'outlook':
                return 'Entity';
            case 'groups':
                return 'Group';
            case 'roomsandequipments':
                return "Rooms & Equipment's";
            case 'onedrive':
                return 'OneDrive';
            case 'sharedmailbox':
                return 'Shared Mailbox';
            case 'sharepoint':
                return 'Mapped Sites';
            case 'teamschat':
                return 'Mapped Teams Chat';
            case 'teamschannel':
                return 'Mapped Teams Channel';
            case 'teamschannel':
                return 'Mapped Teams Channel';
            case 'gmail':
                return 'Gmail';
            case 'mydrive':
                return 'My Drive';

            default:
                return null;
        }
    };

    // PS_41
    // PS_42
    const handleSearch = (event: any) => {
        debugger;
        setSearchTerm(event.target.value);
        setSearchEnabled(true)
    };

    async function updateSelectedItems() {
        setLoading(true)
        try {
            debugger
            setSharepointSelectionPopup(true)
            let token = await getToken()
            let data = {
                token: token,
                taskId: taskId,
                selectedListIds: sharePointLists.filter((item: any) => item.checked).map((item: any) => (item.listId)),
                siteId: siteId
            }
            let result = await updateSelectedLists(data)
        } catch (error) {

        }
        finally {
            setLoading(false)

        }
    }

    // PS_81, PS_82, PS_83, // this search has been merged into handleKeyDown
    // const handleSearchClick = () => {
    //     debugger

    //     const trimmedSearchTerm = searchTerm.trim();
    //     if (searchTerm.trim().length === 0 || searchTerm.length > 0) {
    //         if (isDirectMigration) {
    //             getSourceEntities()
    //             data.length > 0 ? setIsEntityMapped(true) : setIsEntityMapped(false);
    //         }
    //         else {
    //             fetchData();
    //             data.length > 0 ? setIsEntityMapped(true) : setIsEntityMapped(false);
    //         }
    //     }
    //     else if (searchTerm.trim().length === 0 || searchTerm.length > 0) {
    //         if (isDirectMigration) {
    //             getSourceEntities()
    //             data.length > 0 ? setIsEntityMapped(true) : setIsEntityMapped(false);
    //         }
    //         else {
    //             fetchData();
    //             data.length > 0 ? setIsEntityMapped(true) : setIsEntityMapped(false);
    //         }

    //     }
    // };

    //PS_84, PS_85, PS_86, PS_87,
    const handleKeyDown = (event: any) => {
        if (event.key === "Enter" || event.target.id == 'search-icon') {
            //const trimmedSearchTerm = searchTerm.trim();
            // if (searchTerm.length === 0) {
            if (!searchTerm?.length || searchTerm?.trim()!='') {
                if (isDirectMigration) {
                    getSourceEntities()
                    data.length > 0 ? setIsEntityMapped(true) : setIsEntityMapped(false);
                }
                else {
                    fetchData();
                    data.length > 0 ? setIsEntityMapped(true) : setIsEntityMapped(false);
                }
            }
            // else if (searchTerm.length !== 0 && trimmedSearchTerm.length !== 0) {            
        }
    };

    //  PS_88, PS_89, PS_90, PS_91
    const handleSort = (e: any) => {
        try {
            debugger;
            let sortID = e.target.id;
            let sortval = sortID.split("@");
            setSortBy(sortval[0]);
            setSortOrder(sortval[1]);
            setSortHide(sortID);
            //setSearchValue("");
        } catch (e: any) {
            ////console.log(e.message);
        }
    };

    // PS_101, PS_102, PS_103, PS_104, PS_105, PS_106, PS_107, PS_108, PS_109, PS_110, PS_111,
    const generateTimeOptions = () => {
        let options = [];
        options.push(<option key="placeholder" value="" disabled selected>hh:mm</option>); // Placeholder option

        const now = new Date();
        const currentHour = now.getUTCHours();
        const currentMinute = now.getUTCMinutes();

        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 60) {
                let hh = hour.toString().padStart(2, '0');
                let mm = minute.toString().padStart(2, '0');
                let time = `${hh}:${mm}`;
                if (dateInput === new Date().toISOString().split('T')[0]) {
                    if (hour < currentHour || (hour === currentHour && minute < currentMinute)) {
                        options.push(<option key={time} value={time} disabled>{time}</option>);
                    } else {
                        options.push(<option key={time} value={time}>{time}</option>);
                    }
                } else {
                    options.push(<option key={time} value={time}>{time}</option>);
                }
            }
        }
        return options;
    };

    const timeOptions: any = generateTimeOptions();


    // PS_112, PS_113, PS_114
    const handleDateChange = (e: any) => {
        setDateInput(e.target.value);
        setMinTime(e.target.value === new Date().toISOString().split('T')[0] ? getCurrentTime() : '00:00');
    };
    // PS_115, PS_116, PS_117
    const getCurrentTime: any = () => {
        const now = new Date();
        const currentHour = now.getUTCHours().toString().padStart(2, '0');
        const currentMinute = now.getUTCMinutes().toString().padStart(2, '0');
        return `${currentHour}:${currentMinute}`;
    };


    // PS_118, PS_119, PS_120,
    const handleTimeChange = (e: any) => {
        setTimeInput(e.target.value);

    }
    // PS_121, PS_122
    const handleSelectAllChange = () => {
        const newData: any = data.map((item: any) => ({
            ...item,
            checked: !selectAll,
        }));
        ////console.log(data, "data in seal");
        setData(newData);
        setSelectAll(!selectAll);
    };

    // PS_85, PS_86, PS_87, PS_88, PS_89, PS_90, PS_91
    const handleStartMigration: any = async () => {
        // Extract the IDs of the selected rows
        setLoading(true);
        try {
            if (!disableActions) {
                let token = await getToken()
                debugger
                let mappingIds: any

                if (isDirectMigration) {
                    if (applicationName == 'sharepoint') {
                        mappingIds = data.filter((row: { checked: any; }) => row.checked)
                            .map((row: any) => (
                                {
                                    siteURL: (applicationName == 'sharepoint') ? row.SourceSPSiteURL : (applicationName == 'teamschannel') ? row.SourceTeamChannelDetailsID : row.userMigrationMappingId,
                                    siteName: (applicationName == 'sharepoint') ? row.SourceSPSiteName : (applicationName == 'teamschannel') ? row.SourceTeamChannelDetailsID : row.userMigrationMappingId,
                                    siteType: (applicationName == 'sharepoint') ? row.SourceSPSiteTemplate : (applicationName == 'teamschannel') ? row.SourceTeamChannelDetailsID : row.userMigrationMappingId,
                                }));
                        //console.log(mappingIds, "mappingIDS", isDirectMigration);
                    }
                    if (applicationName == 'teamschannel') {
                        mappingIds = data.filter((row: { checked: any; }) => row.checked)
                            .map((row: any) => (
                                {
                                    sourceTeamName: row.SourceTeamName,
                                    sourceTeamType: row.SourceTeamMode,
                                    sourceTeamId: row.SourceTeamID,
                                    sourceTeamMode: row.SourceTeamMode,
                                    sourceChannelName: row.SourceChannelName,
                                    sourceChannelId: row.SourceChannelID,
                                    sourceChannelType: row.SourceChannelType,
                                    sourceChannelMode: row.SourceChannelMode,
                                    IsSourcePrivateTeam: row.IsSourcePrivateTeam
                                }));
                        //console.log(mappingIds, "mappingIDS", isDirectMigration);
                    }


                }
                else {
                    mappingIds = data.filter((row: { checked: any; }) => row.checked)
                        .map((row: any) => ({ mappingId: (applicationName == 'sharepoint') ? row.SPSiteMigrationMappingID : (applicationName == 'teamschannel') ? row.TeamChannelMigrationMappingID : row.userMigrationMappingId }));
                }
                let payload: any = {
                    taskId: taskId,
                    token: token,
                    id: mappingIds,
                    Action: "migration",
                    applicationName: applicationName
                    // reqFrom: "Default"
                }
                if (applicationName.toLowerCase() == 'sharepoint' || applicationName.toLowerCase() == 'teamschannel') {
                    payload["isDirectMigration"] = isDirectMigration
                }
                let response: any = await startMigration(payload);


                if (response?.data?.data?.message === 'Upgrade your plan to get more licenses.') {
                    setAlertpopup(true);
                } else {
                    // Logic to start migration
                    setSelectAll(false)

                    fetchData()

                    // setManageMigration(true);
                    onSelectMigrationSave()

                    setLoading(false);

                    fetchDataInManageMigration()
                }
            }
        }
        catch (error) {
            ////console.log(error, "this is the error");
        }
        finally {
            setLoading(false);
        }

    };

    const openSchedulerPopup = (): void => {
        try {
            if (!disableActions) {
                setScheduleMigrationDateTime(true);
                setDateInput(dateInput == '' ? new Date().toISOString().split('T')[0] : dateInput)
            }
        } catch (error: any) {
            console.error('Error in openSchedulerPopup', error);
        }
    }

    //PS_126, PS_127, PS_128, PS_129, PS_130, PS_131, PS_132, PS_133, PS_134, PS_135, PS_136, PS_137, PS_138, PS_139, PS_140, PS_141, PS_142, PS_143, PS_144, PS_145, PS_146, PS_147, PS_148
    const handleScheduleMigration: any = async () => {
        try {
            let token = await getToken()
            debugger
            if (dateInput === "" || timeInput === "") {
                setScheduleMigrationDateTimeWarning('Provide the date and time')
                return
            }

            const dateTimeString = `${dateInput}T${timeInput}`;
            const dateTimeUTC = moment.utc(dateTimeString); // Parse as UTC

            const currentDateTimeUTC = moment.utc(); // Current UTC time

            // Check if selected date and time (in UTC) are greater than current date and time (in UTC)
            const isValid = dateTimeUTC.isAfter(currentDateTimeUTC);

            ////console.log(isValid, "valid");

            if (!isValid) {
                setScheduleMigrationDateTimeWarning('Please select a future date & time')
                return
            }

            setScheduleMigrationDateTimeWarning('')
            setScheduleMigrationDateTime(false)

            setDateInput('')
            setTimeInput('')

            setLoading(true);
            debugger;
            // switch (applicationName) {
            // case "outlook": {
            // Check if data is defined and not empty before filtering and mapping
            const selectedIds: any[] = data && data.length > 0 ?
                data.filter((row: any) => row.checked)
                    .map((row: any) => ({
                        mappingId: (applicationName == 'sharepoint') ? row.SPSiteMigrationMappingID : (applicationName == 'teamschannel') ? row.TeamChannelMigrationMappingID : row.userMigrationMappingId,
                        SourceTeamChannelDetailsID: (applicationName == 'sharepoint') ? row.SourceSPSiteDetailsID : (applicationName == 'teamschannel') ? row.SourceTeamChannelDetailsID : row.userMigrationMappingId

                    }))
                : [];

            let result: any = await insertScheduleMigration({
                taskId: taskId,
                token: token,
                Id: selectedIds,
                Action: "schedule",
                applicationName: applicationName,
                isDirectMigration: isDirectMigration,
                scheduleMigrationDateTime: dateTimeUTC,
                migrationType: location?.state?.MigrationMode?.toLowerCase()
            });

            if (result.data.statusMessage === 'Insuffecient License') {
                setLoading(false);
                setAlertpopup(true);
            } else {
                // Logic to start migration
                setSelectAll(false);
                fetchData();
                onSelectMigrationSave();
                setLoading(false);
                fetchDataInManageMigration();
            }

        }
        catch (error) {
            ////console.log(error);
        }

    };

    //PS_52
    const handleCheckboxChange = (index: any) => {
        debugger
        const newData: any = [...data];
        newData[index].checked = !newData[index].checked;
        ////console.log(data);
        setData(newData);
        let isSelectedAll = newData.every((obj: any) => obj['checked'] === true);
        ////console.log(isSelectedAll, "is that all we got")
        setSelectAll(isSelectedAll)
    };

    //PS_72, PS_73, PS_74
    const handleScheduleCancel = () => {
        setDateInput('');
        setTimeInput('');
        setScheduleMigrationDateTimeWarning('')
        setScheduleMigrationDateTime(false);
    };

    //PS_75, PS_76, PS_77

    const handleScheduleClose = () => {
        setDateInput('');
        setTimeInput('');
        setScheduleMigrationDateTime(false);
        setScheduleMigrationDateTimeWarning('')
    };

    const isTimeInputLessThanCurrentTime = () => {
        if (dateInput === new Date().toISOString().split('T')[0]) {
            const currentTime = getCurrentTime();
            return timeInput.localeCompare(currentTime) < 0; // Compare time strings
        }
        return false; // Allow save button if date is not current date
    };

    // const checkScheduledDate = () => {
    //     if (dateInput === new Date().toISOString().split('T')[0]) {
    //         const currentTime = getCurrentTime();
    //         return timeInput.localeCompare(currentTime) < 0; // Compare time strings
    //     }
    //     return false; // Allow save button if date is not current date
    // };

    async function handleSelectAllLists(e: any) {
        try {
            setSelectAllList(true)
            setSharePointLists((prevItems: any) => {
                let items = prevItems.map((item: any) => { return { ...item, checked: e.target.checked ? true : false } })
                return items
            })
        }
        catch (error) {
            //console.log(error);
        }
    }

    async function handleSharePointListSort(e: React.MouseEvent<HTMLImageElement>, sortOrder: string) {
        try {
            const sortField = e.currentTarget.id.split('@')[0];
            setSharePointSort(sortField + sortOrder);

            const sortedItems = [...sharePointLists].sort((a: any, b: any) => {
                if (sortOrder === "down") {
                    return a[sortField].localeCompare(b[sortField]);
                } else {
                    return b[sortField].localeCompare(a[sortField]);
                }
            });

            setSharePointLists(sortedItems);
        } catch (error) {
            console.error("Error sorting SharePoint lists:", error);
        }
    }

    async function getSourceEntities() {
        setLoading(true); // Set loading state to true
        try {
            let token = await getToken()
            debugger
            let payload: any = {
                taskId: `${taskId}`,
                token: token,
                applicationName: `${applicationName}`,
                searchValue: searchTerm.trim(),
                sortOrder: sortOrder,
                sortBy: sortBy
            }

            if (applicationName == 'sharepoint' && !['ssd.SourceSPSiteName', 'ssd.SourceSPSiteURL'].includes(sortBy)) {
                payload.sortBy = 'ssd.SourceSPSiteName'
            }
            if (applicationName == 'teamschannel' && !['stcd.SourceTeamName', 'stcd.SourceChannelName'].includes(sortBy)) {
                payload.sortBy = 'stcd.SourceTeamName'
            }

            const sourceEntities: any = await getSourceEntitiesService(payload);

            if (sourceEntities.data.statusCode === 200) {
                if (sourceEntities.data.data.length !== 0) {
                    const newData = sourceEntities.data.data.map((item: any) => ({
                        ...item,
                        checked: false,
                    }));

                    setData(newData);
                    setSelectAll(false);
                    settotalDataCount(sourceEntities.data.data.length);
                    if (searchTerm === '') {
                        setSearchEnabled(false);
                    }
                } else {
                    // If data is empty or length is 0, empty the state variable data
                    setData([]);
                    setSelectAll(false);
                    settotalDataCount(0);
                }
            } else {
                setAlert(sourceEntities.data.statusMessage);
            }
        } catch (error) {

        }
        finally {
            setLoading(false);
        }
    }

    async function getSharePointLists(siteDetail: any) {
        setLoading(true)
        try {
            let token = await getToken()
            let payload = {
                token: token,
                siteId: siteDetail.SourceSPSiteDetailsID,
                taskId: taskId,
            }
            setSharePointLists([]);
            let lists = await getSharePointListsService(payload)
            if (lists?.data.statusCode == 200) {
                let newData = lists?.data.data.listsData.map((item: any) => ({ ...item, checked: false, listType: (item.listType == "documentLibrary") ? "Document Library" : (item.listType == "webPageLibrary") ? "Page Library" : "List" }))
                setSharePointLists(newData)
                setSiteId(lists?.data.data.siteId)
                setSelectAllList(false)
                setSharepointSelectionPopup(false);
                // setTotalListCount(lists?.data.data.length)
                setTotalListCount(newData.length);
                setSiteDisplayName(siteDetail?.sourceDisplayName)
            }
            // else {
            //     setAlert(lists?.data.statusMessage)
            // }
        }
        catch (error) {
            //console.log(error);

        }
        finally {
            setLoading(false)
        }
    }


    // Get Sites and TeamChannel
    const getTeamsandChannels = async (btnType: string, migrationType: string) => {

        try {
            debugger
            let token: any = await getToken();
            let apiResponse: any;
            setLoading(true)
            if (btnType.toLowerCase() === "getsourceteamsandchannels" || btnType.toLowerCase() === "gettargetteamsandchannels") {
                apiResponse = await getTeamsandChannelsApi(btnType, token, taskId, migrationType);
            } else {
                apiResponse = await getSitesApi(btnType, token, taskId);
            }

            if (apiResponse.data.statusMessage === 'Success' && apiResponse.data.statusCode === 200) {
                setLoading(false)
            } else {
                setAlertMessagepopup(commonErrorMsgs.generalError)
                setFailAlertpopup(true);
            }
        } catch (error) {
            setAlertMessagepopup(commonErrorMsgs.generalError)
            setFailAlertpopup(true);
            //console.log(error)
        }
        finally {
            setLoading(false)
        }
    };


    const handleMigrationAction = (index: number) => {
        try {
            const gridData: Array<any> = JSON.parse(JSON.stringify(data));
            gridData.splice(index, 1);
            setData(gridData);
            settotalDataCount(totalDataCount - 1);
        } catch (err) {
            console.error(err);
        }
    }

    const deleteMapping = async (id: number, pageType: string) => {
        try {
            debugger
            setLoading(true)
            const token = await getToken();

            const response: any = await deleteMappingService(pageType, token, (id).toString(), Number(taskId), true);

            if ((response.data.statusMessage === 'Team Channel Mapping Deleted SuccessFull' || response.data.statusMessage == 'Site Mapping Deleted SuccessFull' || response.data.statusMessage == 'Site Mapping Deleted Successfully' || response.data.statusMessage == 'Team Channel Mapping Deleted Successfully') && response.data.statusCode === 200) {
                await fetchData();
            } else {
                setFailAlertpopup(true);
                setAlertMessagepopup('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
            }

        } catch (err) {
            // Default behaviour or error handling in Techstack React TS
            setFailAlertpopup(true);
            setAlertMessagepopup('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
        }
        finally {
            setLoading(false)
        }
    };

    return (

        <div className="table-responsive theme-table bg-white mb-3">
            <table className="table table-borderless table-shadow mb-0">
                <thead style={{ boxShadow: '0 0 4px #ccc' }}>
                    <tr>
                        <th className="text-nowrap table-accordion-head">
                            <span className="d-flex align-items-center gap-3">
                                <img
                                    src="/images/table-accordion-arrow.svg"
                                    alt="table-accordion-arrow"
                                    className={`cursor-pointer table-accordion-arrow ${isOpen ? "" : "collapsed"}`}
                                    data-bs-toggle="collapse"
                                    data-bs-target="#avaZeb"
                                    onClick={() => {
                                        if ((applicationName === 'teamschannel' || applicationName === 'sharepoint') && !isOpen) {
                                            setData([]);
                                            setrenderstate(!renderstate);
                                        }
                                        onToggle();
                                    }}
                                />
                                <span className="ac-heading" onClick={onToggle}>
                                    Migration
                                </span>
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr className={`collapse ${isOpen ? "show" : ""}`} id="avaZeb">
                        <td colSpan={2}>
                            {data?.length > 0 || isEntityMapped || searchEnabled ?
                                <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 ">
                                    <h5 className="font-18 font-semibold mb-0 ">
                                        {getHeaderText(applicationName) && (
                                            <>
                                                {getHeaderText(applicationName)} (<span>{totalDataCount}</span>)
                                                <Tippy
                                                    delay={100}
                                                    arrow={true}
                                                    placement='top'
                                                    content={applicationName == "teamschannel" && isDirectMigration ? "Displays Source  Teams & Channels." : applicationName == "teamschannel" && isActualUserMigration ? "Displays mapped Teams & Channels with option to add new mappings by clicking on '+ Mapping' button." : applicationName == "sharepoint" && isDirectMigration ? "Displays Source Sites & URL." : applicationName == "sharepoint" && isActualUserMigration ? "Displays mapped sites with option to add new mappings by clicking on '+ Mapping' button." : "Displays Mapped Source and Target Entities."}
                                                >
                                                    <img className="ms-1"
                                                        src="/images/tooltip.svg"
                                                        alt="tooltip"
                                                        width={11}

                                                    />
                                                </Tippy>

                                            </>
                                        )}
                                    </h5>

                                    <span className="d-flex gap-3 flex-wrap align-items-center">

                                        <span className="d-flex gap-3 align-items-center">

                                            <span className="input-group box-shadow-filter search-container float-start">
                                                <input
                                                    type="search"
                                                    className="form-control custom-form font-14 font-regular"
                                                    placeholder="Search"
                                                    value={searchTerm}
                                                    onChange={handleSearch}
                                                    onKeyDown={(event) => { handleKeyDown(event) }}
                                                />
                                                <button className="btn border d-flex align-items-center" type="button" id="search-icon" onClick={handleKeyDown}>
                                                    <img src="/images/search.svg" alt="search" className="d-block " style={{ pointerEvents: 'none' }}

                                                    />
                                                </button>
                                            </span>

                                            {/* {applicationName?.toLowerCase()?.trim() === 'groups' ?
                                            <><label className="font-14 font-semibold label-color text-nowrap ">Group Type</label>
                                                <select
                                                    name="groupType"
                                                    id="groupType"
                                                    className="form-select w-100 h-100 m-0 font-14 font-regular"
                                                >
                                                    <option value="All" selected="">
                                                        All
                                                    </option>
                                                    <option value={1}>1</option>
                                                    <option value={2}>Lorem, ipsum.</option>
                                                    <option value={3}>3</option>
                                                </select></> : null} */}
                                        </span>

                                        {(applicationName === 'sharepoint' || applicationName === 'teamschannel') ? (
                                            isDirectMigration ? (
                                                <button
                                                    style={{ backgroundColor: '#F8F8F8' }}
                                                    className="btn text-nowrap font-semibold font-14 gap-2 justify-content-center align-items-center"
                                                    onClick={async () => {
                                                        // await getTeamsandChannels('getsourceteamsandchannels', 'standard');
                                                        if (applicationName === 'sharepoint') {
                                                            await getTeamsandChannels('GetSourceSites', 'any');
                                                        } else {
                                                            await getTeamsandChannels('getsourceteamsandchannels', 'standard');
                                                        }
                                                        getSourceEntities();
                                                    }}
                                                >
                                                    <img src="/images/refresh-icon.svg" alt="Refresh" />
                                                </button>
                                            ) : (
                                                <button
                                                    style={{ backgroundColor: '#F8F8F8', color: '#464775', borderRadius: '3px', borderColor: '#464775' }}
                                                    className="btn text-nowrap font-semibold font-14 gap-2 justify-content-center align-items-center"
                                                    disabled={disableActions}
                                                    onClick={() => {
                                                        debugger;
                                                        history.push("/CustomCreationandMapping", {
                                                            state: {
                                                                ...location.state,
                                                            }
                                                        });
                                                    }}
                                                >
                                                    + Mapping
                                                </button>
                                            )
                                        ) : null}
                                        <button className="btn primary-btn text-nowrap font-semibold font-14 d-flex gap-2 justify-content-center align-items-center"
                                            onClick={openSchedulerPopup}
                                            disabled={disableActions ? true : !(data?.some((obj: { checked: boolean; }) => obj.checked === true)) || role === 'viewer'}
                                        // disabled={!(data.some(obj => obj.checked === true)) || role === 'viewer'}
                                        >
                                            Schedule Migration
                                        </button>
                                        <button className="btn primary-btn text-nowrap font-semibold font-14 d-flex gap-2 justify-content-center align-items-center"
                                            onClick={handleStartMigration}
                                            disabled={disableActions ? true : !(data?.some((obj: { checked: boolean; }) => obj.checked === true)) || role === 'viewer'}
                                        >
                                            Start Migration
                                        </button>
                                    </span>
                                </div> : ""}
                            <div className="table-responsive theme-table bg-white d-inline-block w-100 custom-scroll table-y-scroll">
                                <table className="table table-borderless mb-0">
                                    <thead className="sticky-top" style={{ boxShadow: '0 0 4px #ccc' }}>
                                        <tr>
                                            <th>{data?.length > 0 ?
                                                <input
                                                    className="form-check-input cus-check-input"
                                                    type="checkbox"
                                                    onClick={() => { data.length === selectedRows.length ? setSelectedRows([]) : setSelectedRows(data) }}
                                                    checked={selectAll && data.length > 0}
                                                    onChange={handleSelectAllChange}
                                                    disabled={disableActions}
                                                    style={{ cursor: 'pointer' }}
                                                /> : ""}
                                            </th>
                                            {
                                                obj && obj[applicationName.toLocaleLowerCase().trim()] && obj[applicationName.toLocaleLowerCase().trim()].map(({ header = '', value = '', sort = '' }: { header: string, value: string, sort: any }, index: number) => (
                                                    <>
                                                        {(header !== 'Action' || !disableActions) &&
                                                            <th key={index} className="text-nowrap">
                                                                {header}
                                                                {header != 'Action' ?
                                                                    <span className="ms-1 cursor-pointer">
                                                                        <img
                                                                            src={`/images/sort-arrow-up.svg`}
                                                                            alt="sort-arrow-up"
                                                                            id={`${sort}@DESC`}
                                                                            style={{ cursor: 'pointer', display: sortHide === `${sort}@ASC` ? 'inline' : 'none' }}
                                                                            onClick={(e) => handleSort(e)}
                                                                        />
                                                                        <img
                                                                            src={`/images/sort-arrow-down.svg`}
                                                                            alt="sort-arrow-down"
                                                                            id={`${sort}@ASC`}
                                                                            style={{ cursor: 'pointer', display: sortHide === `${sort}@ASC` ? 'none' : 'inline' }}
                                                                            onClick={(e) => handleSort(e)}
                                                                        />
                                                                    </span> : null}
                                                            </th>}</>
                                                ))
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {searchEnabled && data?.length == 0 ? (<tr>
                                            <td colSpan={obj[applicationName.toLowerCase().trim()].length + 1}>
                                                <div className="text-center">
                                                    <div className="pt-4 pb-3 text-center">
                                                        <img src="/images/no-data-found-img.svg" alt="no-data-found-img" className="mb-3" />
                                                        <h5 className="font-12 font-bold color-no-rec">No Data</h5>
                                                        <p className="font-10 font-regular color-no-rec">There is no data to show you right now</p>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>)
                                            : data?.length > 0 ? (
                                                data?.map((user: any, index: any) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <input className="form-check-input cus-check-input" type="checkbox"
                                                                checked={user.checked}
                                                                onChange={(e) =>
                                                                    handleCheckboxChange(
                                                                        index
                                                                    )
                                                                }
                                                                disabled={disableActions}
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                        </td>

                                                        {

                                                            obj[applicationName.toLowerCase().trim()].map(({ header = '', value = '' }: { header: string, value: string }, i: number) => {
                                                                if (header === 'Action' && !disableActions) {
                                                                    return (
                                                                        <td>
                                                                            <button
                                                                                style={{ cursor: 'pointer' }}
                                                                                className="btn action-rounded-btn danger-action d-flex align-items-center justify-content-center"
                                                                                title={"cancel"}
                                                                                onClick={() => {
                                                                                    deleteMapping(applicationName.toLowerCase().trim() == 'sharepoint' ? user.SPSiteMigrationMappingID : user.TeamChannelMigrationMappingID, applicationName.toLowerCase().trim() == 'sharepoint' ? 'Site' : 'TeamChannel')
                                                                                }
                                                                                }
                                                                            >
                                                                                <img
                                                                                    src="/images/red-delete-icon.svg"
                                                                                    alt="delete"
                                                                                />
                                                                            </button>
                                                                        </td>
                                                                    )
                                                                }
                                                                const userValue: any = user[value];
                                                                return (
                                                                    <>
                                                                        {(userValue && userValue.length > 21) ? (
                                                                            <td style={{ textTransform: value.toLowerCase() === "resourcetype" ? 'capitalize' : 'none' }}>
                                                                                <span
                                                                                    data-bs-toggle="tooltip"
                                                                                    data-bs-placement="right"
                                                                                    data-bs-title="Type Team Site"
                                                                                    data-bs-original-title=""
                                                                                    title={userValue}
                                                                                    style={{ cursor: 'pointer' }}
                                                                                >
                                                                                    {(value == "sourceDisplayName" && applicationName == "sharepoint") ? <span style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => { getSharePointLists(user); }} > {userValue.slice(0, 21) + '...'}</span> : userValue.slice(0, 21) + '...'}

                                                                                </span>
                                                                            </td>
                                                                        ) : (
                                                                            <td style={{ textTransform: value.toLowerCase() === "resourcetype" ? 'capitalize' : 'none' }}>
                                                                                {(value == "sourceDisplayName" && applicationName == "sharepoint") ? <span style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => { getSharePointLists(user); setSharepointSelectionPopup(true) }} > {userValue}</span> : userValue}
                                                                            </td>
                                                                        )}
                                                                    </>
                                                                );
                                                            })
                                                        }
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    {(isEntityMapped) &&
                                                        <td colSpan={obj[applicationName.toLowerCase().trim()].length}>
                                                            <div className="text-center">
                                                                <div className="pt-4 pb-3 text-center">
                                                                    <img src="/images/no-data-found-img.svg" alt="no-data-found-img" className="mb-3" />
                                                                    <h5 className="font-12 font-bold color-no-rec">No Data</h5>
                                                                    <p className="font-10 font-regular color-no-rec">There is no data to show you right now</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    }

                                                    {(location?.state?.MigrationMode?.toLowerCase() == "migration" && applicationName == "teamschannel" && !isDirectMigration && !isEntityMapped) &&


                                                        <td colSpan={6}>
                                                            <div className="text-center">
                                                                <div className="font-18 font-semibold mb-0">Actual User Migratrion</div>
                                                                <div className="pt-4 pb-3 text-center">
                                                                    <img src="/images/Actual-user-migration.svg" alt="Actual-user-migration-img" className="mb-3" />
                                                                    <p className="font-10 mt-2 font-regular color-no-rec Mt-sm-3">Migrate channels from Teams on behalf of Actual users as Teams public channels.</p>
                                                                    <button className="btn mt-2 primary-btn text-nowrap font-semibold font-14 gap-2 justify-content-center align-items-center " onClick={() => {
                                                                        debugger
                                                                        history.push("/CustomCreationandMapping", {
                                                                            state: {
                                                                                ...location.state,
                                                                            }
                                                                        })
                                                                    }}>
                                                                        Create Mapping
                                                                    </button>
                                                                    <p className="font-10 mt-2 font-regular color-no-rec">Click <a style={{ color: '#1592E6' }} href="https://www.youtube.com">here</a> to know more about Actual User Migration.</p>

                                                                </div>
                                                            </div>
                                                        </td>}
                                                    {(location?.state?.MigrationMode?.toLowerCase() == "standard" && applicationName == "teamschannel" && !isDirectMigration && !isEntityMapped) && <td colSpan={6}>
                                                        <div className="text-center">
                                                            <div className="font-18 font-semibold mb-0">Service Account Migration</div>
                                                            <div className="pt-4 pb-3 text-center">

                                                                <img src="/images/service-account-migration.svg" alt="service-account-migration-img" className="mb-3" />
                                                                <p className="font-10 mt-2 font-regular color-no-rec">Migrate channels from Teams on behalf of Service Account as MS Teams public channels.</p>
                                                                <button className="btn mt-2 primary-btn text-nowrap font-semibold font-14 gap-2 justify-content-center align-items-center " disabled={disableActions} onClick={() => {
                                                                    debugger
                                                                    history.push("/CustomCreationandMapping", {
                                                                        state: {
                                                                            ...location.state,
                                                                        }
                                                                    })
                                                                }}>
                                                                    Create Mapping
                                                                </button>
                                                                <p className="font-10 mt-2 font-regular color-no-rec">Click <a style={{ color: '#1592E6' }} href="https://www.youtube.com">here</a> to know more about Service Account Migration.</p>

                                                            </div>
                                                        </div>
                                                    </td>}

                                                    {(location?.state?.MigrationMode?.toLowerCase() == "migration" && applicationName == "teamschannel" && isDirectMigration && !isEntityMapped) && <td colSpan={6}>
                                                        <div className="text-center">
                                                            <div className="font-18 font-semibold mb-0">Actual User Migration</div>
                                                            <div className="pt-4 pb-3 text-center">
                                                                <img src="/images/Actual-user-migration.svg" alt="Actual-user-migration-img" className="mb-3" />
                                                                <p className="font-10 mt-2 font-regular color-no-rec Mt-sm-3">Migrate channels from Teams on behalf of Actual users as Teams public channels.</p>
                                                                <button className="btn mt-2 primary-btn text-nowrap font-semibold font-14 gap-2 justify-content-center align-items-center " onClick={async () => {

                                                                    // getTeamsandChannels()
                                                                    debugger
                                                                    await getTeamsandChannels('getsourceteamsandchannels', 'migration')
                                                                    getSourceEntities()

                                                                }}>
                                                                    <img src="/images/sync.svg" className="ml-2" />
                                                                    Sync
                                                                </button>
                                                                <p className="font-10 mt-2 font-regular color-no-rec">Click <a style={{ color: '#1592E6' }} href="https://www.youtube.com">here</a> to know more about Actual User Migration.</p>

                                                            </div>
                                                        </div>
                                                    </td>}
                                                    {(location?.state?.MigrationMode?.toLowerCase() == 'standard' && applicationName == "teamschannel" && isDirectMigration && !isEntityMapped) && <td colSpan={6}>
                                                        <div className="text-center">
                                                            <div className="font-18 font-semibold mb-0">Service Account Migration</div>
                                                            <div className="pt-4 pb-3 text-center">
                                                                <img src="/images/service-account-migration.svg" alt="service-account-migration-img" className="mb-3" />
                                                                <p className="font-10 mt-2 font-regular color-no-rec">Migrate channels from Teams on behalf of Service Account as MS Teams public or private channels.</p>
                                                                <button className="btn mt-2 primary-btn text-nowrap font-semibold font-14 gap-2 justify-content-center align-items-center " onClick={async () => {
                                                                    await getTeamsandChannels('getsourceteamsandchannels', 'standard')
                                                                    getSourceEntities()
                                                                }}>
                                                                    <img src="/images/sync.svg" className="ml-2" />
                                                                    Sync
                                                                </button>
                                                                <p className="font-10 mt-2 font-regular color-no-rec">Click <a style={{ color: '#1592E6' }} href="https://www.youtube.com">here</a> to know more about Service Account Migration.</p>

                                                            </div>
                                                        </div>
                                                    </td>}
                                                    {(applicationName == "sharepoint" && isDirectMigration && !isEntityMapped) && <td colSpan={6}>
                                                        <div className="text-center">
                                                            <div className="font-18 font-semibold mb-0">SharePoint Migration</div>
                                                            <div className="pt-4 pb-3 text-center">
                                                                <img src="/images/sharept mig.svg" alt="sharept mig-img" className="mb-3" />
                                                                <p className="font-10 mt-2 font-regular color-no-rec">Click Sync button to fetch the source site details</p>
                                                                <button className="btn mt-2 primary-btn text-nowrap font-semibold font-14 gap-2 justify-content-center align-items-center " onClick={async () => {
                                                                    await getTeamsandChannels('GetSourceSites', 'any')
                                                                    getSourceEntities()
                                                                }}>
                                                                    <img src="/images/sync.svg" />
                                                                    &nbsp; Sync
                                                                </button>
                                                                <p className="font-10 mt-2 font-regular color-no-rec">Click <a style={{ color: '#1592E6' }} href="https://www.youtube.com">here</a> to know more about Sharepoint Migration.</p>

                                                            </div>
                                                        </div>
                                                    </td>}
                                                    {(applicationName == "sharepoint" && !isDirectMigration && !isEntityMapped) && <td colSpan={6}>
                                                        <div className="text-center">
                                                            <div className="font-18 font-semibold mb-0">SharePoint Migration</div>
                                                            <div className="pt-4 pb-3 text-center">
                                                                <img src="/images/sharept mig.svg" alt="sharept mig-img" className="mb-3" />
                                                                <p className="font-10 mt-2 font-regular color-no-rec">Click create mapping button to start mapping source and target sites.</p>
                                                                <button className="btn mt-2 primary-btn text-nowrap font-semibold font-14 gap-2 justify-content-center align-items-center " disabled={disableActions} onClick={() => {
                                                                    debugger
                                                                    history.push("/CustomCreationandMapping", {
                                                                        state: {
                                                                            ...location.state,
                                                                        }
                                                                    })
                                                                }}>
                                                                    Create Mapping
                                                                </button>
                                                                <p className="font-10 mt-2 font-regular color-no-rec">Click <a style={{ color: '#1592E6' }} href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">here</a> to know more about Sharepoint Migration.</p>

                                                            </div>
                                                        </div>
                                                    </td>
                                                    }
                                                    {(data?.length === 0 && (applicationName == "outlook" || isDirectMigration) && searchEnabled) ?
                                                        <td colSpan={obj[applicationName.toLowerCase().trim()].length + 1}>
                                                            <div className="text-center">
                                                                <div className="pt-4 pb-3 text-center">
                                                                    <img src="/images/no-data-found-img.svg" alt="no-data-found-img" className="mb-3" />
                                                                    <h5 className="font-12 font-bold color-no-rec">No Data</h5>
                                                                    <p className="font-10 font-regular color-no-rec">There is no data to show you right now</p>
                                                                </div>
                                                            </div>
                                                        </td> : ""}
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            {loading && (
                <div className="container-fluid">
                    <div className="overlay">
                        <div className="position-absolute top-50 start-50 translate-middle">
                            <div
                                className="spinner-border Loader loader-color align-center "
                                role="status"
                            >
                                <span>
                                    <span className="loader-inner-circle"></span>
                                </span>
                            </div>
                            <p className="loading-text font-16 font-semibold color-white-v2 mt-2">
                                Loading..
                            </p>
                        </div>
                    </div>
                </div>
            )}

            {/*Schedule migration starts here */}
            {scheduleMigrationDateTime ? (
                <div className="modal fade show" tabIndex={-1} style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content p-4">
                            <div className="modal-header border-0 p-0">
                                <h1 className="font-20 font-bold custom-header-color m-0" id="scheduleMigrationLabel">Schedule Migration</h1>
                                <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={handleScheduleClose} />
                            </div>
                            <div className="modal-body border-0 p-0 pt-2 pb-2">
                                <div className="row mt-2">
                                    <span className="font-16 popup-data d-block font-regular mt-2 mb-3">If any migration is in progress, the scheduled migration will be in queue.</span>
                                    <div className="col-md-6 col-12 position-relative">
                                        <label className="font-14 font-semibold label-color w-100 mb-2" htmlFor="date">Date</label>
                                        <input type="date" className="form-control custom-form font-14 font-regular " id="date" value={dateInput} min={new Date().toISOString().split('T')[0]} max={new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().split('T')[0]} onChange={handleDateChange} />
                                        <img src="/images/date-icon.svg" alt="date-icon" className="position-absolute custom-input-icon"></img>
                                    </div>
                                    <div className="col-md-6 col-12 position-relative">
                                        <label className="font-14 font-semibold label-color w-100 mb-2" htmlFor="time">Time (UTC)</label>
                                        <select className="form-control custom-form font-14 font-regular cust-time position-relative" id="time" value={timeInput} onChange={handleTimeChange}>
                                            {timeOptions}
                                        </select>
                                        <img src="/images/time-icon.svg" alt="date-icon" className="position-absolute custom-input-icon"></img>
                                    </div>
                                </div>
                            </div>
                            <span style={{ color: 'red' }}>{scheduleMigrationDateTimeWarning}</span>
                            <div className="modal-footer border-0 p-0 mt-3">
                                <button type="button" className="btn secondary-btn" data-bs-dismiss="modal" onClick={handleScheduleCancel}>Cancel</button>
                                <button type="button" className="btn primary-btn" onClick={() => { handleScheduleMigration() }} disabled={true ? dateInput === '' || timeInput === '' || isTimeInputLessThanCurrentTime() || scheduleMigrationDateTimeWarning !== "" : false}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
            {/*Schedule migration popup ends here */}

            {/*Failure Popup starts here */}
            {alertpopup ? <div
                className="modal fade show"
                tabIndex={-1}
                style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content popup-brd-radius">
                        <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                            <span className="position-absolute">
                                <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
                            </span>
                        </div>
                        <div className="modal-body border-0 text-center">
                            <h5
                                className="modal-title mb-3 primary-header"
                                id="Failure-popupLabel"
                            >
                                Alert
                            </h5>
                            <p className="primary-data">
                                You don't have enough license, Please <a href="/UpgradePlan" style={{ textDecoration: 'none', cursor: 'pointer' }}>upgrade plan</a> to proceed further.
                            </p>
                        </div>
                        <div className="modal-footer border-0 pb-4 justify-content-center">
                            <button
                                type="button"
                                className="btn primary-btn font-14 px-4 font-semibold"

                                onClick={() => {
                                    if (isSessionEpired) {
                                        history.push('/Login')
                                    }
                                    setAlertpopup(false)
                                }}
                            >
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div> : null}
            {/*Failure Popup ends here */}
            <div
                // className="modal fade show"
                hidden={sharepointSelectionPopup}
                className="modal fade show"
                tabIndex={-1}
                style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content p-4">
                        <div className="modal-header border-0 p-0">
                            <h1
                                className="font-20 font-bold custom-header-color m-0"
                                id="internalDiscussionLabel"
                            >

                                {(siteDisplayName.length && siteDisplayName.length > 17) ? (
                                    <td style={{ textTransform: 'capitalize' }}>
                                        <span
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="right"
                                            data-bs-title="Type Team Site"
                                            data-bs-original-title=""
                                            title={siteDisplayName}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {siteDisplayName.slice(0, 17) + '...'}{sharePointLists.length}
                                        </span>
                                    </td>
                                ) : (
                                    <td style={{ textTransform: 'capitalize' }}>
                                        <span>{siteDisplayName} ({totalListCount})</span>
                                    </td>)
                                }
                            </h1>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => { setSharepointSelectionPopup(true) }}
                            />
                        </div>
                        <div className="modal-body border-0 p-0 pt-2 pb-2">
                            <div className="row">
                                <div className="col-md-12 mt-3">
                                    <div className="table-responsive theme-table bg-white custom-scroll table-y-scroll">
                                        <table className="table table-borderless mb-0">
                                            <thead>
                                                <tr>
                                                    <th className="checkbox-col">
                                                        <input
                                                            className="form-check-input cus-check-input"
                                                            type="checkbox"
                                                            disabled={sharePointLists.length == 0}
                                                            checked={!sharePointLists.some((item: any) => !item.checked)}
                                                            onChange={(e) => { handleSelectAllLists(e) }}
                                                        />
                                                    </th>

                                                    {listObj.map((item, _) => (
                                                        <th className="text-nowrap">
                                                            {item.header}
                                                            <span className="ms-1 cursor-pointer">
                                                                {sharePointSort === item.sort + "down" ? (
                                                                    <img
                                                                        src="/images/sort-arrow-up.svg"
                                                                        alt="sort-arrow-down"
                                                                        id={`${item.sort}@a`}
                                                                        onClick={(e) => handleSharePointListSort(e, "up")}
                                                                    />
                                                                ) : sharePointSort === item.sort + "up" ? (
                                                                    <img
                                                                        src="/images/sort-arrow-down.svg"
                                                                        alt="sort-arrow-up"
                                                                        id={`${item.sort}@b`}
                                                                        onClick={(e) => handleSharePointListSort(e, "down")}
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        src="/images/sort-arrow-down.svg"
                                                                        alt="sort-arrow-default"
                                                                        id={`${item.sort}@c`}
                                                                        onClick={(e) => handleSharePointListSort(e, "up")}
                                                                    />
                                                                )}
                                                            </span>


                                                        </th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {sharePointLists.length > 0 ?
                                                    sharePointLists.map((item: any, index: number) => (
                                                        <tr>
                                                            <td>
                                                                <input
                                                                    className="form-check-input cus-check-input"
                                                                    type="checkbox"
                                                                    checked={item.checked}
                                                                    onChange={() => {
                                                                        setSharePointLists((prevItems: any) => {
                                                                            let items = [...prevItems]
                                                                            items[index] = { ...items[index], checked: !items[index].checked }
                                                                            return items
                                                                        })
                                                                    }}

                                                                />
                                                            </td>
                                                            <td>{item.listName}</td>
                                                            <td>{item.listType}</td>
                                                        </tr>
                                                    )) :
                                                    (<td colSpan={3}>
                                                        <div className="text-center">
                                                            <div className="pt-4 pb-3 text-center">
                                                                <img src="/images/no-data-found-img.svg" alt="no-data-found-img" className="mb-3" />
                                                                <h5 className="font-12 font-bold color-no-rec">No Data</h5>
                                                                <p className="font-10 font-regular color-no-rec">There is no data to show you right now</p>
                                                            </div>
                                                        </div>
                                                    </td>)}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer border-0 p-0 mt-3">
                            <button
                                type="button"
                                className="btn secondary-btn"
                                data-bs-dismiss="modal"
                                onClick={() => { setSharepointSelectionPopup(true) }}
                            >
                                Cancel
                            </button>
                            <button type="button" className="btn primary-btn" onClick={() => { updateSelectedItems(); }} disabled={!((sharePointLists.some((item: any) => item.checked == true)) == true)}>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* failure Pop Up starts here */}
            {failAlert ? <div
                className="modal fade show"
                tabIndex={-1}
                style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content popup-brd-radius">
                        <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                            <span className="position-absolute">
                                <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
                            </span>
                        </div>
                        <div className="modal-body border-0 text-center">
                            <h5
                                className="modal-title mb-3 primary-header"
                                id="Failure-popupLabel"
                            >
                                { }
                            </h5>
                            <p className="primary-data">
                                {alertMessage}
                            </p>
                        </div>
                        <div className="modal-footer border-0 pb-4 justify-content-center">
                            <button
                                type="button"
                                className="btn primary-btn font-14 px-4 font-semibold"
                                onClick={() => {
                                    setFailAlertpopup(false)
                                }}
                            >
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div> : null}
            {/*Failure Popup ends here */}
        </div>
    );
};

export default SelectMigration;