import { useEffect, useState, useRef } from "react";
import { ApplicationStats, ApplicationStatsState, OutlookData, sessionResponse } from "../../Common/Interface";
import { decodeSessionDetails } from "../../Services/CommonServices";
import { getAssessmentSummary } from "../../Services/AssessmentSummaryServices";
import { useHistory, useLocation } from "react-router";
import objCryptoJS from "crypto-js";
import { commonErrorMsgs } from "../../Common/Constant";
import * as XLSX from 'xlsx';
import { getSession } from '../../Services/manageClientAPI';
import jwt from 'jwt-decode';

export default function AssessmentSummary() {
    /**PS_01 to PS_14 will be executed
 */
    const [alert, setAlert] = useState<boolean>(false)
    const [loader, setLoader] = useState<boolean>(false)
    const [alertMessage, setAlertMessage] = useState<string>('')
    let location: any = useLocation().state;
    //console.log(location, "locaaaaa");

    let taskName = location?.state?.taskName;



    const history = useHistory()
    const [isCustomized, setIsCustomized] = useState<boolean>(true);
    // PS_03
    const [connectionName, setConnectionName] = useState<string>('');

    const [applicationName, setApplicationName] = useState<any>('SharePoint');

    // PS_04
    const [assessmentTimeTaken, setAssessmentTimeTaken] = useState<Date | null>(null);
    // PS_05
    const [assessmentStartDate, setAssessmentStartDate] = useState<Date | null>(null);
    // PS_06
    const [assessmentEndDate, setAssessmentEndDate] = useState<Date | null>(null);
    // PS_07
    const [customizedStartDate, setCustomizedStartDate] = useState<any>(null);
    // PS_08
    const [customizedEndDate, setCustomizedEndDate] = useState<any>(null);

    // PS_11
    const [status, setStatus] = useState<string>('');
    const [totalCount, settotalCount] = useState<number>(0);
    const [folderCount, setFolderCount] = useState<number>(0);
    const [fileCount, setFileCount] = useState<number>(0);

    const [progressCount, setProgressCount] = useState<number>(0);
    const [Completion, setCompletion] = useState<number>(0);
    const [Size, setSize] = useState<string>('0 B');
    // PS_12

    // PS_13
    const [exportData, setExportData] = useState<any[]>([]);

    // PS_14
    const [applicationData, setApplicationData] = useState<{ [key: string]: any }>({});
    const [totalSize, setTotalSize] = useState('');
    const [overallStatus, setOverallStatus] = useState('');

    const [showFilesCount, setShowFilesCount] = useState(true);

    const [applicationStats, setApplicationStats] = useState<{ [key: string]: any }>({});


    const [hasCustomziedApps, setHasCustomziedApps] = useState<boolean>(false);
    const [hasNonCustomziedApps, setHasNonCustomizedApps] = useState<boolean>(false);

    const [customizedApps, setCustomizedApps] = useState<string[]>([]);
    const [nonCustomizedApps, setNonCustomizedApps] = useState<string[]>([]);

    const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';
    const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';
    const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';
    const [objAdmin, setObjAdmin] = useState({})
    const [objClient, setObjClient] = useState({})
    let [clientName, setclientName] = useState<string>('');
    let [companyName, setCompanyName] = useState<string>('');
    let [emailAddress, setEmailAddress] = useState<string>('');
    let [role, setRole] = useState<string>('');
    let [isAdmin, setIsAdmin] = useState<boolean>(false);
    let [adminName, setAdminName] = useState<string>('');
    let [adminEmailAddress, setAdminEmailAddress] = useState<string>('');
    let [adminrole, setAdminRole] = useState<string>('');

    let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
    const [isSessionEpired, setisSessionEpired] = useState(false);

    let InActiveTimeout = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        sessionTokenProperties()
        resetTimer();
        window.addEventListener('keydown', resetTimer);
        window.addEventListener('click', resetTimer);

        fetchData()
        return () => {
            if (InActiveTimeout.current) {
                console.log("Assessment Summary Unmount Time Clear");
                clearTimeout(InActiveTimeout.current);
            }
            window.removeEventListener('click', resetTimer);
            window.removeEventListener('keydown', resetTimer);
        }
    }, []);

    let resetTimer = async () => {
        try {
            debugger
            let sessionResponse: string = await resetSessionTimer(InActiveTimeout, SessionEpiredDateTime);
            if (sessionResponse == 'expired') {
                debugger
                sessionStorage.removeItem("c_at");
                setisSessionEpired(true)
                setAlert(true)
                setAlertMessage('Your Session has Expired please login again to continue');
            }
            else if (sessionResponse == 'updateToken') {
                if (emailAddress == "" || emailAddress == undefined) {
                    await sessionTokenProperties();
                }
                debugger
                let token = await getToken()

                let payload = {
                    mailId: emailAddress,
                    isAdmin: false,
                    token: token
                };
                let getsessionToken = await getSession(payload);
                const rn_secretKey: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY
                const cs_secretKey: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;

                // const c_byteValue = objCryptoJS.AES.decrypt(getsessionToken?.data?.data.sessionToken, rn_secretKey);
                // const c_decryptedValue = c_byteValue.toString(objCryptoJS.enc.Utf8);
                // let updatedToken = objCryptoJS.AES.encrypt(c_decryptedValue, cs_secretKey).toString();
                await sessionStorage.removeItem("c_at");
                await sessionStorage.setItem('c_at', getsessionToken?.data?.data.sessionToken);
                await sessionTokenProperties();
                // let updatedobjClient: any = jwt(c_decryptedValue);
                // SessionEpiredDateTime = updatedobjClient.CreatedAt;
                // setSessionEpiredDateTime(SessionEpiredDateTime);
            }
        }
        catch (error) {
            setLoader(false);
            setAlert(true)
            setAlertMessage('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
        }
    }

    const resetSessionTimer = async (InActiveTimeout: any, SessionExpirationDateTime: any) => {
        try {
            debugger
            if (typeof window !== 'undefined') {
                if (InActiveTimeout.current) {
                    clearTimeout(InActiveTimeout.current);
                }

                const tokenExpiryCheckInterval = Number(process.env.REACT_APP_TABIDLESCENARIO);

                InActiveTimeout.current = setTimeout(() => {
                    // console.log("Expired");          
                    sessionStorage.removeItem("c_at");
                    setisSessionEpired(true)
                    setAlert(true)
                    setAlertMessage('Your Session has Expired please login again to continue');
                }, tokenExpiryCheckInterval);

                const currentDateTime = new Date().toISOString();
                //const sessionExpiredDateTime = new Date(SessionExpirationDateTime).toISOString();
                const diffInMilliseconds = Math.abs(new Date(currentDateTime).getTime() - new Date(SessionEpiredDateTime).getTime());
                const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
                const sessionUpdateTimeGap = Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP);

                if (minutes > sessionUpdateTimeGap) {
                    return 'updateToken'
                }

                return 'NoActionRequired'
            }
            return 'NoActionRequired'
        }
        catch (ex) {
            throw ex
        }
    };

    let sessionTokenProperties = async () => {
        try {
            const tokenResponse: sessionResponse = await decodeSessionDetails();
            debugger
            if (tokenResponse.adminDetails) {
                if (tokenResponse.adminDetails) {
                    setObjAdmin(tokenResponse.adminDetails)
                    setAdminName(tokenResponse.adminDetails.Name);
                    setAdminEmailAddress(tokenResponse.adminDetails.Email);
                    setAdminRole(tokenResponse.adminDetails.Role);
                }
                // else {
                //     history.push("/Admin/Login")
                // }
            }
            // else {
                if (tokenResponse.clientDetails) {
                    setObjClient(tokenResponse.clientDetails)
                    setclientName(tokenResponse.clientDetails.Name);
                    setCompanyName(tokenResponse.clientDetails.CompanyName)
                    emailAddress = tokenResponse.clientDetails.Email;
                    setEmailAddress(emailAddress);
                    setRole(tokenResponse.clientDetails.Role);
                    
                    SessionEpiredDateTime = tokenResponse.clientDetails.CreatedAt;
                    setSessionEpiredDateTime(SessionEpiredDateTime);
                }
                else {
                    history.push("/Login")
                }
            // }
            //#endregion
        } catch (error) {
            console.error('Failed in main method', error);
        }
    };

    const getStatusCSS = (status: string) => {
        switch (status) {
            case "failed":
            case "warning":
                return "danger";
            case "queued":
                return "draft";
            case "draft":
                return "draft";
            case "cancelled":
                return "draft";
            case "scheduled":
                return "scheduled";
            case "inprogress":
                return "inprogress";
            case "in progress":
                return "inprogress";
            case "completed":
                return "success";
            case "paused":
                return "paused";
            default:
                return "";
        }
    };

    const getToken = async () => {
        let clientToken: string;
        let adminToken: string;
        let token: { clientToken?: string; adminToken?: string } = {};
        debugger
        try {
            if (objClient != undefined) {
                let sessionToken: any = sessionStorage.getItem('c_at')
                if (sessionToken) {
                    // sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)
                    // var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
                    // clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
                    token['clientToken'] = sessionToken

                }


            }
            if (objAdmin != undefined) {
                let sessionToken: any = sessionStorage.getItem('a_at')
                if (sessionToken) {
                    // sessionToken = objCryptoJS.AES.decrypt(sessionToken, a_secretKey)
                    // var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
                    // adminToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();

                    token['adminToken'] = sessionToken

                }
            }
            return token
        } catch (error) {
            throw error
        }
    }

    // async function formatBytes(bytes: number, decimals = 2) {
    //     if (bytes === 0) return '0 B';
    //     const k = 1000;
    //     const dm = decimals < 0 ? 0 : decimals;
    //     const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    //     const i = Math.floor(Math.log(bytes) / Math.log(k));
    //     return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    // };

    async function format1000Bytes(size: number) {
        const units: string[] = ['B', 'KB', 'MB', 'GB', 'TB'];
        let unitIndex: number = 0;

        while (size >= 1000 && unitIndex < units.length - 1) {
            size /= 1000;
            unitIndex++;
        }

        return size.toFixed(2) + ' ' + units[unitIndex];
    }

    async function format1024Bytes(bytes: number, decimals = 2) {
        if (bytes === 0) return '0 B';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        let i = Math.floor(Math.log(bytes) / Math.log(k));

        // Limit the index to 8 if it exceeds YB
        if (i > 8) i = 8;

        // Calculate the size
        const size = bytes / Math.pow(k, i);

        return `${size.toFixed(dm)} ${sizes[i]}`;
    }

    /**PS_33 to PS_55 will be executed
     */
    const fetchData = async () => {
        try {
            setLoader(true);
            let token = await getToken();

            let payload = {
                token: token,
                taskId: location?.state?.taskId
            };

            debugger

            const response: any = await getAssessmentSummary(payload);
            //console.log(response, "vanten da");

            if (response?.data?.statusCode === 200) {
                setLoader(false);
                const data = response.data.data;
                //console.log(data, 'dataaaa');

                //console.log("the response of the summary", response);

                // Set common data
                setConnectionName(data?.connectionName[0]?.connectionName || data?.connectionName || '');
                setAssessmentStartDate(data?.AssessmentDateAndTime?.assessmentInitatedDate || data?.AssessmentDateAndTime || '');
                setAssessmentTimeTaken(data?.completeDetails?.assessmentDetails?.timeTakenFormatted || '');
                setAssessmentEndDate(data?.completeDetails?.assessmentDetails?.assessmentEndDateTime || '');
                setCustomizedStartDate(data?.completeDetails?.assessmentDetails?.customStartDate ? new Date(data.completeDetails?.assessmentDetails?.customStartDate).toLocaleDateString() : '');
                setCustomizedEndDate(data?.completeDetails?.assessmentDetails?.customEndDate ? new Date(data.completeDetails?.assessmentDetails?.customEndDate).toLocaleDateString() : '');
                setSize(data?.totalStats?.totalStorageFormatted)
                setFolderCount(data?.totalStats?.totalFolders)
                setFileCount(data?.totalStats?.totalFiles)
                setOverallStatus(data?.overallStatus);

                setHasNonCustomizedApps(data?.totalStats?.hasNonCustomizedApps)
                setHasCustomziedApps(data?.totalStats?.hasCustomizedApps)

                setCustomizedApps(data?.completeDetails?.customizedApplications)
                setNonCustomizedApps(data?.completeDetails?.nonCustomizedApplications);

                // Initialize application-specific data
                const appData: { [key: string]: any } = {};

                // Check if only Outlook is in the applications
                const appKeys = Object.keys(data.applications);
                if (
                    (appKeys.length === 1 && (appKeys.includes('outlook') || appKeys.includes('shared'))) ||
                    (appKeys.length === 2 && appKeys.includes('outlook') && appKeys.includes('shared'))
                ) {
                    setShowFilesCount(false);
                } else {
                    setShowFilesCount(true);
                }

                debugger

                const updatedStats: any = { ...applicationStats };

                for (const [app] of Object.entries(data.applications)) {
                    if (app.toLowerCase().trim() === 'outlook' && data.applications.outlook.detailedInfo.length > 0) {
                        const outlookData = data.applications.outlook;
                        updatedStats.outlook = {
                            size: await format1024Bytes(outlookData.TotalSize),
                            status: outlookData.Status || '',
                            progressCount: outlookData.masterInventoryCount || 0,
                            totalCount: outlookData.sourceUserCount || 0,
                            percentage: outlookData?.PercentageCompletion || '0%',
                            detailedInfo: outlookData.detailedInfo || [],
                        };
                    }

                    if (app.toLowerCase().trim() === 'shared' && data.applications.shared.detailedInfo.length > 0) {
                        const sharedData = data.applications.shared;
                        updatedStats.shared = {
                            size: await format1024Bytes(sharedData.TotalSize),
                            status: sharedData.Status || '',
                            progressCount: sharedData.masterInventoryCount || 0,
                            totalCount: sharedData.sourceUserCount || 0,
                            percentage: sharedData?.PercentageCompletion || '0%',
                            detailedInfo: sharedData.detailedInfo || [],
                        };
                    }

                    if (app.toLowerCase().trim() === 'teamschannel') {
                        const teamsData = data.applications.teamschannel;
                        updatedStats.teamschannel = {
                            size: await format1024Bytes(teamsData.TotalSize),
                            status: teamsData.Status || '',
                            progressCount: teamsData.masterInventoryCount || 0,
                            totalCount: teamsData.sourceTeamCount || 0,
                            percentage: teamsData.PercentageCompletion || '0%',
                            detailedInfo: teamsData.detailedInfo || [],
                        };
                    }

                    if (app.toLowerCase().trim() === 'sharepoint') {
                        const spData = data.applications.sharepoint;
                        updatedStats.sharepoint = {
                            size: await format1024Bytes(spData.TotalSize),
                            status: spData.Status || '',
                            progressCount: spData.Count || 0,
                            totalCount: spData.sourceSiteCount || 0,
                            percentage: spData.PercentageCompletion || '0%',
                            detailedInfo: spData.detailedInfo || [],
                        };
                    }

                    if (app.toLowerCase().trim() === 'mydrive') {
                        const myDriveData = data.applications.mydrive;
                        updatedStats.mydrive = {
                            size: await format1024Bytes(Number(myDriveData.TotalSize)),
                            status: myDriveData.Status || '',
                            progressCount: myDriveData.Count || 0,
                            totalCount: myDriveData.TotalCount || 0,
                            percentage: myDriveData.PercentageCompletion || '0%',
                            detailedInfo: myDriveData.detailedInfo || [],
                        };
                    }
                }

                // Update the state with the new stats
                setApplicationStats(updatedStats);

                // For this response, isCustomized is not directly available
                // You might want to set it based on some other logic or remove it if not needed
                setIsCustomized(true); // or false, depending on your requirements

            } else {
                setLoader(false);
                setAlert(true);
                setAlertMessage(response?.data?.statusMessage || commonErrorMsgs.generalError);
            }
        } catch (error: any) {
            setAlert(true);
            setLoader(false);
            setAlertMessage(commonErrorMsgs.generalError);
        }
    };


    //PS_57 to PS_73
    const exportExcel = async () => {
        try {
            setLoader(true);
            //console.log("Starting Excel export");

            // Initialize a new workbook
            const workbook = XLSX.utils.book_new();

            // Create an overall summary worksheet based on overall data
            const summaryData = {
                "Connection Name": connectionName,
                "Assessment Start Date & Time": assessmentStartDate,
                "Assessment End Date & Time": assessmentEndDate,
                "Customization Dates": `${customizedStartDate || ''} - ${customizedEndDate || ''}`,
                "Total Size": Size,
                "Folder Count": folderCount,
                ...(showFilesCount && { "File Count": fileCount }), // Conditionally add "File Count" key
                "Total Time Taken": assessmentTimeTaken
                // "Overall Status": overallStatus
            };

            const summaryWorksheet = XLSX.utils.json_to_sheet([summaryData]);

            // Helper function to translate application names
            const getAppName = (appName: string): string => {
                switch (appName) {
                    case 'outlook':
                        return 'User Mailbox';
                    case 'shared':
                        return 'Shared Mailbox';
                    case 'sharepoint':
                        return 'SharePoint';
                    case 'teamschannel':
                        return 'Teams Channel';
                    case 'gmail':
                        return 'GMail';
                    case 'mydrive':
                        return 'OneDrive';
                    default:
                        return appName;
                }
            };

            // Add an empty row
            XLSX.utils.sheet_add_json(summaryWorksheet, [{}], { origin: -1 });

            // One single header row for all applications
            const applicationDataHeaders = {
                "Customized Apps/Non-Customized Apps": "Customized / Non-Customized",
                "Application Name": "Application Name",
                "Size": "Size",
                "Assessment Completed Count": "Assessment Completed Count",
                "Total Count": "Total Count",
                "Status": "Status"
            };
            XLSX.utils.sheet_add_json(summaryWorksheet, [applicationDataHeaders], { skipHeader: true, origin: -1 });

            // Iterate over applicationStats and add a row for each application
            for (const appName of Object.keys(applicationStats)) {
                const appStats: ApplicationStats = applicationStats[appName as keyof ApplicationStatsState]!;
                const dataRow = {
                    "Customized Apps/Non-Customized Apps": customizedApps.includes(appName) ? 'Customized' : 'Non-Customized',
                    "Application Name": getAppName(appName),
                    "Size": appStats.size,
                    "Assessment Completed Count": appStats.progressCount,
                    "Total Count": appStats.totalCount,
                    "Status": appStats.status
                };
                XLSX.utils.sheet_add_json(summaryWorksheet, [dataRow], { skipHeader: true, origin: -1 });
            }

            XLSX.utils.book_append_sheet(workbook, summaryWorksheet, 'Overall Summary');

            // List of applications to process
            const applications: any = ['outlook', 'shared', 'sharepoint', 'teamschannel', 'gmail', 'mydrive'];

            for (const appKey of applications) {
                if (appKey in applicationStats) {
                    try {
                        const data: any = applicationStats[appKey as keyof ApplicationStatsState];
                        //console.log(`${appKey} data:`, data);

                        let appWorksheetData = [];

                        // Process data based on application type
                        switch (appKey) {
                            case 'outlook':
                                if (Array.isArray(data.detailedInfo)) {
                                    appWorksheetData = await Promise.all(data.detailedInfo.map(async (user: any) => ({
                                        "User Mailbox Name": user.UserDisplayName,
                                        "Mails-count": user.TotalMailCount,
                                        "Mailbox-Size": await format1024Bytes(Number(user.TotalItemSizeInBytes)),
                                        "Contacts-count": user.TotalContactCount,
                                        "Todo-count": user.TotalTaskCount,
                                        "Calendar-count": user.TotalCalendarCount,
                                        "Status": user.inventoryStatus,
                                        "Error Log": user.customError || ""
                                    })));
                                }
                                break;
                            case 'shared':
                                if (Array.isArray(data.detailedInfo)) {
                                    appWorksheetData = await Promise.all(data.detailedInfo.map(async (user: any) => ({
                                        "Shared Mailbox Name": user.UserDisplayName,
                                        "Members": user.TotalMembersCount,
                                        "Mails-count": user.TotalMailCount,
                                        "Mailbox-Size": await format1024Bytes(Number(user.TotalItemSizeInBytes)),
                                        "Contacts-count": user.TotalContactCount,
                                        "Todo-count": user.TotalTaskCount,
                                        "Calendar-count": user.TotalCalendarCount,
                                        "Status": user.inventoryStatus,
                                        "Error Log": user.customError || ""
                                    })));
                                }
                                break;
                            case 'teamschannel':
                                if (Array.isArray(data.detailedInfo)) {
                                    appWorksheetData = data.detailedInfo.map((team: any) => ({
                                        "Team Name": team["SourceTeamName"],
                                        "Team Email": team["SourceTeamEmailID"],
                                        "Users": team["Users"],
                                        "Channels": team["Channels"],
                                        "Messages": team["SourceParentMsg"] + team["SourceReplyMsg"] + team["SourceBotMsg"],
                                        "Human Messages": (team["SourceParentMsg"] + team["SourceReplyMsg"] + team["SourceBotMsg"]) - team["SourceBotMsg"],
                                        "Bot Messages": team["SourceBotMsg"],
                                        "Team Apps": team["Apps"],
                                        "Files": team["Files"],
                                        "Status": team["status"],
                                        "Error Log": team["Error"]
                                    }));
                                }
                                break;
                            case 'sharepoint':
                                // SharePoint processing logic
                                if (Array.isArray(data.detailedInfo)) {
                                    appWorksheetData = await Promise.all(data.detailedInfo.map(async (site: any) => ({
                                        "Site Name": site.SiteName,
                                        "Site URL": site.SiteURL,
                                        "Users": site.userCount,
                                        "Documents": site.DocumentLibrary,
                                        "Lists": site.Lists,
                                        "Pages": site.Pages,
                                        "Permission Levels": site.permissionLevelCount,
                                        "People and Groups": site.PeopleAndGroups,
                                        "Site Size": await format1024Bytes(Number(site.siteSize)),
                                        "Status": site.status,
                                        "Error": site.Error
                                    })));
                                }
                                // You'll need to provide the specific data structure for SharePoint
                                break;
                            case 'gmail':
                                // Gmail processing logic
                                if (data.detailedInfo && typeof data.detailedInfo === 'object') {
                                    appWorksheetData = [{
                                        "User Name": data.detailedInfo.UserDisplayName,
                                        "Mails-count": data.detailedInfo.TotalMailCount,
                                        "Mailbox-Size": data.detailedInfo.TotalItemSizeInGB,
                                        "Contacts-count": data.detailedInfo.TotalContactCount,
                                        "Todo-count": data.detailedInfo.TotalTaskCount,
                                        "Calendar-count": data.detailedInfo.TotalCalendarCount,
                                        "Status": data.detailedInfo.inventoryStatus,
                                        "Error Log": data.detailedInfo.customError || ""
                                    }];
                                }
                                // You'll need to provide the specific data structure for Gmail
                                break;
                            case 'mydrive':
                                // MyDrive processing logic
                                if (Array.isArray(data.detailedInfo)) {
                                    appWorksheetData = await Promise.all(data.detailedInfo.map(async (user: any) => ({
                                        "User Name": user.UserName,
                                        "User Email": user.UserEmail,
                                        "Total Files": user.TotalFiles,
                                        "Total Folders": user.TotalFolders,
                                        "Drive Size ": await format1024Bytes(Number(user.DriveSize)),
                                        "Status": user.Status,
                                        "Error": user.Error
                                    })));
                                }
                                // You'll need to provide the specific data structure for MyDrive
                                break;
                            default:
                            //console.log(`Unknown application type: ${appKey}`);
                        }

                        if (appWorksheetData.length > 0) {
                            const appWorksheet = XLSX.utils.json_to_sheet(appWorksheetData);
                            XLSX.utils.book_append_sheet(workbook, appWorksheet, `${getAppName(appKey)} Details`);
                            //console.log(`${appKey} worksheet appended successfully`);
                        } else {
                            //console.log(`No data to append for ${appKey}`);
                        }
                    } catch (error) {
                        console.error(`Error processing ${appKey} data:`, error);
                    }
                } else {
                    //console.log(`${appKey} data not found in applicationData`);
                }
            }

            // Export the workbook as an Excel file
            XLSX.writeFile(workbook, 'OverallAssessmentSummary.xlsx');
            //console.log("Excel file written successfully");
            setLoader(false);
        } catch (error) {
            console.error('Error exporting Excel:', error);
            setLoader(false);
            setAlert(true);
            setAlertMessage(commonErrorMsgs?.generalError);
        }
    };


    //console.log("appliction data ",applicationData)

    return (<>
        {loader && (
            <div className="overlay">
                <div className="position-absolute top-50 start-50 translate-middle">
                    <div
                        className="spinner-border Loader loader-color align-center "
                        role="status"
                    >
                        <span>
                            <span className="loader-inner-circle" />
                        </span>
                    </div>
                    <p className="loading-text font-16 font-semibold color-white-v2 mt-2">
                        Loading..
                    </p>
                </div>
            </div>
        )}
        <div className="container-fluid container-height">
            <div className="row">
                <div className="col-md-12">
                    <div className="card px-4 py-4 mt-3 mb-3 border-0 rounded-3">
                        {/* bread crumb starts here */}
                        <ul className="breadcrumb-item mb-4 ps-0">
                            <li>
                                <a href="/Task" className="pe-0">
                                    Task
                                </a>
                            </li>
                            <li className="pe-2">{taskName}</li>
                        </ul>
                        {/* bread crumb ends here */}
                        <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 mt-2">
                            <h5 className="primary-header mb-0 d-flex align-items-center">
                                <a href="/Task">
                                    <img
                                        src="/images/head-back-arrow.svg"
                                        alt="back-arrow"
                                        width={19}
                                        className="me-2"
                                    />
                                </a>
                                {taskName} - Assessment
                            </h5>
                            <button className="btn primary-btn text-nowrap font-semibold font-14 d-flex gap-2 justify-content-center align-items-center" onClick={() => exportExcel()}>
                                <img src="/images/excel-icon-white.svg" alt="excel" width={13} />{" "}
                                Export
                            </button>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card p-md-4 border-0 rounded-3">
                                    <div className="row justify-content-center">
                                        <div className="row float-start div-border">
                                            <div className="col-md-4 float-start mt-4 mb-2">
                                                <div className="float-start">
                                                    <span className="float-start w-100 font-14 font-regular widget-title">
                                                        Connection Name
                                                    </span>
                                                    <span className="float-start w-100 font-14 font-semibold widget-subtitle f-sb">
                                                        {connectionName}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-md-4 float-start mt-4 mb-2">
                                                <div className="float-start">
                                                    <span className="float-start w-100 font-14 font-regular widget-title">
                                                        Assessment Start Date & Time
                                                    </span>
                                                    <span className="float-start w-100 font-14 font-semibold widget-subtitle f-sb">
                                                        {assessmentStartDate}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-md-4 float-start mt-4 mb-2">
                                                <div className="float-start">
                                                    <span className="float-start w-100 font-14 font-regular widget-title">
                                                        Assessment End Date & Time
                                                    </span>
                                                    <span className="float-start w-100 font-14 font-semibold widget-subtitle f-sb">
                                                        {assessmentEndDate}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-md-4 float-start mt-4 mb-2">
                                                <div className="float-start">
                                                    <span className="float-start w-100 font-14 font-regular widget-title">
                                                        Customization Dates
                                                    </span>
                                                    <span className="float-start w-100 font-14 font-semibold widget-subtitle f-sb">
                                                        {customizedStartDate} -  {customizedEndDate}
                                                    </span>
                                                </div>
                                            </div>
                                            <div
                                                id="FileSize"
                                                className="col-md-4 float-start mt-4 mb-2"
                                            >
                                                <div className="float-start">
                                                    <span className="float-start w-100 font-14 font-regular widget-title ">
                                                        Total size
                                                    </span>
                                                    <span className="float-start w-100 font-14 font-semibold widget-subtitle f-sb">
                                                        {Size}
                                                    </span>
                                                </div>
                                            </div>
                                            <div
                                                id="MessageCount"
                                                className="col-md-4 float-start mt-4 mb-2"
                                            >
                                                <div className="float-start">
                                                    <span className="float-start w-100 font-14 font-regular widget-title">
                                                        Folder Count
                                                    </span>
                                                    <span className="float-start w-100 font-14 font-semibold widget-subtitle f-sb">
                                                        {folderCount}
                                                    </span>
                                                </div>
                                            </div>

                                            {showFilesCount && (
                                                <div
                                                    id="FileCount"
                                                    className="col-md-4 float-start mt-4 mb-2"
                                                >
                                                    <div className="float-start">
                                                        <span className="float-start w-100 font-14 font-regular widget-title ">
                                                            File Count{" "}
                                                        </span>
                                                        <span className="float-start w-100 font-14 font-semibold widget-subtitle f-sb">
                                                            {fileCount}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}

                                            <div
                                                id="FileCount"
                                                className="col-md-4 float-start mt-4 mb-2"
                                            >
                                                <div className="float-start">
                                                    <span className="float-start w-100 font-14 font-regular widget-title ">
                                                        Total Time Taken
                                                    </span>
                                                    <span className="float-start w-100 font-14 font-semibold widget-subtitle f-sb">
                                                        {assessmentTimeTaken}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        {hasCustomziedApps && <div className="mt-4 mt-md-5">
                                            <h3 className="mb-2 font-22 font-bold pb-2">
                                                Customized Apps
                                            </h3>
                                            <div className="row">
                                                {Object.entries(applicationStats)
                                                    .filter(([appName]) => customizedApps.includes(appName))
                                                    .map(([appName, appData]) => (
                                                        <div key={appName} className="col-md-4 col-lg-4 col-xl-3 mb-4">
                                                            <div className="border-radius-8 border p-3 h-100">
                                                                <div className="d-flex justify-content-between">
                                                                    <span>
                                                                        <img
                                                                            src={`/images/${appName.toLowerCase() === 'outlook' ? 'ms-outlook-logo' : appName.toLowerCase() === 'shared' ? 'shared-mailbox-icon' : appName.toLowerCase()}.svg`}
                                                                            alt={`${appName} icon`}
                                                                            className="mb-2 me-2 cust-card-img"
                                                                        />
                                                                        <span className="font-semibold font-14 mb-3 color-black">
                                                                            {appName === 'outlook' ? 'User Mailbox' :
                                                                                appName === 'shared' ? 'Shared Mailbox' :
                                                                                    appName === 'sharepoint' ? 'SharePoint' :
                                                                                        appName === 'teamschannel' ? 'Teams Channel' :
                                                                                            appName === 'gmail' ? 'GMail' :
                                                                                                appName === 'mydrive' ? 'OneDrive' :
                                                                                                    appName}
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div className="row mb-2 pb-2">
                                                                    <div className="col-5 cust-count-line position-relative pe-0">
                                                                        <p className="mb-0 font-10 font-regular cust-gray-color">
                                                                            Count
                                                                        </p>
                                                                        <span className="font-semibold font-10">
                                                                            {appData.progressCount} out of {appData.totalCount}
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-6 pe-0">
                                                                        <p className="mb-0 font-10 font-regular cust-gray-color">
                                                                            Size
                                                                        </p>
                                                                        <span className="font-semibold font-20">
                                                                            {appData.size}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <p className="font-regular font-12 form-label-color mb-1">
                                                                    <span
                                                                        className={`table-status ${getStatusCSS(
                                                                            appData.status.toLowerCase()
                                                                        )} d-inline-block me-2 `}
                                                                    ></span>
                                                                    {appData.status} {appData.percentage}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>}

                                        {hasNonCustomziedApps && <div className="mt-4 mt-md-5">
                                            <h3 className="mb-2 font-22 font-bold pb-2">
                                                Non-Customized Apps
                                            </h3>
                                            <div className="row">
                                                {Object.entries(applicationStats)
                                                    .filter(([appName]) => nonCustomizedApps.includes(appName))
                                                    .map(([appName, appData]) => (
                                                        <div key={appName} className="col-md-4 col-lg-4 col-xl-3 mb-4">
                                                            <div className="border-radius-8 border p-3 h-100">
                                                                <div className="d-flex justify-content-between">
                                                                    <span>
                                                                        <img
                                                                            src={`/images/${appName.toLowerCase() === 'outlook' ? 'ms-outlook-logo' : appName.toLowerCase() === 'shared' ? 'shared-mailbox-icon' : appName.toLowerCase()}.svg`}
                                                                            alt={`${appName} icon`}
                                                                            className="mb-2 me-2 cust-card-img"
                                                                        />
                                                                        <span className="font-semibold font-14 mb-3 color-black">
                                                                            {appName === 'outlook' ? 'User Mailbox' :
                                                                                appName === 'shared' ? 'Shared Mailbox' :
                                                                                    appName === 'sharepoint' ? 'SharePoint' :
                                                                                        appName === 'teamschannel' ? 'Teams Channel' :
                                                                                            appName === 'gmail' ? 'GMail' :
                                                                                                appName === 'mydrive' ? 'OneDrive' :
                                                                                                    appName}
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div className="row mb-2 pb-2">
                                                                    <div className="col-5 cust-count-line position-relative pe-0">
                                                                        <p className="mb-0 font-10 font-regular cust-gray-color">
                                                                            Count
                                                                        </p>
                                                                        <span className="font-semibold font-10">
                                                                            {appData.progressCount} out of {appData.totalCount}
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-6 pe-0">
                                                                        <p className="mb-0 font-10 font-regular cust-gray-color">
                                                                            Size
                                                                        </p>
                                                                        <span className="font-semibold font-20">
                                                                            {appData.size}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <p className="font-regular font-12 form-label-color mb-1">
                                                                    <span
                                                                        className={`table-status ${getStatusCSS(
                                                                            appData.status.toLowerCase()
                                                                        )} d-inline-block me-2 `}
                                                                    ></span>
                                                                    {appData.status} {appData.percentage}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {alert ? <div
            className="modal fade show"
            tabIndex={-1}
            style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content popup-brd-radius">
                    <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                        <span className="position-absolute">
                            <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
                        </span>
                    </div>
                    <div className="modal-body border-0 text-center">
                        <h5
                            className="modal-title mb-3 primary-header"
                            id="Failure-popupLabel"
                        >
                            {alertMessage}
                        </h5>
                        <p className="primary-data">
                            {alert}
                        </p>
                    </div>
                    <div className="modal-footer border-0 pb-4 justify-content-center">
                        <button
                            type="button"
                            className="btn primary-btn font-14 px-4 font-semibold"
                            onClick={() => {
                                if (isSessionEpired) {
                                    history.push('/Login')
                                }
                                setAlert(false)
                            }}
                        >
                            Ok
                        </button>
                    </div>
                </div>
            </div>
        </div> : null}
    </>
    )

}


