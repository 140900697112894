import { useHistory, useLocation } from 'react-router-dom';
import { checkUserMappingName } from '../../Services/UserMapping';
import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { getAllDropDownValue } from '../../Services/UserMapping';
import Tippy from '@tippyjs/react';
import { followCursor } from 'tippy.js';
import { userMappingConfigurationsProps } from '../../Common/Interface';
import { insertMappingUser, insertUserMappingForTask } from '../../Services/UserMapping';
import { sessionResponse } from '../../Common/Interface';
import {
  decodeSessionDetails

} from '../../Services/CommonServices';
import CryptoJS from 'crypto-js';
import jwt from 'jwt-decode'
import { getSession } from '../../Services/manageClientAPI';
import { insertLastVisitedPageValueApi } from '../../Services/MigrationAndManageMigrationServices';
// Assuming 'isOpen' is a boolean passed as a prop to the component
interface YourComponentProps {
  isOpen: boolean;
}

// You would define your dropdown detail structure here
// For example, if a dropdown detail is an object with a 'name' and 'id', you can define it as follows:
interface DropdownDetail {
  id: string;
  name: string;
}

interface DropDownResponse {
  status: number;
  statusMessage: string;
  data: {
    data: DropdownDetail[];
  };
}

interface Payload {
  clientId: any;
  taskId: any; // Assuming taskId is a string; adjust as needed
  searchValue: string;
}

interface InsertMappingPayload {
  clientId: any | null;
  taskId: any; // Assuming taskId is a string; adjust this type as necessary
  userMappingName: string;
}

interface InsertMappingResponse {
  status: number;
  // Additional expected properties of the response...
}

interface CheckNamePayload {
  clientId: string | null;
  taskId: string;
  userMappingName: string;
  action: 'checkname';
}

interface CreateMappingPayload {
  clientId: string | null;
  taskId: string;
  userMappingName: string;
  action: 'createMapping';
}

interface GroupPayload {
  manageClientId: string | null;
  taskId: string;
}

interface ApiResponse {
  status: number;
  data: {
    message: string;
  };
}

export const UserMapping: React.FC<userMappingConfigurationsProps> = ({
  taskId,
  taskName,
  roleCheck,
  isOpen,
  disabled,
  isEdit,
  isEditCheck,
  accordianOpen,
  onUserMappingSave,
  updateUserMappingSaveStatus,
  onToggleAccordian,
  onToggle,
  enableUserMapping,
  lastVisitedStage,
  setLastVisitedStage,
}) => {
  let location: any = useLocation().state;
  const [dropdownDetails, setDropDownDetails] = useState<DropdownDetail[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [showUserMapping, setShowUserMapping] = useState<any>(isOpen);
  const [showGeneralConfig, setShowGeneralConfig] = useState<string>("");
  const [showGroupConfig, setShowGroupConfig] = useState<string>("");
  const [showGoLiveConfig, setShowGoLiveConfig] = useState<string>("");
  const [selectedDropDown, setSelectedDropDown] = useState<any>("");

  const [createDropDown, setCreateDropDown] = useState<string>("");
  const [showCreateMapPopup, setShowCreateMapPopup] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [objAdmin, setObjAdmin] = useState({})
  const [objClient, setObjClient] = useState({})
  let [adminName, setAdminName] = useState('');
  let [adminEemailAddress, setAdminEmailAddress] = useState('');
  let [adminrole, setAdminRole] = useState('');
  let sessionDetails = { UserName: "", CompanyName: "", EmailAddress: "", ID: 0, Role: "" }
  let [clientName, setclientName] = useState('');
  let [companyName, setCompanyName] = useState('');
  let [emailAddress, setEmailAddress] = useState('');
  let [role, setRole] = useState('');
  let [alert, setAlert] = useState(false)
  //let [isOpen, setIsOpen] = useState(false)
  const [noResults, setNoResults] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');


  //   const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [userMappingSAC, setUserMappingSAC] = useState<boolean>(false);
  const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';
  const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';
  const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';
  let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
  const [isSessionEpired, setisSessionEpired] = useState(false);
  const lastStage: number = 2;
  let InActiveTimeout = useRef<NodeJS.Timeout | null>(null);

  const history = useHistory()

  const [hasSavedAndContinued, setHasSavedAndContinued] = useState(false);

  const [disableActions, setDisableActions] = useState(false);



  useEffect(() => {

    if (selectedDropDown !== "") {
      //setUserMappingSAC(true);
      setHasSavedAndContinued(true);
    }

    if (isEdit === true) {
      // If setUserMappingSAC expects a boolean, you should set it to true rather than "true"
      setUserMappingSAC(true);
    }
    sessionTokenProperties();
    // resetTimer();
    getDropDown();

    // window.addEventListener('keydown', resetTimer);
    // window.addEventListener('click', resetTimer);

    // return () => {
    //   window.removeEventListener('click', resetTimer);
    //   window.removeEventListener('keydown', resetTimer);
    // };


  }, [isEdit]); // Added 'isEdit' as a dependency for better practice


  async function getDropDown() {
    try {
     
      if (!searchValue?.length || searchValue?.trim() != "") {
        setLoading(true);
        let token = await getToken()


        const payload: any = {
          token: token,
          taskId: location?.state?.taskId,
          searchValue: searchValue.trim(),
          Action: "getMapping"
        };

        let dropDownData: any = await getAllDropDownValue(payload);

        if (dropDownData?.data?.statusCode === 200) {
          setDropDownDetails(dropDownData?.data?.data[0]);
          if (dropDownData?.data?.statusMessage === 'Failed to get the user mapping names') {
            // When data[0] is 0, set an empty array or a special flag
            setDropDownDetails([]);
            setNoResults(true);
            // setSelectedDropDown("");
          }
          if (dropDownData?.data?.data.length > 0) {
            if (dropDownData?.data?.data[1][0]?.userMappingName) {
              setNoResults(false);
              setSelectedDropDown(dropDownData?.data?.data[1][0]?.userMappingName)
              setUserMappingSAC(true)
            }
          }
        }
        else {
          setAlertMessage(dropDownData?.data?.statusMessage)
        }
      }
    } catch (error: any) { // 'any' should be avoided but without knowing error structure we can't be specific
      console.error(error, "this is the error");
      // setNoResults(true);
      setAlertMessage('Please reach AVAMIGRATRON SUPPORT')
    } finally {
      setLoading(false);
    }
  }
  let resetTimer = async () => {
    try {
      // debugger;
      let sessionResponse: string = await resetSessionTimer(InActiveTimeout, SessionEpiredDateTime);
      ////console.log("sessionResponse value :", sessionResponse);

      // //debugger
      if (sessionResponse == 'expired') {
        debugger
        sessionStorage.removeItem("c_at");
        setisSessionEpired(true)
        setAlert(true)
        setAlertMessage('Your Session has Expired please login again to continue');
      }
      else if (sessionResponse == 'updateToken') {
        if (emailAddress == "" || emailAddress == undefined) {
          await sessionTokenProperties();
        }
        debugger
        let token = await getToken()
        let payload = {
          mailId: emailAddress,
          isAdmin: false,
          token: token
        };
        let getsessionToken = await getSession(payload);
        const rn_secretKey: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY
        const cs_secretKey: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;

        const c_byteValue = CryptoJS.AES.decrypt(getsessionToken?.data?.data.sessionToken, rn_secretKey);
        const c_decryptedValue = c_byteValue.toString(CryptoJS.enc.Utf8);
        let updatedToken = CryptoJS.AES.encrypt(c_decryptedValue, cs_secretKey).toString();
        await sessionStorage.removeItem("c_at");
        await sessionStorage.setItem('c_at', updatedToken);
        let updatedobjClient: any = jwt(c_decryptedValue);
        SessionEpiredDateTime = updatedobjClient.CreatedAt;
        setSessionEpiredDateTime(SessionEpiredDateTime);
      }
      ////console.log("Reset Timer End");
    }
    catch (error) {
      ////console.log("Reset Timer Catch : ", error);
      setAlert(true)
      setAlertMessage('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
      setLoading(false);
    }
  }

  const resetSessionTimer = async (InActiveTimeout: any, SessionExpirationDateTime: any) => {
    try {
      // debugger
      ////console.log("Reset Session Timer Start");
      // Assuming InActiveTimeout is a number representing the ID of the current timeout

      if (typeof window !== 'undefined') {
        ////console.log("Reset Session Timer Inside");
        if (InActiveTimeout.current) {
          clearTimeout(InActiveTimeout.current);
        }
        const tokenExpiryCheckInterval = Number(process.env.REACT_APP_TABIDLESCENARIO);

        InActiveTimeout.current = setTimeout(() => {
          ////console.log("Expired");
          sessionStorage.removeItem("c_at");
          ////console.log("removed session");
          setisSessionEpired(true)
          setAlert(true)
          setAlertMessage('Your Session has Expired please login again to continue');
          // return 'expired'
        }, tokenExpiryCheckInterval);

        ////console.log("Reset Session Timer checking");

        const currentDateTime = new Date().toISOString();
        //const sessionExpiredDateTime = new Date(SessionExpirationDateTime).toISOString();
        ////console.log("sessionExpiredDateTime value :", sessionExpiredDateTime);

        const diffInMilliseconds = Math.abs(new Date(currentDateTime).getTime() - new Date(SessionEpiredDateTime).getTime());
        ////console.log("diffInMilliSeconds values:", diffInMilliseconds);

        const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
        ////console.log("minutes : ", minutes);
        console.log("minutes usermap: ", minutes);

        const sessionUpdateTimeGap = Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP);
        ////console.log("Reset Session Timer Differece : ", sessionUpdateTimeGap);

        if (minutes > sessionUpdateTimeGap) {

          ////console.log("Reset Session Timer need to update");
          return 'updateToken'
        }

        ////console.log("Reset Session Timer End");
        return 'NoActionRequired'
      }
      return 'NoActionRequired'
    }
    catch (ex) {
      ////console.log("Reset Session Timer Catch : ", ex);
      throw ex
    }
  };


  let sessionTokenProperties = async () => {
    try {
      const tokenResponse: any = await decodeSessionDetails();
      debugger
      if (tokenResponse.adminDetails) {
        setObjAdmin(tokenResponse.adminDetails)
        setAdminName(tokenResponse.adminDetails.Name);
        setAdminEmailAddress(tokenResponse.adminDetails.Email);
        setAdminRole(tokenResponse.adminDetails.Role);

        if (tokenResponse?.adminDetails?.Role.toLowerCase().includes('viewer')) {
          setDisableActions(true)
        }
      }

      if (tokenResponse.clientDetails) {
        setObjClient(tokenResponse.clientDetails)
        setclientName(tokenResponse.clientDetails.Name);
        setCompanyName(tokenResponse.clientDetails.CompanyName)
        emailAddress = tokenResponse.clientDetails.Email;
        setEmailAddress(emailAddress);
        setRole(tokenResponse.clientDetails.Role);        
        SessionEpiredDateTime = tokenResponse.clientDetails.CreatedAt;
        setSessionEpiredDateTime(SessionEpiredDateTime);

        if (tokenResponse?.clientDetails?.Role.toLowerCase().includes('viewer')) {
          setDisableActions(true)
        }
      }
      else {
        history.push("/Login")
      }

      //#endregion
    } catch (error) {
      setAlert(true);

      console.error('Failed in main method', error);
    }
  };
  const getToken = async () => {
    let clientToken: string;
    let adminToken: string;
    let token: { clientToken?: string; adminToken?: string } = {};
    debugger
    try {
      if (objClient != undefined) {
        let sessionToken: any = sessionStorage.getItem('c_at')
        if (sessionToken) {
          // sessionToken = CryptoJS.AES.decrypt(sessionToken, c_secretKey)
          // var dePass = sessionToken.toString(CryptoJS.enc.Utf8);
          // clientToken = CryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
          token['clientToken'] = sessionToken

        }


      }
      if (objAdmin !== undefined) {
        let sessionToken: any = sessionStorage.getItem('a_at')
        if (sessionToken) {
          // sessionToken = CryptoJS.AES.decrypt(sessionToken, a_secretKey)
          // var dePass = sessionToken.toString(CryptoJS.enc.Utf8);
          // adminToken = CryptoJS.AES.encrypt(dePass, rn_secretKey).toString();

          token['adminToken'] = sessionToken

        }
      }
      return token
    } catch (error) {
      setAlert(true);

      throw error
    }
  }

  async function saveAndContinueProcess() {
    try {
      debugger
      setLoading(true);
      let token = await getToken()

      let application = location?.state?.applicationName.toLowerCase();

      let userBasedApp: number = 0

      if (application == 'outlook' || application == 'gmail' || application == 'mydrive') {
        userBasedApp = 1
      }

      const payload: any = {
        token: token,
        taskId: location?.state?.taskId,
        userBasedApp: userBasedApp,
        userMappingName: selectedDropDown
      };

      let response: any = await insertUserMappingForTask(payload);

      if (response?.data?.statusCode === 200) {
        // setShowUserMapping("")
        // setShowGeneralConfig("show")
        onToggle();
        onToggleAccordian();
        updateUserMappingSaveStatus(true)
        onUserMappingSave()

      }
      else {
        setAlertMessage(response?.data?.statusMessage)
      }
    } catch (error: any) { // 'any' should be avoided but without knowing error structure we can't be specific
      console.error(error, "this is the error");
      setAlertMessage('Please reach AVAMIGRATRON SUPPORT')
    } finally {
      setLoading(false);
    }
  }

  const insertLastVisitedPageValue = async (page: string) => {
    try {
      if (lastStage >= lastVisitedStage) {
        setLastVisitedStage(lastStage)
      }
      let token = await getToken()

      const payload = {
        token: token,
        taskId: taskId,
        lastVisitedValue: page,
        lastVisitedStage: lastVisitedStage,
      };

      ////console.log(payload, 'last visited');

      // Insert only newly entered records into the database
      const response: any = await insertLastVisitedPageValueApi(payload);

      if (!response) {
        ////console.log('The current page value failed to insert');
      } else {
        ////console.log('The current page value inserted successfully');

      }
    } catch (error) {
      console.error('Error inserting records:', error);
    }
  };

  const createMapping = async () => {
    debugger
    try {

      setLoading(true);
      let token = await getToken()

      const checkNamepayload: any = {
        token: token,
        taskId: location?.state?.taskId,
        userMappingName: createDropDown,
        Action: "checkname"
      };

      const checkMappingRes: any = await checkUserMappingName(checkNamepayload);



      if (checkMappingRes.status === 200) {
        if (checkMappingRes.data.statusMessage === 'User mapping name already exisits choose a different name') {
          setErrorMsg("Entered Name already Exist");
        } else {
          // Execute the rest of your logic for creating the mapping
          let payload: any = {
            token: token,
            taskId: location?.state?.taskId,
            userMappingName: createDropDown,
            Action: "createMapping"
          };

          let insertMappingRes: any = await insertMappingUser(payload)

          if (insertMappingRes.data.statusCode !== 200) {
            setAlertMessage(insertMappingRes.data.statusMessage);

          }

          if (insertMappingRes.data.statusCode === 200) {

            setErrorMsg("");
            setShowCreateMapPopup(false);
            setLoading(false);
            history.push("/MappedUsers", {
              state: {
                ...location.state,
                userMappingName: createDropDown,
                creating: true,
                // taskId: location?.state?.taskId,
                // taskName: location?.state?.taskName,
                // isEdit: location?.state?.taskName // Assume taskName is defined elsewhere
              }
            });

          }
        }
      }
    } catch (error: any) { // Use a specific type for the error if known, otherwise define an Error type
      console.error(error);
      setLoading(false);
      setAlertMessage('Please reach AVAMIGRATRON SUPPORT')
      return error.message;
    }
    finally {
      setLoading(false);
    }

  };

  return (
    <>
      {/* User mapping starts here */}
      <div className="col-md-12">
        <div className="table-responsive theme-table bg-white mb-3">
          <table className="table table-borderless table-shadow mb-0">
            <thead>
              <tr>
                <th className="text-nowrap table-accordion-head">
                  <span className="d-flex align-items-center gap-3">
                    <img
                      src="/images/table-accordion-arrow.svg"
                      alt="table-accordion-arrow"
                      className={`cursor-pointer table-accordion-arrow ${isOpen ? "" : "collapsed"
                        }`}
                      data-bs-toggle="collapse"
                      data-bs-target={disabled == true || isEditCheck == true ? "#userMapping" : ''}
                      onClick={() => {
                        if (disableActions) {
                          onToggle()
                        }
                        else {
                          if (disabled == true || lastVisitedStage >= 1 || isEditCheck == true) {
                            onToggle()
                          }
                          else if (disabled == false) {
                            ////console.log(disabled, "KKKKKK");
                          }
                        }
                      }}
                    />
                    <span
                      className="ac-heading cursor-pointer"
                      data-bs-toggle="collapse"
                      data-bs-target={disabled == true || isEditCheck == true ? "#userMapping" : ''}
                      onClick={() => {
                        if (disableActions) {
                          onToggle()
                        }
                        else {
                          if (disabled == true || lastVisitedStage >= 1 || isEditCheck == true) {
                            onToggle()
                          }
                          else if (disabled == false) {
                            ////console.log(disabled, "KKKKKK");
                          }
                        }
                      }}
                    >
                      {location?.state?.applicationName == 'outlook' ? 'Entity Mapping' : 'User Mapping'}
                    </span>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className={`collapse ${isOpen ? "show" : ""}`} id="user-mapping">
                <td className="pt-4">
                  <span className="font-18 font-semibold">Map your Source users with Target users to proceed for migration.</span>
                  <div>
                    <label className="font-14 font-bold label-color text-nowrap mt-3 mb-2" >Choose Mapping <span className="mandatory-color mx-2">*</span>
                      <Tippy
                        arrow={true}
                        followCursor={'horizontal'}
                        plugins={[followCursor]}
                        placement='top-start'
                        content="Links users across systems for data transfer and access control, allowing selection or creation of mappings via dropdown or add icon."
                      >
                        <img
                          src="/images/tooltip.svg"
                          alt="tooltip"
                          width={11}
                        />
                      </Tippy>
                    </label>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="input-group input-search">
                          <div className="dropdown dd-flex-1">
                            <button
                              
                              className="bg-white color-black font-14 w-100 text-start dpd-field d-flex justify-content-between align-items-center"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <span style={{ color: 'grey' }}>
                                <span title={selectedDropDown}>{selectedDropDown?.length > 10 ? selectedDropDown.slice(0, 10) + '...' : selectedDropDown || "Select"} </span>
                                {/* {selectedDropDown || "Select"} */}
                              </span>
                              <img src="/images/down-chevron-arw.svg" alt="dropdown" />
                            </button>
                            <ul className="dropdown-menu w-100 mb-3 pb-0 rounded-start" data-popper-placement="bottom">
                              <li className="px-1 mt-1">
                                <div className="input-group input-search px-2">
                                  <input
                                    type="search"
                                    className="form-control border-end-0 font-14 font-regular"
                                    placeholder="Search"
                                    onChange={(e) => setSearchValue(e.target.value)}
                                    onKeyDown={(event) => {
                                      if (event.key === "Enter") {
                                        getDropDown();
                                      }
                                    }}
                                  />
                                  <button
                                    className="btn border-start-0 d-flex align-items-center"
                                    type="button"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      e.preventDefault();
                                      getDropDown();
                                    }}
                                  >
                                    <img src="/images/search.svg" alt="search" />
                                  </button>
                                </div>
                              </li>
                              {noResults && (
                                <li className="text-center font-13 color-grey-v2 p-3">No Records Found</li>
                              )}
                              {dropdownDetails?.map((value: any, index) => (
                                <li aria-disabled={disableActions || roleCheck.toLowerCase().includes('viewer')} key={index} className="dropdown-item py-0 cursor-pointer">
                                  <span
                                    className="d-flex justify-content-between align-items-center border-bottom py-2"
                                    onClick={(e: any) => {
                                      if(!disableActions)
                                      setSelectedDropDown(value.userMappingName);
                                    }}

                                  >
                                    <span className="py-1" title={value.userMappingName}>{value.userMappingName?.length > 10 ? value.userMappingName.slice(0, 10) + '...' : value.userMappingName || "-"} </span>
                                    {/* <span className="py-1">{value.userMappingName}</span> */}
                                    <img
                                      src="/images/edit-icon-dark-blue.svg"
                                      alt="edit"
                                      style={{ cursor: 'pointer' }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        history.push("/MappedUsers", {
                                          state: {
                                            ...location.state,
                                            userMappingName: value.userMappingName,
                                            creating: false
                                          }
                                        });
                                      }}
                                    />
                                  </span>
                                </li>
                              ))}
                            </ul>
                          </div>
                          <span
                            className="input-group-text bg-white cursor-pointer"
                            aria-disabled={roleCheck.toLowerCase().includes('viewer') || adminrole.toLowerCase() === 'adminviewer' || role.toLowerCase() === 'viewer'}
                            onClick={() => {
                              setShowCreateMapPopup(true);
                            }
                            }
                          >
                            <img src="/images/add-icon-blue.svg" alt="add" style={{ cursor: 'pointer' }} width={10} />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end mt-3">
                      <button
                        disabled={!selectedDropDown || (roleCheck.toLowerCase().includes('viewer') || roleCheck.toLowerCase().includes('adminviewer'))}
                        className="btn primary-btn text-nowrap font-semibold text-white font-14 d-flex gap-2 justify-content-center align-items-center"
                        onClick={() => {
                          saveAndContinueProcess();
                          insertLastVisitedPageValue('appSpecificConfigurations');
                          setUserMappingSAC(true);
                          // if (['outlook', 'gmail', 'mydrive'].includes(location?.state?.applicationName.toLowerCase())) {
                          // }
                        }}
                      >
                        Save &amp; Continue
                      </button>
                    </div>
                  </div>
                </td>
              </tr>


            </tbody>
          </table>
        </div>
      </div>
      {/* User mapping ends here */}

      {showCreateMapPopup == true && loading === false ?
        <div className="modal fade show" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.8)" }} id="createMapping" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="createMappingLabel" aria-modal="true" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content p-4">
              <div className="modal-header border-0 p-0">
                <h1 className="font-20 font-bold custom-header-color m-0" id="createMappingLabel">Create Mapping
                </h1>
                <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" style={{ cursor: 'pointer' }} aria-label="Close" onClick={() => { setShowCreateMapPopup(false); setErrorMsg(""); setCreateDropDown("") }} />
              </div>
              <div className="modal-body border-0 p-0 pt-2 pb-2">
                <div className="row mt-2">
                  <div className="col-12">
                    <label className="font-14 font-semibold label-color w-100 my-2 pt-3" htmlFor="mappingName"> Mapping Name <span className="mandatory-color">*</span></label>
                    {/* 26 */}
                    <input type="text" className="form-control custom-form font-14 font-regular" id="mappingName" value={createDropDown} placeholder="Enter Mapping Name" maxLength={80} onChange={(e) => {
                      setCreateDropDown(e.target.value)
                      if (e.target.value.trim() === '') {
                        setErrorMsg("This field Cannot be empty");
                      } else if (!/^[a-zA-Z0-9_]+$/.test(e.target.value)) {
                        setErrorMsg("Enter valid user mapping name");
                      } else {
                        setErrorMsg("");
                      }
                    }
                    } disabled={role.toLowerCase() === 'adminviewer' || adminrole.toLowerCase() === 'adminviewer' || role.toLowerCase() === 'viewer'} />
                    <span style={{ color: "red", fontSize: "12px" }}>{errorMsg}</span>
                  </div>
                </div>
              </div>
              <div className="modal-footer border-0 p-0 mt-5">
                <button type="button" className="btn secondary-btn" data-bs-dismiss="modal" style={{ cursor: 'pointer' }} onClick={() => { setShowCreateMapPopup(false); setErrorMsg(""); setCreateDropDown("") }}>Cancel</button>
                {/* 27 */}
                <button type="button" className="btn primary-btn" onClick={() => { createMapping() }} style={{ cursor: 'pointer' }} disabled={adminrole.toLowerCase() == 'adminviewer' || role.toLowerCase() == 'adminviewer' || role.toLowerCase() == 'viewer' || createDropDown.trim() == "" || errorMsg !== ''}>Continue</button>
              </div>
            </div>
          </div>
        </div>
        : <></>}

      {loading && <div className="container-fluid">
        <div className="overlay">
          <div className="position-absolute top-50 start-50 translate-middle">
            <div
              className="spinner-border Loader loader-color align-center "
              role="status"
            >
              <span>
                <span className="loader-inner-circle" />
              </span>
            </div>
            <p className="loading-text font-16 font-semibold color-white-v2 mt-2">
              Loading..
            </p>
          </div>
        </div>
      </div>}

      {alertMessage && (
        <div className="modal fade show" id="Alert-popup" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="Alert-popupLabel" aria-modal="true" role="dialog" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.8)" }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content popup-brd-radius">
              <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                <span className="position-absolute">
                  <img src="/images/failure-popup-icon.svg" alt="Alert-popup-icon" />
                </span>
              </div>
              <div className="modal-body border-0 text-center">
                <h5 className="modal-title mb-3 primary-header" id="Alert-popupLabel">Alert</h5>
                <p className="primary-data font-16">{alertMessage}</p>
              </div>
              <div className="modal-footer border-0 pb-4 justify-content-center">
                <button type="button" className="btn secondary-btn font-14 px-4 font-semibold me-2" data-bs-dismiss="modal" onClick={() => {
                  if (isSessionEpired) {
                    history.push('/Login')
                  }
                  setAlertMessage('')
                }}>OK</button>

              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
};

export default UserMapping;